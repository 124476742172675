import {Component, OnInit} from '@angular/core';
import { ThemeService, Theme } from './services/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/internal/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  public theme: Theme;
  public themes: Theme[] = [];
  public showHeader: string;

  constructor(
    private _themeService: ThemeService,
    private _overlayContainer: OverlayContainer,
    public _route: Router
  ) {
    _route.events.pipe(
      filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => this.showHeader = e.urlAfterRedirects);

    this.theme = this._themeService.getDefaultTheme();
    this.overlayContainerTheming(this.theme.value);
  }

  public ngOnInit() {
    this.themes = this._themeService.getThemes();
    this._themeService.getTheme().subscribe((next) => {
        this.theme = next;
    });
  }

  public toggleTheme(value: string) {
      this.overlayContainerTheming(value);
      this._themeService.setTheme(value);
  }

  private overlayContainerTheming(themeClassName: string) {
      const cl = this._overlayContainer.getContainerElement().classList;

      if (cl.length > 1) {
          this._overlayContainer.getContainerElement().classList.remove(cl.item(cl.length - 1));
      }

      this._overlayContainer.getContainerElement().classList.add(themeClassName);
  }
}
