import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {XslxDataParser} from "../../../utils/xslx-data-parser";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SpecificationService} from "../../../services/specification.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ValidationFieldsService} from "../../../services/validation_fields.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-field-import',
  templateUrl: './field-import.component.html',
  styleUrls: ['./field-import.component.scss']
})
export class FieldImportComponent implements OnInit {

  public form: FormGroup;
  public maxFileSize = 4;
  public loading = false;

  constructor(public dialog: MatDialogRef<FieldImportComponent>, private validationFieldsService: ValidationFieldsService,private snackBar: MatSnackBar,
              private sanitized: DomSanitizer

  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      file: new FormControl(null, [Validators.required]),
    });
  }

  public import() {
    this.loading = true;
    this.validationFieldsService.import({
      filename: this.form.get('file').value['name'],
      content: this.form.get('file').value['content'].split(',')[1]

    }).subscribe(result => {
      if (result.status && result.status === 'success') {

        let existing = result.total - result.count;
        let message = result.count +' / '+ result.total +' value(s) added';
        if(existing > 0) {
          message += ', ' + existing + ' value(s) already existing';
        }
        this.snackBar.open(message, null , {duration:2000})
        this.loading = false;
        this.dialog.close({})
      }
    });
  }

}
