import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route} from '@angular/router';

import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, tap, switchMap} from 'rxjs/internal/operators';
import {LoginService} from "./login.service";

@Injectable({providedIn : 'root'})
export class AdminGuardService implements CanActivate, CanLoad {

  private unauthorized_page = "/";

  constructor(private authenticationService: LoginService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.checkRoles()){
      this.router.navigate([this.unauthorized_page])
      return false;
    }
    return true;
  }
  canLoad(route: Route): Observable<boolean>| boolean {
    if (!this.checkRoles()){
      this.router.navigate([this.unauthorized_page])
      return false;
    }
    return true;
  }

  checkRoles()
  {
    return this.authenticationService.user && this.authenticationService.user.isAdmin === true;
  }


}
