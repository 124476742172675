import {Injectable} from "@angular/core";
import {MsalService} from "@azure/msal-angular";
import {OAuthSetting} from "../utils/auth";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { of, throwError} from "rxjs";
import {HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import {catchError, tap} from 'rxjs/internal/operators';

const httpOptions = { headers: new HttpHeaders({
    'Content-Type':'application/json'
  })};

@Injectable({
  providedIn: 'root'
})

export  class LoginService {
  public redirectUrl:string;
  public isLogged:boolean = undefined;
  private errors: string;
  public user: any;

  constructor(private msalService: MsalService, private http: HttpClient, private router: Router, public snackBar: MatSnackBar) {
  }

  async signIn(): Promise<any> {
    let result = await this.msalService.loginPopup({scopes : OAuthSetting.scopes})
      .catch((reason) => {
        this.errors = JSON.stringify(reason, null, 2);
      });


    if (result) {

      this.user = this.msalService.getAccount();
      console.log(this.user);
      let access_token = await this.getAccesstoken();
      access_token = access_token.accessToken;

      return this.http.post('/auth/login', {access_token: access_token, email: this.user.userName}, httpOptions).pipe(
        catchError(this.handleError),
        tap((value: any) => {
          localStorage.setItem('access_token', value.token);
          this.isLogged = true;
        }));
    }

    return of({ errors: this.errors });
  }

  public getUser() {
    return this.http.get('/auth/get_user', httpOptions).pipe(
      catchError(this.handleError),
      tap((value: any) => {
        return value;
      })
    );
  }

  public IsAuthenticated() {
    this.isLogged = false;
    let httpOptions = { headers: new HttpHeaders({
        'Authorization':'Bearer ' + localStorage.getItem('access_token')
      })};

    return this.http.get('/auth/check_token', httpOptions).pipe(
      catchError(this.handleError),
      tap((value: any) => {
        this.user = value.user;
        this.isLogged = true;
      })
    );

  }

  public logout() {
    localStorage.clear();
    this.isLogged = false;
    this.user = undefined;
    //this.msalService.logout();
    return of({data: "ok"});

  }

  async getAccesstoken(): Promise<any> {
    let result = await this.msalService.acquireTokenSilent({scopes : OAuthSetting.scopes, redirectUri: OAuthSetting.redirectUri})
      .catch((reason) => {
        this.errors = JSON.stringify(reason, null, 2);
      });

    return result;
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      this.isLogged = false;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };

}
