import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Optional, Output} from "@angular/core";
import {MAT_OPTION_PARENT_COMPONENT, MatOptgroup, MatOption, MatOptionParentComponent} from "@angular/material/core";
import {FormControl} from "@angular/forms";
import {__values} from "tslib";

@Component({
  selector: 'app-mat-option-filter',
  templateUrl: './mat-option-filter.component.html',
  styleUrls: ['./mat-option-filter.component.scss']
})

export class MatOptionFilterComponent extends MatOption{
  @Input() set searchValue(val: any) {
    if(this.inputControl) {
      this.inputControl.setValue(val.value);
    }
  }
  @Input() grouped = false;
  @Input() keySelector;
  @Input() values: any;
  @Input() placeHolder = 'search';
  @Output() filteredValues = new EventEmitter();
  public inputControl = new FormControl('');

 constructor(elementRef: ElementRef<HTMLElement>,
              changeDetectorRef: ChangeDetectorRef,
              @Optional() @Inject(MAT_OPTION_PARENT_COMPONENT) parent: MatOptionParentComponent,
              @Optional() group: MatOptgroup) {

    super(elementRef, changeDetectorRef, parent, group);

    this.inputControl.valueChanges.subscribe((inputValue) => {

          if(!this.grouped) {
            if(this.values && this.values.filter) {
              let filtered = this.values.filter(v => this.keySelector(v).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);
              this.filteredValues.emit({values : filtered});
            }
          }
          else {
            let filtered = {}
            for (let k in this.values) {
                filtered[k] = this.values[k].filter(v => this.keySelector(v).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);
            }
            this.filteredValues.emit({values : filtered});
          }

    });
}
}
