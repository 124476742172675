import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ValidationFieldsService} from "../../services/validation_fields.service";
import {FormControl} from "@angular/forms";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Component({
  selector: 'validation-field-select',
  templateUrl: './validation-field.component.html',
  styleUrls: ['./validation-field.component.scss']
})
export class ValidationFieldComponent implements OnInit,AfterViewInit {

  numericFields = ['caractères max', 'mot(s)'];

  multiselectValues = [];
  tmpVal: string = '';
  @Input('value') selectValue: any = {text: ''};
  @Input('fieldList') fieldList = [];
  @Input('fieldName') fieldName: any;

  @Input('isMultiselect') isMultiselect = false;

  @Input('readOnly') readonly = false;

  @Output('fieldSelect') fieldSelect: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  public  fieldControl: FormControl;
  public  numericField: FormControl;
  public chipValue: FormControl;
  public newValue = '';

  public addMode = false;

  filteredOptions: Observable<any[]>;

  constructor(private validationFieldsService: ValidationFieldsService) { }

  ngOnInit(): void {
    this.numericField = new FormControl('');
    this.chipValue = new FormControl('');
    if(this.selectValue) {

      if(this.isMultiselect) {
        let values = []
        for(let element of this.fieldList) {
          values.push(element.text)
        }
        this.multiselectValues = this.selectValue.text.split(',').filter(value => value.trim() !== '' &&
          (values.indexOf(value.trim())>=0 || this.containsNumericField(value.trim())))
        this.chipValue.valueChanges.subscribe((r)=>{
          console.log(r)
        })
        this.fieldControl = new FormControl('');
      }
      else {
        let existingValues = this.fieldList.filter(element => this.selectValue.text.toLowerCase().trim() === element.text.toLowerCase().trim())

        this.fieldControl = new FormControl((existingValues.length>0)?this.selectValue:'');

        if(existingValues.length === 0) {
          this.fieldSelect.emit({text: ''});
        }
      }
    }
    else {
      this.fieldControl = new FormControl('');
      this.fieldSelect.emit({text: ''});
    }

    this.filteredOptions = this.fieldControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  containsNumericField(value) {
    let filtered = this.numericFields.filter(x=> value.endsWith(x)>=0);
    return filtered.length > 0;
  }

  ngAfterViewInit(): void {

  }

  public isNumericField() {
    let filtered = this.numericFields.filter(x=> x.indexOf(this.chipValue.value.text)>=0);

    return filtered.length > 0;
  }

  displayFn(field): string {
    return field && field.text ? field.text : '';
  }

  private _filter(value: any): string[] {

    const filterValue = (value.text)?value.text.toLowerCase():value.toLowerCase()

    return this.fieldList.filter(option => option.text.toLowerCase().includes(filterValue) && this.multiselectValues.indexOf(option.text)<0);
  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1.text.toLowerCase() === o2.text.toLowerCase();
  }

  public onChange($event) {
    this.tmpVal = this.fieldControl.value;
    let self = this;
    setTimeout(() => {
      if(!self.addMode) {
        if($event.source)
          self.fieldSelect.emit($event.source.value);
        else
          self.fieldSelect.emit({text:$event.target.value});
      }
    },300);

  }
  public onFocusOut($event) {

    this.tmpVal = this.fieldControl.value;
    let self = this;
    setTimeout(() => {
      if(!self.addMode) {
        let text = self.fieldControl.value;
        if (self.fieldControl.value.text) {
          text = self.fieldControl.value.text;
        }
        let existingValues = this.fieldList.filter(element => text.toLowerCase().trim() === element.text.toLowerCase().trim())

        if(existingValues.length === 0) {
          self.fieldSelect.emit({text: ''});
          self.fieldControl.setValue('');
        }

      }

    }, 300)


  }

  public switchAddMode() {

    let val = this.tmpVal;
    if(val['text']) {
      val = val['text'];
    }
    this.newValue = val;
    this.addMode = true;
  }

  public addChip() {
    if(this.multiselectValues.indexOf(this.chipValue.value.text)<0) {
      if(this.isNumericField()) {
        this.multiselectValues.push( this.numericField.value + ' ' + this.chipValue.value.text);
      }
      else {
        this.multiselectValues.push( this.chipValue.value.text);
      }
    }

    //   this.multiselectValues.push(event.option.viewValue);
    this.numericField.setValue('');
    this.chipValue.setValue('');
    // this.chipInput.nativeElement.value = ''
    //
    // this.chipInput.nativeElement.blur()
    this.fieldSelect.emit({text: this.multiselectValues.join(',')});
  }

  addElement() {
    let field = {
      text: this.newValue,
      richText: [{insert: this.newValue}],
      field: this.fieldName,
      validate: false
    };

    if(this.newValue.trim() === '') {
      this.addMode = false;
      return;
    }

    this.validationFieldsService.addField(field).subscribe(response => {
      if(response.status && response.status === 'success') {

          this.loadValidationFields(field);
      }
    });
  }

  public loadValidationFields(field=null) {
    this.validationFieldsService.list_mine().subscribe(result => {
      this.fieldList = result.elements[this.fieldName];
      if(field) {

        this.fieldControl.setValue(field);
        this.fieldSelect.emit(field);
        this.addMode = false;
      }
    })
  }

  public remove(i) {
    this.multiselectValues.splice(i, 1);
    this.fieldSelect.emit({text: this.multiselectValues.join(',')});
  }

  public add(event) {
    const value = (event.value || '').trim();

    if (value) {
      if(this.multiselectValues.indexOf(value)<0)
        this.multiselectValues.push(value);
    }
    this.fieldControl.setValue('');
    // Clear the input value
    event.chipInput!.clear();


  }

  public selected(event) {
    this.chipValue.setValue(event.option.value);
    console.log(this.chipValue)
    // if(this.multiselectValues.indexOf(event.option.viewValue)<0)
    //   this.multiselectValues.push(event.option.viewValue);
    // this.fieldControl.setValue('');
    // this.chipInput.nativeElement.value = ''
    //
    // this.chipInput.nativeElement.blur()
    // this.fieldSelect.emit({text: this.multiselectValues.join(',')});
  }

}
