<mat-sidenav-container>
  <mat-sidenav-content>
    <section>
      <div id="header">
        <div>
          <h1>Sources / Templates</h1>
          <span class="sub-title">List of uploaded sources</span>
        </div>

        <div class="actions">
          <button mat-raised-button (click)="addSource()">
            <mat-icon class="mdi mdi-24px mdi-import"></mat-icon>
            <div class="btn-text">Upload source /Template</div>
          </button>
        </div>
      </div>

      <div id="block-table-specs">
        <form [formGroup]="filterForm">
          <mat-form-field>
            <input matInput formControlName="filter" placeholder="FILTER">
            <button type="button" *ngIf="filterForm.get('filter').value" mat-button matSuffix mat-icon-button
                    aria-label="Clear" (click)="filterForm.get('filter').setValue('')">
              <mat-icon class="mdi mdi-close"></mat-icon>
            </button>
          </mat-form-field>
        </form>
        <span class="no-data" *ngIf="!hasData">
          There is no data to display<br />
        </span>

        <div [ngStyle]="{'display': hasData ? 'block' : 'none'}">


          <div class="div-table">
            <table mat-table matSort [dataSource]="sourceData" matSortActive="_id" matSortDirection="desc"
                   [ngClass]='{ "loading": tableIsLoading }'>

              <!-- Loading -->
              <ng-container matColumnDef="loading">
                <th mat-header-cell class="table-loading" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </th>
              </ng-container>

              <!-- No Result -->
              <ng-container matColumnDef="noresult">
                <th mat-header-cell class="table-noresult" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  No result
                </th>
              </ng-container>

              <ng-container matColumnDef="creation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="_id">Creation Date</th>
                <td mat-cell *matCellDef="let element">{{ createdAt(element._id) }}</td>
              </ng-container>

              <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Update Date</th>
                <td mat-cell *matCellDef="let element">{{ (element.updatedAt)?formatDate(element.updatedAt):createdAt(element._id) }}</td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>
              <!-- Filename -->
              <ng-container matColumnDef="filename">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Filename</th>
                <td mat-cell *matCellDef="let element">{{ element.filename }}</td>
              </ng-container>

              <ng-container matColumnDef="associated_specs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Associated Specs</th>
                <td mat-cell *matCellDef="let element">{{ element.associated_specs }}</td>
              </ng-container>

              <!-- Download -->
              <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button type="button" color="accent" (click)="download(element)" mat-icon-button matTooltip="Download">
                    <mat-icon aria-label="download" class="mdi mdi-24px mdi-file-download"></mat-icon>
                  </button>
                  <button type="button" color="accent" (click)="replaceSource(element)" mat-icon-button matTooltip="Replace">
                    <mat-icon aria-label="replace" class="mdi mdi-24px mdi-file-replace"></mat-icon>
                  </button>
                  <button type="button" color="accent" (click)="renameSource(element)" mat-icon-button matTooltip="Rename">
                    <mat-icon aria-label="rename" class="mdi mdi-24px mdi-rename-box"></mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-header-row class="table-loading" *matHeaderRowDef="['loading']"></tr>
              <tr mat-header-row
                  [ngClass]="{'table-noresult' : true, 'display' : sourceData.data.length == 0 && !tableIsLoading}"
                  *matHeaderRowDef="['noresult']"></tr>
              <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
            </table>
          </div>

          <mat-paginator showFirstLastButtons="showFirstLastButtons" pageSize="10" [pageSizeOptions]="[10, 25, 50]">
          </mat-paginator>
        </div>

      </div>

    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
