<div class="layout-column layout-align-center-stretch">
  <div mat-dialog-title class="layout-column layout-align-space-between-stretch">
    <div class="layout-row layout-align-space-between-center">
      <h5>Merge Specification</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>

  <div mat-dialog-content>

    <div id="header">
      <div class="actions">
        <button mat-raised-button color="primary" (click)="discardChanges()">
          <mat-icon class="mdi mdi-18px mdi-save"></mat-icon>
          <div class="btn-text">Discard changes</div>
        </button>
        <button mat-raised-button color="primary" (click)="merge()" [disabled]="!hasSelected()">
          <mat-icon matBadgePosition="before" matBadgeColor="primary" matPrefix class="mdi mdi-18px mdi-import"></mat-icon>
          <div class="btn-text">Merge</div>
        </button>
      </div>
    </div>

    <div>
      <table class="mat-table"  *ngIf="newSpec">
        <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell"><mat-checkbox [(ngModel)]="allChecked" (change)="selectAll($event)">
          </mat-checkbox></th>
          <th class="mat-header-cell">Header</th>
          <th class="mat-header-cell">Old</th>
          <th class="mat-header-cell">New</th>
        </tr>
        </thead>
        <ng-container *ngFor="let spec of newSpec">
          <tr class="mat-row" *ngIf="changedHeaders.indexOf( this.headerCorrespondances[spec.header]) >= 0">
            <td class="mat-cell">
              <mat-checkbox [(ngModel)]="selected[headerCorrespondances[spec.header]]" (change)="selectionChange($event)">
              </mat-checkbox>
            </td>
            <td class="mat-cell">
              <div>{{spec.header}}</div>
            </td>
            <td class="mat-cell">
              <div [innerHTML]="deltaToHtml(oldSpec[headerCorrespondances[spec.header]].richText) | safeHtml"></div>
            </td>
            <td class="mat-cell">
              <div [innerHTML]="deltaToHtml(spec.richText) | safeHtml"></div>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>

  </div>

</div>
