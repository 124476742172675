import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UsersService} from '../../../services/users.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit, AfterViewInit {

  @Input('sidenav')
  public sidenav;

  @Output('useradded')
  public userAdded = new EventEmitter();

  public loading = false;

  public formUser: FormGroup;

  constructor(private usersService: UsersService) {
  }

  ngOnInit() {
    this.formUser = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
      }
    );
  }

  ngAfterViewInit(): void {
    this.sidenav.openedStart.subscribe(() => {
      this.formUser.reset();
    });
  }

  public addUser() {
    if (this.formUser.valid) {
      this.loading = true;
      this.usersService.addUser(
        this.formUser.get('email').value,
      ).subscribe(response => {
        this.loading = false;

        if (response.status === 'ok') {
          this.userAdded.emit();
          this.sidenav.close();
        } else if (response.error === 'duplicate') {
          this.formUser.get('email').setErrors({'duplicate': true});
        } else if (response.error === 'invalid') {
          this.formUser.get('email').setErrors({'email': true});
        }
      });
    }
  }
}
