<mat-toolbar class="layout-row layout-align-space-between-center">
    <span [routerLink]="['/']">IPBRIDGE</span>

    <div *ngIf="loginService.IsAuthenticated() && user && user['isAdmin'] && validationFieldsService.unvalidate > 0">
      <div id="notification" matTooltip="You have data to validate" [routerLink]="['/admin']">
        <span class="count">{{validationFieldsService.unvalidate > 9 ? '+9' : validationFieldsService.unvalidate}}</span>
        <mat-icon class="mdi mdi-18px mdi-bell-outline"></mat-icon>
      </div>

      <div class="spacer"></div>
    </div>

    <div>
      <button mat-button *ngIf="loginService.IsAuthenticated()" [routerLink]="['/']">
        <mat-icon class="mdi mdi-18px mdi-note-multiple"></mat-icon>
        <div class="btn-text">Spec Plan List</div>
      </button>
      <button mat-button *ngIf="loginService.IsAuthenticated()" [routerLink]="['/draft-list']">
        <mat-icon class="mdi mdi-18px mdi-note-multiple"></mat-icon>
        <div class="btn-text">Drafts</div>
      </button>
      <button mat-button *ngIf="loginService.IsAuthenticated()" [routerLink]="['/spec-library']">
        <mat-icon class="mdi mdi-18px mdi-library-shelves"></mat-icon>
        <div class="btn-text">Library</div>
      </button>
      <button mat-button *ngIf="loginService.IsAuthenticated()" [routerLink]="['/sources']">
        <mat-icon class="mdi mdi-18px mdi-file"></mat-icon>
        <div class="btn-text">Sources / Templates</div>
      </button>
      <button mat-button *ngIf="loginService.IsAuthenticated() && user && user['isAdmin']" [routerLink]="['/users']">
        <mat-icon class="mdi mdi-18px mdi-account-group"></mat-icon>
        <div class="btn-text">Users</div>
      </button>
      <button mat-button *ngIf="loginService.IsAuthenticated() && user &&user['isAdmin']"  [routerLink]="['/admin']">
        <mat-icon class="mdi mdi-18px mdi-account-cog"></mat-icon>
        <div class="btn-text">Admin</div>
      </button>
      <button mat-button *ngIf="loginService.IsAuthenticated()" (click)="goToHelp()"  target="_blank" >
        <mat-icon class="mdi mdi-18px mdi-help-circle-outline"></mat-icon>
        <div class="btn-text">Help</div>
      </button>
      <button mat-button *ngIf="loginService.IsAuthenticated()" (click)="logout()">
        <mat-icon class="mdi mdi-18px mdi-power"></mat-icon>
        <div class="btn-text">Sign Out</div>
      </button>
    </div>
</mat-toolbar>
