<mat-sidenav-container>
  <mat-sidenav-content>
    <section>
      <div id="header">
        <div>
          <h1>Validation Fields</h1>
          <span class="sub-title">List of fields to validate</span>
        </div>
        <div class="actions">
          <button mat-raised-button (click)="import()">
            <mat-icon class="mdi mdi-24px mdi-import"></mat-icon>
            <div class="btn-text">Import</div>
          </button>
          <button mat-raised-button (click)="export()">
            <mat-icon class="mdi mdi-24px mdi-export"></mat-icon>
            <div class="btn-text">Export</div>
          </button>
        </div>
      </div>

      <div id="block-table-specs">
        <form [formGroup]="filterForm">
          <mat-form-field>
            <input matInput formControlName="filter" placeholder="FILTER">
            <button type="button" *ngIf="filterForm.get('filter').value" mat-button matSuffix mat-icon-button
                    aria-label="Clear" (click)="filterForm.get('filter').setValue('')">
              <mat-icon class="mdi mdi-close"></mat-icon>
            </button>
          </mat-form-field>
        </form>
        <span class="no-data" *ngIf="!hasData">
          There is no data to display<br />
        </span>

        <div [ngStyle]="{'display': hasData ? 'block' : 'none'}">


          <div class="div-table">
            <table mat-table matSort [dataSource]="fieldsData" matSortActive="_id" matSortDirection="desc"
                   [ngClass]='{ "loading": tableIsLoading }'>

              <!-- Loading -->
              <ng-container matColumnDef="loading">
                <th mat-header-cell class="table-loading" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </th>
              </ng-container>

              <!-- No Result -->
              <ng-container matColumnDef="noresult">
                <th mat-header-cell class="table-noresult" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  No result
                </th>
              </ng-container>

              <ng-container matColumnDef="field">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Field Name</th>
                <td mat-cell *matCellDef="let element">{{ element.field }}</td>
              </ng-container>

              <ng-container matColumnDef="validate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="validate_count">Validate</th>
                <td mat-cell *matCellDef="let element">{{ element.validate_count }}</td>
              </ng-container>

              <ng-container matColumnDef="unvalidate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="unvalidate_count">unvalidate</th>
                <td mat-cell *matCellDef="let element">{{ element.unvalidate_count }}</td>
              </ng-container>



              <!-- Download -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button type="button" color="accent"  mat-icon-button matTooltip="Field values" [routerLink]="['/field-view']"
                          [queryParams]="{field: element.field}" >
                    <mat-icon aria-label="field-values" class="mdi mdi-24px mdi-playlist-check"></mat-icon>
                  </button>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-header-row class="table-loading" *matHeaderRowDef="['loading']"></tr>
              <tr mat-header-row
                  [ngClass]="{'table-noresult' : true, 'display' : fieldsData.data.length == 0 && !tableIsLoading}"
                  *matHeaderRowDef="['noresult']"></tr>
              <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
            </table>
          </div>

          <mat-paginator showFirstLastButtons="showFirstLastButtons" pageSize="10" [pageSizeOptions]="[10, 25, 50]">
          </mat-paginator>
        </div>

      </div>

    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
