 <mat-form-field appearance="{{appearance}}" id="{{id}}" style="width: inherit">
  <mat-label>{{label}}</mat-label>
  <mat-select ngDefaultControl (focusout)="clearFilters()" [formControl]="formControl">
             <app-mat-option-filter style="position: sticky" *ngIf="_values" [placeHolder]="'Filter'" [grouped]="grouped" [keySelector]="keySelector" [searchValue]="searchValue" [values]="_values" (filteredValues)="onOptionsChange($event)"></app-mat-option-filter>

            <ng-container *ngIf="!grouped">
               <mat-option *ngFor="let element of filtered" [value]="valueSelector(element)">{{keySelector(element)}}</mat-option>
            </ng-container>
            <ng-container *ngIf="grouped">
              <ng-container *ngFor="let key of keys" >
                <mat-optgroup *ngIf="filtered && filtered[key] && filtered[key].length > 0" label="{{key}}">
                 <mat-option *ngFor="let element of filtered[key]" [value]="valueSelector(element)">{{keySelector(element)}}</mat-option>
               </mat-optgroup>
              </ng-container>

            </ng-container>
  </mat-select>
 </mat-form-field>
