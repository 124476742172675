<div class="layout-column layout-align-center-stretch">
  <div mat-dialog-title class="layout-column layout-align-space-between-stretch block-title">
    <div class="layout-row layout-align-space-between-center">
      <h5>Edit Help</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Versions</mat-label>
      <mat-select value="{{getDefaultSelect()}}" (selectionChange)="selectionChange($event)">
        <mat-option *ngFor="let version of versions" [value]="version">
          {{mongoIdToDate(version._id)}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <iframe src="https://globalappsportal.sharepoint.com/sites/Projet-IPBridge314/_layouts/15/Doc.aspx?sourcedoc={3bdff062-7eaf-46cc-bcc9-93c9248f4cbd}&amp;action=embedview&amp;wdStartOn=1" width="476px" height="288px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<!--    <quill-editor [style]="{marginBottom:'10px'}" placeholder="" format="object" [(ngModel)]="tuto">-->
<!--    </quill-editor>-->
  </div>

  <div mat-dialog-actions class="layout-row layout-align-space-between-stretch">
    <button mat-stroked-button color="warn" [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!tutoHasChanges()" (click)="save()">Save</button>
  </div>
</div>
