<mat-sidenav-container>
  <mat-sidenav-content>
    <section>
      <div id="header">
        <div>
          <h1>Users</h1>
          <span class="sub-title">Manage your users</span>
        </div>

        <div class="actions">
          <button mat-raised-button color="primary" (click)="addUser()">
            <mat-icon class="mdi mdi-18px mdi-import"></mat-icon>
            <div class="btn-text">Add user</div>
          </button>
        </div>
      </div>


      <div id="block-table-specs">

        <span class="no-data" *ngIf="!hasData">
          There is no data to display<br />
          Let's <a (click)="addUser()">create a user</a>
        </span>

        <div [ngStyle]="{'display': hasData ? 'block' : 'none'}">

          <form [formGroup]="filterForm">
            <mat-form-field>
              <input matInput formControlName="filter" placeholder="FILTER">
              <button type="button" *ngIf="filterForm.get('filter').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="filterForm.get('filter').setValue('')">
                <mat-icon class="mdi mdi-close"></mat-icon>
              </button>
            </mat-form-field>
          </form>

          <div class="div-table">
            <table mat-table matSort [dataSource]="users" matSortActive="creationDate" matSortDirection="desc" [ngClass]='{ "loading": tableIsLoading }'>

              <!-- Loading -->
              <ng-container matColumnDef="loading">
                <th mat-header-cell class="table-loading" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </th>
              </ng-container>

              <!-- No Result -->
              <ng-container matColumnDef="noresult">
                <th mat-header-cell class="table-noresult" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  No result
                </th>
              </ng-container>

              <!-- Fullname -->
              <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
                <td mat-cell *matCellDef="let element">{{ element.fullname || '-' }}</td>
              </ng-container>

              <!-- Email -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>

              <!-- Creation Date -->
              <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
                <td mat-cell *matCellDef="let element">{{ element.creationDate }}</td>
              </ng-container>

              <!-- Last login -->
              <ng-container matColumnDef="lastLogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last login</th>
                <td mat-cell *matCellDef="let element">{{ element.lastLogin || '-' }}</td>
              </ng-container>

              <!-- Actions -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button type="button" mat-icon-button [matMenuTriggerFor]="actionsMenu" aria-label="Actions">
                    <mat-icon class="mdi mdi-18px mdi-dots-vertical"></mat-icon>
                  </button>

                  <mat-menu #actionsMenu="matMenu">
                    <button type="button" mat-menu-item (click)="deleteUser(element)">
                      <mat-icon class="mdi mdi-18px mdi-delete"></mat-icon>
                      <div class="btn-text">Delete</div>
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-header-row class="table-loading" *matHeaderRowDef="['loading']"></tr>
              <tr mat-header-row [ngClass]="{'table-noresult' : true, 'display' : (users.data.length == 0 && !tableIsLoading)}" *matHeaderRowDef="['noresult']"></tr>
              <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
            </table>
          </div>

          <mat-paginator showFirstLastButtons="showFirstLastButtons" pageSize="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
        </div>
      </div>
    </section>
  </mat-sidenav-content>

  <mat-sidenav #sidenavadduser mode="over" position="end">
    <app-user-add [sidenav]="sidenavAddUser" (useradded)="loadUsers()"></app-user-add>
  </mat-sidenav>

</mat-sidenav-container>
