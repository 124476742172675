import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SpecificationService} from 'src/app/services/specification.service';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/internal/operators';
import {merge} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SpecDeleteComponent} from '../specs/spec-delete/spec-delete.component';
import {XslxDataParser} from '../../utils/xslx-data-parser';
import {ConfirmSaveComponent} from './confirm-save/confirm-save.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-library-spec',
  templateUrl: './library-spec.component.html',
  styleUrls: ['./library-spec.component.scss']
})
export class LibrarySpecComponent implements  AfterViewInit {

  public loading: boolean;
  public data: MatTableDataSource<any> = new MatTableDataSource<any>();
  public columns: Array<string> = ['selected',  'adserving', 'publisher', 'site', 'format', 'user'];
  public columnsDisplay: Array<string> = [ 'selected', 'adserving', 'publisher', 'site', 'format', 'user'];
  public selectedElement: Array<any> = [];
  public only_selected = false;
  public hasChanges = false;
  public XlsxData: XslxDataParser;


  @Input('sidenav') public sidenav;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public filter: FormGroup;

  constructor(
    private dialog: MatDialog,
    private specificationService: SpecificationService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<LibrarySpecComponent>) {
      this.data.data = [];

      this.filter = this.fb.group({
        filter: ['']
      });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(
      () => this.paginator.pageIndex = 0
    );

    merge(this.filter.get('filter').valueChanges, this.sort.sortChange, this.paginator.page).pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(e => {
        this.getData();
    });
  }

  public change(event, element, key) {
    if (!element.changes) {
      element.changes = {};
    }
    if (event.target.value !== element[key]) {
      if (event.target.value === '') {
        element.editMode = false;
        return;
      }
      this.hasChanges = true;
      element.changes[key] = event.target.value;
    }
    else {
      delete element.changes[key];
      if (!element.changes || Object.keys(element.changes).length === 0) {
        this.hasChanges = false;
      }
    }
    element.editMode = false;
  }

  public showOnlySelected($event) {
    this.only_selected = $event.checked;
    this.paginator.pageIndex = 0;
    if (this.only_selected) {
      this.data.data = this.selectedElement;
      this.data.sort = this.sort;
      this.data.paginator = this.paginator;
    } else {
      this.getData();
    }
  }

  public save() {
    this.dialog.open(ConfirmSaveComponent).afterClosed().subscribe(result => {
      if (result === 'save') {
        const changes = this.data.data.filter(element => {
          return (element.changes && Object.keys(element.changes).length > 0);
        });
        this.specificationService.updateSpecLibrary(changes).subscribe(result => {
          if (result.status && result.status === 'success') {
            this.getData();
            this.hasChanges = false;
            this.snackBar.open('Changes saved', null, {
              duration: 2000
            });
          }
        });
      }
      else {
        if (result === 'discard') {
          this.getData();
          this.hasChanges = false;
          this.snackBar.open('Changes discarded', null, {
            duration: 2000
          });
        }
      }
    });

  }

  delete(element) {
    this.dialog.open(SpecDeleteComponent, {
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      if (result) {
        this.specificationService.deleteFromSpecLibrary(element._id).subscribe( result => {
          if (result.status && result.status == 'success') {
            this.getData();
          }
        });
      }
    });
  }

  public selectItem($event, item) {
    item.checked = $event.checked;
    this.selectedElement = this.selectedElement.filter(e => e._id !== item._id);

    if ($event.checked) {
      this.selectedElement.push(item);
    }
  }

  public close() {
    this.dialogRef.close(this.selectedElement);
  }

  public getData() {
    this.loading = true;

    if (this.only_selected) {
      this.data.filter = this.filter.get('filter').value.toLowerCase();
      return;
    }

    let offset = this.paginator.pageIndex * this.paginator.pageSize;
    let limit = this.paginator.pageSize;
    let sort = undefined;
    let filter = this.filter.get('filter').value;

    if (this.sort.active && this.sort.direction) {
      sort = {prop: this.sort.active, dir: this.sort.direction};
    }

    this.specificationService.specLibraryList(offset, limit, filter, sort).subscribe(e => {
      this.data.filter = undefined;
      this.data.sort = undefined;
      this.data.paginator = undefined;
      this.columnsDisplay = this.columns;
      this.data.data = e.data;
      this.paginator.length = e.count;

      this.data.data = this.data.data.map(el => {
        el.checked = this.selectedElement.find(e => {return e._id === el._id}) !== undefined;
        return el;
      });

      this.loading = false;
    });
  }
}
