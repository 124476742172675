import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-draft-save',
  templateUrl: './draft-save.component.html',
  styleUrls: ['./draft-save.component.scss']
})
export class DraftSaveComponent {
  public draftFormGroup: FormGroup;

  constructor (public dialog: MatDialogRef<DraftSaveComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data && data.name) {
      this.draftFormGroup = new FormGroup({
        name: new FormControl(data.name, Validators.required)
      });
    }
    else {
      this.draftFormGroup = new FormGroup({
        name: new FormControl('', Validators.required)
      });
    }
  }

  public save() {
    if (this.draftFormGroup.valid) {
      this.dialog.close({'name': this.draftFormGroup.get('name').value});
    }
  }

  public close() {
    this.dialog.close(null);
  }
}
