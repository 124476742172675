import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable()
export class ValidationFieldsService {

  public unvalidate = 0;

  constructor(private http: HttpClient) {
  }


  public list(): Observable<any> {
    return this.http.get('/api/validation_fields/list', httpOptions);
  }

  public list_mine(): Observable<any> {
    return this.http.get('/api/validation_fields/list_mine', httpOptions);
  }

  public reject(_id): Observable<any> {
    return this.http.get('/api/validation_fields/reject?_id=' + _id, httpOptions);
  }

  public list_fields(skip:number, limit:number, filter=null, sort=null, direction='1'): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set('skip', skip.toString()).set('limit', limit.toString());
    if(sort) {
      httpParams = httpParams.set('sort_field', sort).set('sort_direction', direction);
    }
    if(filter) {
      httpParams = httpParams.set('filter', filter);
    }

    return this.http.get('/api/validation_fields/list_fields', {params: httpParams});
  }

  public unvalidate_count(): Observable<any> {
    return this.http.get('/api/validation_fields/unvalidate_count', httpOptions);
  }

  public addField(field): Observable<any> {
    return this.http.post('/api/validation_fields/add', { field : field}, httpOptions);
  }

  public updateValidation(field): Observable<any> {
    return this.http.post('/api/validation_fields/update_validation', { field : field}, httpOptions);
  }

  public import(params: any): Observable<any> {
    return this.http.post('/api/validation_fields/import', params, {headers: httpOptions.headers});
  }

  public export(): Observable<any> {
    return this.http.get('/api/validation_fields/export', {headers: httpOptions.headers});
  }
}
