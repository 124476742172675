<section>
  <app-loader *ngIf="loading"></app-loader>

  <div>
    <h3>Export Specifications</h3>
  </div>
  <div>
    <div class="layout-column" [formGroup]="formSpecPlan">
      <mat-form-field class="flex">
        <input matInput placeholder="Advertiser" name="advertiser" type="text" formControlName="advertiser">
        <mat-error *ngIf="formSpecPlan.get('advertiser').hasError('required')">Advertiser is required</mat-error>
      </mat-form-field>
      <mat-form-field class="flex">
        <input matInput placeholder="Campaign" name="campaign" type="text" formControlName="campaign">
        <mat-error *ngIf="formSpecPlan.get('campaign').hasError('required')">Campaign is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput  [matDatepicker]="startDatepicker" placeholder="Start date" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
        <mat-error *ngIf="formSpecPlan.get('startDate').hasError('required')">Date is required</mat-error>
      </mat-form-field>
<!--      <mat-form-field>-->
<!--        <input matInput [matDatepickerFilter]="deliveryDateFilter" [matDatepicker]="deliveryDatepicker" placeholder="Delivery date" formControlName="deliveryDate">-->
<!--        <mat-datepicker-toggle matSuffix [for]="deliveryDatepicker"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #deliveryDatepicker></mat-datepicker>-->
<!--        <mat-error *ngIf="formSpecPlan.get('deliveryDate').hasError('required')">Delivery Date is required</mat-error>-->
<!--        <mat-error *ngIf="formSpecPlan.get('deliveryDate').hasError('dates')">Delivery Date must be less or equal than Start Date</mat-error>-->
<!--      </mat-form-field>-->
      <mat-form-field class="flex">
        <input matInput placeholder="Contacts" name="contacts" type="text" formControlName="contacts">
        <mat-error *ngIf="formSpecPlan.get('contacts').hasError('emails')">Contacts are required</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="actions">
    <button mat-stroked-button color="warn" (click)="sidenav.close()">
      Cancel
    </button>
    <button mat-raised-button color="primary" [disabled]="!formSpecPlan.valid" (click)="export()">
      Export
    </button>
  </div>

</section>
