import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, FormGroup} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import { merge } from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import {ValidationFieldsService} from "../../services/validation_fields.service";
import {ActivatedRoute} from "@angular/router";
import {XslxDataParser} from "../../utils/xslx-data-parser";
import * as moment from "moment";
import {SpecEditComponent} from "../specs/spec-edit/spec-edit.component";
import {ConfirmRejectComponent} from "./confirm-reject/confirm-reject.component";
import {SpecDeleteComponent} from "../specs/spec-delete/spec-delete.component";

@Component({
  templateUrl: './field-view.component.html',
  styleUrls: ['./field-view.component.scss']
})
export class FieldViewComponent implements OnInit, AfterViewInit {
  public fieldsData: MatTableDataSource<any> = new MatTableDataSource<any>();
  public tableColumns: string[] = ['created_by', 'created_at', 'update_at','text','validate', 'action'];
  public selectField: any;
  public filterForm: FormGroup;

  public tableIsLoading = false;
  public hasData = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(private validationFieldsService: ValidationFieldsService,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filter: new FormControl('')
    });

  this.activatedRoute.queryParams.subscribe(params => {
    this.selectField = params.field;
  });
  }

  ngAfterViewInit(): void {
    this.filterForm.get('filter').valueChanges.subscribe(() => this.paginator.pageIndex = 0)
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0)
    merge(this.sort.sortChange, this.paginator.page, this.filterForm.get('filter').valueChanges).pipe(
      startWith(''),
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {

        this.loadData();
      }
    );
  }

  public QuillToHtml(quill) {
    return XslxDataParser.deltaToHtml(quill)
  }

  private loadData() {
    this.tableIsLoading = true;

    const filter = this.filterForm.get('filter').value;

    let count = 10;
    let page = 0;
    if (this.paginator) {
      count = this.paginator.pageSize;
      page = this.paginator.pageIndex;
    }
    let sort_column = null;
    let sort_direction = null;

    if (this.sort && this.sort.active && this.sort.direction) {
      sort_column = this.sort.active;
      sort_direction = this.sort.direction === 'asc' ? '1' : '-1';
    }
    this.validationFieldsService.list().subscribe(response => {
      if (response.elements) {
        let validate = []
        let unvalidate = []
        let fields =  response.elements[this.selectField].sort((a, b) => this.compare(a, b, sort_column, +sort_direction ))
        for(let field of fields) {
          if(field.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
            if(field.validate)
            {
              validate.push(field);
            }
            else {
              unvalidate.push(field);
            }
          }
        }
        this.fieldsData.data = unvalidate.concat(validate);
        this.tableIsLoading = false;
        this.hasData = this.fieldsData.data.length > 0;
      }
    });
  }

  public addFieldValue() {
    let dialog = this.dialog.open(SpecEditComponent, { data: {}, autoFocus: false });

    dialog.afterClosed().subscribe(result => {
      console.log(result)
      if(result) {
        let field = {
          text: this.getPlainText(result).trim(),
          richText: result.richText,
          field: this.selectField,
          validate: false
        };

        if (field.richText.ops) {
          field.richText = field.richText.ops;
          delete field.richText.ops;
        }

        this.validationFieldsService.addField(field).subscribe(response => {
          if(response.status && response.status === 'success') {
            this.loadData();
          }

        });
      }
    });
  }

  compare( a, b , field, order=1) {
    if ( a[field] < b[field] ){
      return -1 * order;
    }
    if ( a[field] > b[field] ){
      return 1 * order;
    }
    return 0;
  }

  public changeValidation($event, element) {
    console.log($event, element)
    element.validate = $event.value;

    this.validationFieldsService.updateValidation(element).subscribe(response => {
      if(response.status && response.status === 'success') {
        this.loadData()
        this.getUnvalidateCount()
      }
    })
  }

  rejectField(element, isDelete = false) {
    if(isDelete) {
      this.dialog.open(SpecDeleteComponent).afterClosed().subscribe(result => {
        if(result) {
          this.validationFieldsService.reject(element._id).subscribe(response => {
            if(response.status && response.status == 'success') {
              this.loadData();
              this.getUnvalidateCount()
            }
          })
        }
      })
    }
    else {
      this.dialog.open(ConfirmRejectComponent).afterClosed().subscribe(result => {
        if(result) {
          this.validationFieldsService.reject(element._id).subscribe(response => {
            if(response.status && response.status == 'success') {
              this.loadData();
              this.getUnvalidateCount()
            }
          })
        }
      })
    }


  }

  createdAt(_id) {
    return moment(new Date(parseInt(_id.substring(0,8), 16)*1000)).format('YYYY-MM-DD');
  }

  public formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  public getPlainText(element) {

    if(element && element.text) {
      let plainText = XslxDataParser.revertescapeHtml(element.text).replace(/<[^>]*>/g, '');

      return plainText;
    }
    return '';
  }

  editText(element) {
    let _dialog = this.dialog.open(SpecEditComponent,{ data: element, autoFocus: false });

    _dialog.afterClosed().subscribe(result => {
      if(result) {
        element.text = this.getPlainText(result).trim();
        element.richText = result.richText;
        if (element.richText.ops) {
          element.richText = element.richText.ops;
          delete element.richText.ops;
        }

        this.validationFieldsService.updateValidation(element).subscribe(response => {
          if(response.status && response.status === 'success') {
            this.loadData()
            this.getUnvalidateCount()
          }
        })
      }
    })
  }

  public getUnvalidateCount() {
    this.validationFieldsService.unvalidate_count().subscribe(response => {
      this.validationFieldsService.unvalidate = response.count;
    })
  }
}
