<app-loader *ngIf="loading"></app-loader>
<div id="auth-wrapper">
  <div id="auth">
    <h1>ipbridge</h1>

    <div>
      <button mat-button (click)="signIn()">
        <img src="/assets/Office365.png" />
      </button>
      <div id="login-alert" *ngIf="errors"><mat-icon color="warn" class="mdi mdi-alert-circle"></mat-icon>{{ errors }}</div>
    </div>
  </div>

</div>
