import { Component, OnInit, Inject } from '@angular/core';
import {XslxDataParser} from '../../../utils/xslx-data-parser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-library-merge',
  templateUrl: './library-merge.component.html',
  styleUrls: ['./library-merge.component.scss']
})
export class LibraryMergeComponent{

  quillConfig= {
    toolbar: []
  }

  specs = []

  constructor(public dialog: MatDialogRef<LibraryMergeComponent>, @Inject(MAT_DIALOG_DATA) public  data: any, private sanitized: DomSanitizer) {
    for(let k in data['changes']) {
      if(this.deltaToHtml(data[k].richText) !== this.deltaToHtml(data['changes'][k].richText))
      this.specs.push({
        'key': k,
        'checked': true,
        'old': data[k],
        'new': data['changes'][k]
      });
    }

  }

  public hasChecked() {
    for(let spec of this.specs) {
      if(spec.checked === true)
        return true;
    }
    return false;
  }

  public checkAll($event) {
    for(let spec of this.specs) {
      spec.checked = $event.checked;
    }
  }

  public deltaToHtml(delta) {
    return XslxDataParser.deltaToHtml(delta, this.sanitized);
  }

  public discardChanges() {
    this.data['changes'] = {}
    this.dialog.close({merge: false, data: this.data});
  }

  public merge() {
    for(let spec of this.specs) {
      if(spec.checked === false) {
        delete this.data['changes'][spec.key];
      }
    }

    this.dialog.close({merge: true, data: this.data}) ;
  }



  public close() {
    this.dialog.close();
  }
}
