<div class="layout-column layout-align-center-stretch">
  <div mat-dialog-title class="layout-column layout-align-space-between-stretch block-title">
    <div class="layout-row layout-align-space-between-center">
      <h5>Spec Edit</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>

  <div mat-dialog-content>
    <div class="list-add">

      <div *ngFor="let row of elements;let indexOfelement=index;" style="display: flex; flex-direction: column; position: relative">
        <div style="position: absolute; z-index: 10; right: 10px; top: 10px">
          <mat-icon aria-label="remove" class="mdi mdi-24px mdi-close" (click)="removeLine(indexOfelement)" matTooltip="Remove Row"></mat-icon>
        </div>
        <div style="display: flex; flex-direction: row;margin: 35px 15px 5px 15px; width: 100%;justify-content: space-between">
          <div style="margin-left: 15px">
            <validation-field-select
              [value]="row.element"
              [fieldList]="elementList"
              [fieldName]="'element'"
              (fieldSelect)="changeSelect($event, row,'element')"></validation-field-select>
          </div>
          <div *ngIf="isMultiselect">
            <button type="button" color="accent" (click)="addValue(row.values)" mat-stroked-button>
              <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-plus"></mat-icon>
              <span>Add Line</span>
            </button>
          </div>
        </div>

        <mat-divider style="width: 100%"></mat-divider>
        <div style="display: flex; flex-direction: row; width:100%" *ngIf="row.values">
          <mat-list style="width: 100%">
            <mat-list-item *ngFor="let line of row.values; let indexOfValue=index" style="width: 100%">
              <div style="display: flex; flex-direction: row;width: 100%;justify-content: space-between">
                <div>
                  <validation-field-select
                    [value]="line.select"
                    [fieldList]="fieldList"
                    [fieldName]="fieldName"
                    [readOnly]="false"
                    [isMultiselect]="false"
                    (fieldSelect)="changeSelect($event, line)"></validation-field-select>
                </div>
                <div *ngIf="(withNumberField || (line.select && line.select.text && line.select.text.trim()=='caractères max' || line.select.text.trim()=='mots')) && line.select.text !== '-' ">
                  <mat-form-field>
                    <mat-label>Set number</mat-label>
                    <input matInput [value]="line['input2']"  [min]="0" (change)="changeField($event, line, 'input2')"
                           onkeydown="return event.key !== 'e' && event.key !== 'E'"
                           pattern="[0-9-]*" autocomplete="off">
                  </mat-form-field>
                </div>
                <div *ngIf="isDimensionDialog && (row.element.text.toLowerCase()==='video' || row.element.text.toLowerCase().indexOf('image')>=0 || row.element.text.toLowerCase().indexOf('bannière') >=0)">
                  <validation-field-select
                    [readOnly]="false"
                    [value]="line.ratio"
                    [fieldList]="ratioList"
                    [fieldName]="'ratio'"
                    (fieldSelect)="changeSelect($event, line,'ratio')"></validation-field-select>
                </div>
                <div >
                  <button type="button" color="accent" (click)="removeValue(row.values, indexOfValue)" mat-icon-button matTooltip="New Item" >
                    <mat-icon aria-label="remove" class="mdi mdi-24px mdi-close-circle"  matTooltip="Remove Row"></mat-icon>
                  </button>
                </div>
              </div>
            </mat-list-item>
          </mat-list>

        </div>

      </div>

      <div>
        <button type="button" color="accent" (click)="addRow()" mat-stroked-button>
          <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-plus"></mat-icon>
          <span>Add Element</span>
        </button>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="layout-row layout-align-space-between-stretch">
    <button mat-stroked-button color="warn" [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!isValid()">Save</button>
  </div>
</div>
