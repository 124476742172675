import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {SpecPlanListComponent} from './components/spec-plan-list/spec-plan-list.component';
import {AuthGuardService} from './services/auth-guard.service';
import {SpecsComponent} from './components/specs/specs.component';
import {UsersComponent} from './components/users/users.component';
import {LibraryListComponent} from './components/library-spec/library-list/library-list.component';
import {CanDeactivateGuard} from './services/can-deactivate.guard';
import {DraftListComponent} from './components/draft-list/draft-list.component';
import {TutoComponent} from './components/tuto/tuto.component';
import {HelpComponent} from './components/help/help.component';
import {SourcesComponent} from './components/sources/sources.component';
import {AdminComponent} from './components/admin/admin.component';
import {FieldViewComponent} from './components/field-view/field-view.component';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {AdminGuardService} from './services/admin-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/spec-plans', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'spec-plans', component: SpecPlanListComponent, canActivate: [ AuthGuardService]},
  { path: 'spec-library', component: LibraryListComponent, canActivate: [ AuthGuardService]},
  { path: 'draft-list', component: DraftListComponent, canActivate: [ AuthGuardService]},
  { path: 'specs', component: SpecsComponent, canActivate: [ AuthGuardService], canDeactivate: [CanDeactivateGuard]},
  { path: 'users', component: UsersComponent, canActivate: [ AuthGuardService]},
  { path: 'sources', component: SourcesComponent, canActivate: [ AuthGuardService]},
  { path: 'help', component: HelpComponent, canActivate: [ AuthGuardService]},
  { path: 'admin', component: AdminComponent, canActivate: [ AuthGuardService]},
  { path: 'field-view', component: FieldViewComponent, canActivate: [ AuthGuardService]},
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [ AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
