import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LoginService} from './login.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }


  checkLogin(url: string): Observable<boolean> | boolean {
    if (url == '/' && this.loginService.isLogged === false)
      return true;
    this.loginService.redirectUrl = url;
    if (this.loginService.isLogged) { return true; }
    return this.loginService.IsAuthenticated().pipe(
      catchError((e) => {
        this.router.navigated = false;
        this.router.navigate(['/login']);
        //location.href = '/login';
        return of(false);}),
      map((d) =>{
        if (d)
        {
          if (url == '/')
            this.router.navigate(['/spec-plans'])
          return true;
        }
      }));
  }

}
