<ng-template #uploadTemplate let-reference="reference">
  <div class="flex layout-column">
    <div class="flex layout-row layout-align-start-center">
      <label>
        Choose your file :
      </label>
      <button type="button" mat-button (click)="reference.openInputFileUpload()" color="warn">
        <mat-icon class="mdi mdi-upload"></mat-icon>
        <span>CHOOSE YOUR FILE</span>
      </button>
    </div>
    <div>
      <a href="sources/download/example">Download Template</a>
    </div>
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #itemTemplate let-reference="reference" let-files="files">
  <mat-card class="layout-column layout-margin" *ngFor="let _file of files; let i = index">
    <div class="layout-row layout-align-space-between-center">
      <div class="flex-25" *ngIf="reference._preview">
        <div
          style="width: 64px; height: 64px; border-radius: 50%; background-size: cover; background-position: center center; background-repeat: no-repeat"
          [style.background-image]="_file.content"></div>
      </div>
      <div class="flex layout-column">
        <p><b>{{ _file.name }}</b></p>
        <p>
          <small>{{ _file.size / 1024 / 1024 | number:'1.2-2' }} Mo</small> &nbsp;
        </p>
      </div>

      <button type="button" mat-icon-button *ngIf="_file.error || !(_file.progress < 100)" color="warn"
              (click)="reference.onDelete(i)">
        <mat-icon class="mdi mdi-24 mdi-delete"></mat-icon>
      </button>
    </div>
    <mat-progress-bar mode="determinate" [value]="_file.progress" *ngIf="!(_file.error) && _file.progress < 100">
    </mat-progress-bar>
  </mat-card>
</ng-template>

<div class="layout-column layout-align-center-stretch">

  <div mat-dialog-title class="layout-column layout-align-space-between-stretch block-title">
    <div class="layout-row layout-align-space-between-center">
      <h5>Import values</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>

  <div mat-dialog-content>

    <form [formGroup]="form" class="layout-column flex">

      <app-mat-file-upload
        [uploadTemplate]="uploadTemplate"
        [itemTemplate]="itemTemplate"
        [preview]="false"
        [multiple]="false"
        [maxSize]="128*(1024*1024)"
        [maxLength]="256"
        accept="*"
        [formControl]="form.get('file')">
      </app-mat-file-upload>
    </form>

    <button mat-raised-button color="primary" (click)="import()" [class.spinner]="loading" [disabled]="!form.valid || loading">Import</button>
  </div>
</div>
