<mat-sidenav-container>
  <mat-sidenav-content>
    <section style="height: 85%">
      <div id="header">
        <div>
          <h1>Help</h1>
        </div>

        <div class="actions">
         <!-- <button mat-raised-button (click)="editHelp()">
            <span>
              <mat-icon matPrefix class="mdi mdi-18px mdi-import"></mat-icon>
              Edit
            </span>
          </button>-->
        </div>
      </div>

      <div style="height: 100%">
        <iframe src="https://globalappsportal.sharepoint.com/sites/Projet-IPBridge314/_layouts/15/Doc.aspx?sourcedoc={3bdff062-7eaf-46cc-bcc9-93c9248f4cbd}&amp;action=embedview&amp;wdStartOn=1" width="100%" height="100%" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
      </div>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
