import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {SpecificationService} from "../../../services/specification.service";

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  public formData: FormGroup;

  public sourceList = [];

  constructor(public dialog: MatDialogRef<AddComponent>, private specificationService: SpecificationService) { }

  ngOnInit(): void {
    this.formData = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required, this.ValidatorFileNotExists.bind(this)])
    });

    this.specificationService.listSources().subscribe(response => {
      if(response.sources) {
        let existingNames = [];

        this.sourceList = [];
        for ( let source of response.sources ) {
          if ( existingNames.indexOf(source.name) < 0) {
            this.sourceList.push(source);
            existingNames.push(source.name);
          }
        }
      }
    });

  }

  public ValidatorFileNotExists(control: AbstractControl) {
    let exists = false;
    this.sourceList.forEach(source => {
      if (control.value === source.name) {
        exists = true;
        return;
      }
    });
    if (exists) {
      return {exists: true};
    }
    else {
      return null;
    }
  }

  public onFileChange(event) {
    this.formData.get('name').setValue(this.formData.get('file').value['name']);
  }

  public upload(): void {
    this.specificationService.uploadSource(
      this.formData.get('file').value['content'].split(',')[1],
      this.formData.get('file').value['name'],
      this.formData.get('name').value
    ).subscribe((response) => {
      if (response.status && response.status === 'success') {
        this.dialog.close({success:true});
      }
    });
  }
}
