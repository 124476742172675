import { SpecMergeComponent } from './spec-merge/spec-merge.component';
import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { SpecificationService } from '../../services/specification.service';
import { XslxDataParser } from '../../utils/xslx-data-parser';
import { SpecEditComponent } from './spec-edit/spec-edit.component';
import { SpecDeleteComponent } from './spec-delete/spec-delete.component';
import { SourceUploadComponent } from './source-upload/source-upload.component';
import {ActivatedRoute, Router} from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { LibrarySpecComponent } from '../library-spec/library-spec.component';
import {LibraryListComponent} from '../library-spec/library-list/library-list.component';
import {SpecSaveComponent} from './spec-save/spec-save.component';
import {DraftSaveComponent} from './draft-save/draft-save.component';
import {LoginService} from '../../services/login.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import {ValidationFieldsService} from "../../services/validation_fields.service";
import {ValidationFieldEditComponent} from "../../utils/validation-field-edit/validation-field-edit.component";

@Component({
  templateUrl: './specs.component.html',
  styleUrls: ['./specs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpecsComponent implements OnInit, AfterViewInit {

  public isExporting = false;
  specsList: MatTableDataSource<any> = new MatTableDataSource<any>();
  paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  sidenavOpened = false;
  sidenavselected = '';
  requiredColumns = ['adserving', 'publisher', 'site', 'format'];
  specPlanIsValid = true;
  checkExport = false;
  headers = [];
  headersCorrespondances: any;
  allHeaders: any;
  specs = [];
  changes = [];
  actionsEnable = false;
  draftName = null;
  draftId = null;

  specialFields = ['file_type', 'dimensions', 'max_weight', 'max_duration']

  adservingOptions = [
    'Hébergement CM',
    'Hébergement Sizmek',
    'Hébergement WCM',
    'Hébergement Adform',
    'Hébergement partenaire',
    'Hébergement Innovid',
    'Hébergement Flashtalking'
  ];

  loading = false;

  infos = null;

  filename = null;
  _id = null;

  dataChecked = [];
  hasData = false;

  validationFields: {};

  @ViewChild('tablespecs') tablespecs: MatTable<any>;
  @ViewChild('sidenav',{static:true}) public sidenav: MatSidenav;
  @ViewChild('tab') public tab: MatTabGroup;
  @ViewChild('libManager') public libManager: LibraryListComponent;

  constructor(private specificationService: SpecificationService,
              private loginService: LoginService,
              public dialog: MatDialog, private router: Router,
              private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar,
              private validationFieldsService: ValidationFieldsService,
              private sanitized: DomSanitizer
            ) {
    this.checkExport = false;

  }

  ngOnInit() {
    this.loadValidationFields();
    this.sidenav.closedStart.subscribe(() => {
      this.sidenavOpened = false;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.loading = true;
      if (params._id) {
        this._id = params._id;
      }
      if (params.filename) {
        this.filename = params.filename;
        this.specificationService.getHeadersByLabel().subscribe((response: any) => {
          this.headersCorrespondances = response;


        this.specificationService.import_existing(params.filename).subscribe(result => {

          if (result.status && result.status === 'success') {
            let data = result.data;
            let dataParser = new XslxDataParser(data, this.headersCorrespondances, this.sanitized);
            dataParser.parse(true);
            const headers = ['checking', 'alerte', 'actions'].concat(dataParser.headers);

            const line = [];
            let dates = {}
            for (let i = 0; i < dataParser.specs.length; i++) {
              const row = dataParser.specs[i];
              for (let j = 0; j < row.length; j++) {
                if (this.headersCorrespondances[row[j].header] === 'creation_date') {
                  dates[i] = row[j].richText[0].insert;
                }
              }
            }

            this.specificationService.calculate_days(dataParser.infos['startDate'], dates).subscribe((res)=> {

              if(res.dates) {
                for (let i = 0; i < dataParser.specs.length; i++) {
                  const row = dataParser.specs[i];
                  line[i] = {};

                  for (let j = 0; j < row.length; j++) {
                    if (this.headersCorrespondances[row[j].header] === 'creation_date' ) {

                      line[i][row[j].header] = { text: '<span style="font-size: 11px;color: #000000;">'+res.dates[i]+'</span>', richText: [{insert: res.dates[i]}], header: row[j].header };

                    }
                    else {
                      if (this.headersCorrespondances[row[j].header] === 'border' && row[j].richText[0].insert.toUpperCase().trim() !== 'OUI') {
                        line[i][row[j].header] = { text: '<span style="font-size: 11px;color: #000000;">Non</span>', richText: [{insert: 'Non'}], header: row[j].header };
                      }
                      else {
                        line[i][row[j].header] = { text: row[j].text, richText: row[j].richText, header: row[j].header };
                      }
                    }

                  }
                }
                this.specsList.data = line;
                this.hasData = (this.specsList.data.length > 0) ?  true : false;
                this.headers = headers;
                this.infos = dataParser.infos;
                if (!params.filename) {
                  this.snackBar.open('File import success!', null, {
                    duration: 2000
                  });
                }
              }

            })



          } else if (result.status && result.status === 'invalid') {
            this.snackBar.open('Imported file is invalid', null, {
              duration: 2000
            });
          } else {
            this.snackBar.open('An error occurred while importing file', null, {
              duration: 2000
            });
          }

          this.loading = false;
        });
        });
      }

      if (params.draft) {

        this.draftId = params.draft;
        this.loadSavedSpecs(params.draft);
      }
    });
    this.loadSpecsList();
  }

  public loadSpecsList() {
    this.loading = true;

    this.specificationService.getHeadersByLabel().subscribe((response: any) => {
      this.headersCorrespondances = response;
      this.loading = false;
    });

    this.specificationService.getHeaders().subscribe((response: any) => {
      this.allHeaders = response;
      this.loading = false;
    });


  }

  public ngAfterViewInit(): void {

  }

  public cantExport() {
    const disabled = !this.specsList.data || this.specsList.data.length === 0 || !this.specPlanIsValid;

    return disabled;
  }

  public saveInLibrary() {
    let dialog = this.dialog.open(SpecSaveComponent, {autoFocus: false});
    this.loading = true;
    dialog.afterClosed().subscribe(result => {
      this.loading = false;
      if (result) {

        const specs = this.specsList.data.map(element => {
          return Object.values(element);
        });
        this.specificationService.save(specs, this.headersCorrespondances).subscribe(response => {
          if (response.status && response.status === 'merge-required') {
            this.snackBar.open('Merge required !', null, { duration: 2000 });
            this.changes = response.specs;
          }
          else {
            if (response.status === 'success') {
              this.snackBar.open('Save success !', null, { duration: 2000 });
              //this.specsList.data = [];
              this.hasData = (this.specsList.data.length > 0) ?  true : false;
            }
          }
        });
      }
    });
  }

  public saveForLater() {
    let dialog = this.dialog.open(DraftSaveComponent, {data: {name: this.draftName, id: this.draftId}, autoFocus: false});
    this.loading = true;
    dialog.afterClosed().subscribe(result => {
      this.loading = false;


      if(result && result.name) {
        let obj = {
          specsList: this.specsList.data,
          headersCorrespondances: this.headersCorrespondances,
          allHeaders: this.allHeaders
        };
        if (!this.draftId || (this.draftId && result.duplicate)) {
          this.specificationService.draftInsert(result.name, obj, this.loginService.user).subscribe(response => {

            localStorage.setItem('saved-specs', JSON.stringify(obj));
            this.snackBar.open('Save success !', null, { duration: 2000 });
          });
        }
        else {
          this.specificationService.draftUpdate(this.draftId, result.name, obj).subscribe(response => {

            localStorage.setItem('saved-specs', JSON.stringify(obj));
            this.snackBar.open('Save success !', null, { duration: 2000 });
          });
        }
      }
    });
  }

 public loadSavedSpecs(id) {

    this.specificationService.draftGet(id).subscribe(response => {
      if (response.status && response.status === 'success') {
        let obj = response.draft;

        this.draftName = response.name;
        this.headersCorrespondances = obj.headersCorrespondances;
          this.allHeaders = obj.allHeaders;
          let headers = [];
          this.allHeaders.forEach((element) => {
            headers.push(element.label);
          });
          this.headers = ['checking', 'alerte', 'actions'].concat(headers);

          this.specsList.data = obj.specsList;
          this.hasData = (this.specsList.data.length > 0) ?  true : false;
        localStorage.setItem('saved-specs', JSON.stringify(obj));
      }
    });

 }

 public isSavedExists() {
   return localStorage.getItem('saved-specs');
 }

  public openEditDialog(cell, indexRow, indexCell) {
    let sp = JSON.parse(JSON.stringify(cell));
    let dialog = this.dialog.open(SpecEditComponent, { data: sp, autoFocus: false });

    dialog.afterClosed().subscribe(result => {
      if (result) {

        this.specsList.data[indexRow][indexCell] = result;
        this.specsList.data[indexRow][indexCell].position = indexCell;

        if (this.specsList.data[indexRow][indexCell].richText.ops) {
          this.specsList.data[indexRow][indexCell].richText = this.specsList.data[indexRow][indexCell].richText.ops;
          delete this.specsList.data[indexRow][indexCell].richText.ops;

          this.hasData = (this.specsList.data.length > 0) ?  true : false;
          this.tablespecs.renderRows();
          this.specPlanIsValid = true;
        }
      }
    });
  }



  public openSourceUploadDialog(cell) {
    let dialog = this.dialog.open(SourceUploadComponent, {
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(response => {
      if (response.urlText && response.urlDelta) {
        cell.richText = response.urlDelta;
        cell.text = response.urlText;
      }
    });
  }

  public openLibrarySpecDialog() {
    let dialog = this.dialog.open(LibrarySpecComponent, {
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        for (let item of result) {
          let spec = {};
          let lastHistory = item.history[item.history.length - 1].values;

          for (let key in this.headersCorrespondances) {
            let headerField = this.headersCorrespondances[key];

            if (lastHistory[headerField]) {
              spec[key] = lastHistory[headerField];
              if(spec[key]['values']) {
                delete spec[key]['values'];
              }
              spec[key]['header'] = key;
              if (headerField === 'border' && spec[key].richText[0].insert.toUpperCase().trim() !== 'OUI') {
                spec[key]['richText'] = [{insert: 'Non'}];
              }
              spec[key].text = XslxDataParser.deltaToHtml(lastHistory[headerField].richText, this.sanitized);
            }
          }

          this.specsList.data.push(spec);
        }

        this.fillHeadersIfEmpty();
        this.hasData = (this.specsList.data.length > 0) ?  true : false;
        this.tablespecs.renderRows();
      }
    });
  }

  public isActionEnable() {
    for (let spec of this.specs) {
      if (spec.selected) {
        return true;
      }
    }
    return false;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.specsList.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.specsList.data.forEach((row, rowIndex) => this.selection.select(rowIndex));
  }

  public toggle(sidenavselected) {
    this.sidenavselected = sidenavselected;
    this.sidenavOpened = !this.sidenavOpened;
  }

  public setSpecList(event) {
    this.specsList.data = event.specs;
    this.hasData = (this.specsList.data.length > 0) ?  true : false;
    this.headers = event.headers;
  }

  public setSpecChange(event) {
    if(event.specs) {
      this.changes = event.specs;
      this.isExporting = false;
    }
    else {
      this.isExporting = true;
    }

  }

  delete() {
    this.dialog.open(SpecDeleteComponent, {
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      if (result) {
        for (let index in this.selection.selected) {
          this.removeChange(index);
        }
        this.specsList.data = this.specsList.data.filter((value, index) => {
          return (this.selection.selected.indexOf(index) === -1);
        });
        this.hasData = (this.specsList.data.length > 0) ?  true : false;
        this.selection.clear();
        this.actionsEnable = false;
        this.specPlanIsValid = true;
      }
    });
  }

  deleteOne(index) {
    this.dialog.open(SpecDeleteComponent, {
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      if (result) {
        this.specsList.data.splice(index, 1);

        this.hasData = (this.specsList.data.length > 0) ?  true : false;
        this.tablespecs.renderRows();
        this.specPlanIsValid = true;
      }
    });
  }

  public specHasChanges(index) {
    for (let spec of this.changes) {
      if (spec.index === index) {
        this.specPlanIsValid = false;
        return true;
      }
    }
    return false;
  }

  public removeChange(index) {
    let i = 0;
    for (let spec of this.changes) {
      if (spec.index === index) {
        this.changes.splice(i, 1);
        return;
      }
      i++;
    }
  }

  public getSpecChange(index) {
    for (let spec of this.changes) {
      if (spec.index === index) {
        return spec;
      }
    }
    return null;
  }


  public merge(index) {

    const specs = this.specsList.data.map(element => {
      return Object.values(element);
    });

    this.specificationService.save(specs, this.headersCorrespondances).subscribe(response => {
      if (response.status && response.status === 'merge-required') {

        this.changes = response.specs;

        let change = this.getSpecChange(index);

        let data = {
          oldSpec: change.spec,
          newSpec: this.specsList.data[index],
          changedHeaders: change.changes,
          headersCorrespondances: this.headersCorrespondances
        };

        let dialog = this.dialog.open(SpecMergeComponent, { data: data, autoFocus: false });

        dialog.afterClosed().subscribe(results => {
          this.specificationService.merge([results.merged]).subscribe(result => {
            for (let field of results.merged) {
              this.specsList.data[index][field.header].richText = field.richText;
              this.specsList.data[index][field.header].text = XslxDataParser.deltaToHtml(field.richText, this.sanitized);
            }
            this.hasData = (this.specsList.data.length > 0) ?  true : false;
            this.tablespecs.renderRows();
            this.removeChange(index);
            this.specPlanIsValid = true;
          });
        });
      }
    });

  }

  public createRow() {
    let spec = {};
    this.allHeaders.forEach((element) => {
      spec[element.label] = {};

      spec[element.label]['header'] = element.label;

      spec[element.label]['richText'] = [{
        insert: '',
        attributes: {
          size: '11px',
          color: '#000000',
          bold: false,
          italic: false,
          underline: false
        }
      }];

      spec[element.label]['text'] = '';
    });

    this.fillHeadersIfEmpty();

    this.specsList.data.push(spec);
    this.hasData = (this.specsList.data.length > 0) ?  true : false;

    this.tablespecs.renderRows();
  }

  private getHeadersByField() {
    return Object.entries(this.headersCorrespondances)
      .reduce((obj, [key, value]) => (obj[this.headersCorrespondances[key]] = key, obj), {});
  }

  public getRequiedColumnsEmptyCells(spec) {
    let emptyCells = [];
    let headers = this.getHeadersByField();
    for (let field of this.requiredColumns) {
      let headerLabel = headers[field];
      if (!spec[headerLabel].text || XslxDataParser.revertescapeHtml(spec[headerLabel].text).replace(/<[^>]*>/g, '').trim() === '' || (field === 'adserving')) {
        if(field !== 'adserving'){
          emptyCells.push(headerLabel);
        }
        else {
          if (!spec[headerLabel].richText[0].insert.trim().toLowerCase().startsWith('hébergement partenaire') && this.adservingOptions.indexOf(XslxDataParser.revertescapeHtml(spec[headerLabel].text).replace(/<[^>]*>/g, '').trim()) < 0){
            emptyCells.push(headerLabel);
          }
          else {

            if(spec[headerLabel].richText[0].insert.trim().toLowerCase().startsWith('hébergement partenaire') && (spec[headerLabel].richText[0].insert.trim().toLowerCase().split('-').length <= 1 ||(spec[headerLabel].richText[0].insert.trim().toLowerCase().split('-').length > 1 &&spec[headerLabel].richText[0].insert.trim().toLowerCase().split('-')[1].trim() === '')) ) {
              emptyCells.push(headerLabel);

            }
          }
        }

      }
    }
    return emptyCells;
  }

  public cellIsEmpty(cell) {
    if (!cell || !cell.text || (cell.text && XslxDataParser.revertescapeHtml(cell.text).replace(/<[^>]*>/g, '').trim() === '')) {

      return true;
    }
    return false;
  }

  public rowsHasError() {
    for(let i in this.specsList.data) {
      if(this.getAlertMessage(this.specsList.data[i], i).length > 0) {
        return true;
      }
    }
    return false;
  }

  public getAlertMessage(spec, index) {
    const messages = [];

    if (this.isValidationRequire(spec)) {
      messages.push('Waiting for field validation');
    }

    if (this.hasDuplicates(spec)) {
      messages.push('Duplicate rows');
    }

    if (this.getRequiedColumnsEmptyCells(spec).length > 0) {
      messages.push('The first 4 cells can`\'t be empty');
    }

    if (this.specHasChanges(index)) {
      messages.push('You modified an existing specification.\nYou have to merge it');
    }

    let headers = this.getHeadersByField();
    let formatHeader =  headers['format'];
    if (this.getPlainText(spec[formatHeader]).split('|').length !==  3) {
      messages.push('Format field must have 3 parts separated by |(Pipe)');
    }

    if(messages.length > 0) {
      this.specPlanIsValid = false;
    }
    return messages;
  }
  getValidationFieldById(id, fieldname) {
    for(let field of this.validationFields[fieldname]) {
      if(field._id === id)
        return field;
    }
    return null;
  }
  isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }
  isValidationRequire(spec) {

    for (let field in this.validationFields) {
      let headersByFields = this.getHeadersByField()

      if(this.specialFields.indexOf(field)>=0) {
        if(spec[headersByFields[field]].values) {

          for(let el of spec[headersByFields[field]].values) {
            if(!this.isIterable(el.values)) {
              let a = 0;
            }
            if(el.values) {
              for(let v of el.values) {
                let f = this.getValidationFieldByText(field, v.select);
                if(f && !f.validate)
                  return true;
              }
              let f = this.getValidationFieldByText('element', el.element.text);
              if(f && !f.validate)
                return true;
            }
          }
        }
      }
    else {

        let text =  this.getPlainText(spec[headersByFields[field]]);
        let f = this.getValidationFieldByText(field, text);
        if (f)
          spec[headersByFields[field]]['id_field'] = f._id;
        if(f && !f.validate)
          return true;
      }

    }
    return false;
  }

  isValidate(element, col) {
    if(['checking', 'alerte', 'actions'].indexOf(col) < 0) {
      let validate = element[col].validate !==null && element[col].validate === false;
      if(col === 'Régie')
      return validate;
    }

    return false
  }

  initValidationField(element, fieldname) {
    if(fieldname === 'dimensions') {
      let a=0
    }
    if(element.id_field) {
      return this.getValidationFieldById(element.id_field, fieldname);
    }
    let self = this;
    let text = this.getPlainText(element).toLowerCase().trim()
    let f = this.getValidationFieldByText(element, fieldname);
    return f? f: {text:''};
  }

  getValidationFieldByText(field, text) {
    if(!this.validationFields[field]) {
      return null;
    }
    if(text.text) {
      text = text.text;
    }
    for(let f of this.validationFields[field]) {
      if(f.text.trim().toLowerCase() === text.trim().toLowerCase()) {
        return f;
      }
    }
    return null;
  }

  public validateSpec(spec) {
    if (this.hasDuplicates(spec) || this.getRequiedColumnsEmptyCells(spec).length > 0) {
      this.specPlanIsValid = false;
      return false;
    }
    return true;
  }

  public hasDuplicates(spec) {
    let count = 0;
    let headers = this.getHeadersByField();

    this.specsList.data.forEach((element) => {
      let sameFields = true;

      for (let field of this.requiredColumns) {
        let headerLabel = headers[field];

        if (XslxDataParser.revertescapeHtml(spec[headerLabel].text).replace(/<[^>]*>/g, '').trim() !== XslxDataParser.revertescapeHtml(element[headerLabel].text).replace(/<[^>]*>/g, '').trim()) {
          sameFields = false;
          break;
        }
      }

      if (sameFields) {
        count++;
      }
    });

    return count > 1;
  }

  public manageLibrary() {
    this.tab.selectedIndex = 1;
    this.libManager.getData();
  }

  public closeLibrary() {
    this.tab.selectedIndex = 0;
  }

  public duplicate(spec, index) {
    this.specsList.data.splice(index, 0, JSON.parse(JSON.stringify(spec)));
    this.tablespecs.renderRows();
  }

  public saveOneInlibrary(spec, index) {
    let dialog = this.dialog.open(SpecSaveComponent, {autoFocus: false});
    this.loading = true;
    dialog.afterClosed().subscribe(result => {
      this.loading = false;
      if (result) {

        const specs = [spec].map(element => {
          return Object.values(element);
        });
        this.specificationService.save(specs, this.headersCorrespondances).subscribe(response => {
          if (response.status && response.status === 'merge-required') {
            this.snackBar.open('Merge required !', null, { duration: 2000 });
            this.changes = response.specs;
          }
          else {
            if (response.status === 'success') {
              this.snackBar.open('Save success !', null, { duration: 2000 });
              //this.specsList.data = [];
              this.hasData = (this.specsList.data.length > 0) ?  true : false;
            }
          }
        });
      }
    });
  }

  private fillHeadersIfEmpty() {
    if (this.headers.length === 0) {
      let headers = [];

      this.allHeaders.forEach((element) => {
        headers.push(element.label);
      });



      this.headers = ['checking', 'alerte', 'actions'].concat(headers);

    }
  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1.text.toLowerCase() === o2.text.toLowerCase();
  }

  public loadValidationFields() {
    this.validationFieldsService.list_mine().subscribe(result => {
      this.validationFields = result.elements;
    })
  }

  public switchEditModeSpecialFields(element, fieldName, withNumberField) {
    let _dialog = this.dialog.open(ValidationFieldEditComponent, {
      data: {
        richText: element.richText,
        fieldName: fieldName,
        withNumberField: withNumberField,
        fieldList: this.validationFields[fieldName],
        elements: this.validationFields['element'],
        ratioList: this.validationFields['ratio'],
        isDimensionDialog: fieldName === 'dimensions'
      },
      autoFocus: false
    })

    _dialog.afterClosed().subscribe(result => {
      if(result) {
        element.richText = result.richText;
        element.text = result.text;
        element.values = result.values;

        this.loadValidationFields();
      }
    })

  }

  public addLine(element) {
    if(!element.values)
      element.values = []
    element.values.push({input: '', select: ''});
  }

  public addLineBis(element) {
    if(!element.values)
      element.values = []
    element.values.push({input1: '', input2: '', select: ''});
  }

  public removeLine(element, indexOfelement) {
    element.values.splice(indexOfelement,1)
    element.text = XslxDataParser.deltaToHtml(element.richText);
}


  public addValueForValidation(fieldName, element) {
    let dialog = this.dialog.open(SpecEditComponent, { data: {}, autoFocus: false });

    dialog.afterClosed().subscribe(result => {

      if(result) {
        let field = {
          text: this.getPlainText(result).trim(),
          richText: result.richText,
          field: fieldName,
          validate: false
        };

        this.validationFieldsService.addField(field).subscribe(response => {
          if(response.status && response.status === 'success') {
            element = result;
            if (element.richText.ops) {
              element.richText = element.richText.ops;
              delete element.richText.ops;
              this.loadValidationFields();
              this.snackBar.open('Field value sent for validation !',null, {duration:3000})
            }
          }
        })
      }
    });
  }

  public getPlainText(element) {

    if(element && element.text) {
      let plainText = XslxDataParser.revertescapeHtml(element.text).replace(/<[^>]*>/g, '');

      return plainText;
    }
    return '';
  }

  public onBorderCheck(event, element, index) {

    this.dataChecked[index] = event.checked;

    let value = 'non';

    if (event.checked) {
      value = 'oui';
    }
    value.toLowerCase();

    element.text = '<span style="font-size: 11px;color: #000000;">' + value + '</span>';
    element.richText[0].insert = value;
    this.specsList.data[index]['BORDURE 1X1 NOIRE'] = element;
  }

  public isChecked(index) {
    return (index in this.dataChecked) ? this.dataChecked[index] : false;
  }

  public adservingChange(event, element) {
    element.text = '<span style="font-size: 11px;color: #000000;">' + event.value + '</span>';
    element.richText[0].insert = event.value;
  }


  public validationFieldChange(event, element) {

    element.text = '<span style="font-size: 11px;color: #000000;">' + event.text + '</span>';
    element.richText = event.richText;
    element['id_field'] = event._id;

    this.loadValidationFields();

  }

  public initSpecialFieldSelect(element, index) {
    let spt = element.richText[index].insert.split(':');
    if(!element.values) {
      element.values = []
      for(let rt of element.richText) {
        if(rt.insert.trim() != '') {
          let spt2 = rt.insert.split(':');
          element.values.push({input: spt2[0].trim(), select: spt2.length>1?spt2[1].trim():''})
        }
      }
    }
    return {'text': element.values[index].select}
  }

  public initSpecialFieldInput(element, index) {
    let spt = element.richText[index].insert.split(':');

    if(!element.values) {
      element.values = []
      for(let rt of element.richText) {
        if(rt.insert.trim() != '') {
          let spt2 = rt.insert.split(':');
          element.values.push({input: spt2[0].trim(), select: spt2.length>1?spt2[1].trim():''})
        }
      }
    }

    return element.values[index].input;
  }



  public changeSpecialFieldSelect($event, element, index) {

      element.values[index].select = $event.value.text
      if(element.values[index].select.trim() != '' && element.values[index].input.trim()) {
        element.richText = []
        for(let v of element.values) {
          element.richText.push({insert: v.input + ' : '+ v.select});
          element.richText.push({insert: '\n'});
        }

        element.text = XslxDataParser.deltaToHtml(element.richText);

      }
  }

  public changeSpecialFieldInput($event, element, index) {
    element.values[index].input = $event.target.value
    if(element.values[index].select.trim() != '' && element.values[index].input.trim()) {
      element.richText = []
      for(let v of element.values) {
        element.richText.push({insert: v.input + ' : '+ v.select});
        element.richText.push({insert: '\n'});
      }

      element.text = XslxDataParser.deltaToHtml(element.richText);
    }
  }

  public initSpecialFieldSelectBis(element, index) {
    let spt = element.richText[index].insert.split(':');
    if(!element.values) {
      element.values = []
      for(let rt of element.richText) {
        let input1 = '';
        let input2 = '';
        let select = '';

        if(rt.insert.trim() != '') {
          let spt2 = rt.insert.split(':');
          if (spt2.length > 1)
          {
            input1 = spt2[0].trim();
            let spt3 = spt2[1].split(' ');
            if(spt3.length > 1) {
              input2 = spt3[0].trim();
              select = spt3[1].trim();
            }
            else {
              select = spt2[1].trim();
            }

          }
          element.values.push({input1: input1, input2: input2,select: select})
        }
      }
    }
    return {'text': element.values[index].select}
  }

  public initSpecialFieldInputBis(element, index, inputSelect=1) {
    let spt = element.richText[index].insert.split(':');
    if(!element.values) {
      element.values = []
      for(let rt of element.richText) {
        let input1 = '';
        let input2 = '';
        let select = '';

        if(rt.insert.trim() != '') {
          let spt2 = rt.insert.split(':');
          if (spt2.length > 1)
          {
            input1 = spt2[0].trim();
            let spt3 = spt2[1].split(' ');
            if(spt3.length > 1) {
              input2 = spt3[0].trim();
              select = spt3[1].trim();
            }
            else {
              select = spt2[1].trim();
            }

          }
          element.values.push({input1: input1, input2: input2,select: select})
        }
      }
    }

    return element.values[index]['input' + inputSelect];
  }



  public changeSpecialFieldSelectBis($event, element, index) {

    element.values[index].select = $event.value.text
    if(element.values[index].select.trim() != '' && element.values[index].input1.trim()&& element.values[index].input2.trim()) {
      element.richText = []
      for(let v of element.values) {
        element.richText.push({insert: v.input1 + ' : ' + v.input2  + ' ' + v.select});
        element.richText.push({insert: '\n'});
      }

      element.text = XslxDataParser.deltaToHtml(element.richText);

    }
  }

  public changeSpecialFieldInputBis($event, element, index, inputSelect=1) {
    element.values[index]['input' + inputSelect] = $event.target.value
    if(element.values[index].select.trim() != '' && element.values[index].input1.trim()&& element.values[index].input2.trim()) {
      element.richText = []
      for(let v of element.values) {
        element.richText.push({insert: v.input1 + ' : ' + v.input2  + ' ' + v.select});
        element.richText.push({insert: '\n'});
      }

      element.text = XslxDataParser.deltaToHtml(element.richText);

    }
  }

  public partnerChange(event, element) {
    element.text = '<span style="font-size: 11px;color: #000000;">' + 'Hébergement partenaire - ' + event.text + '</span>';
    element.richText[0].insert = 'Hébergement partenaire - ' + event.text;


  }

  public creationDateChange(event, element) {
    let value = '';
    if (event.option) {
      value = 'MEL J-' + event.option.value;
    }
    if (event.target) {
      value = 'MEL J-' + event.target.value;
    }
    element.text = '<span style="font-size: 11px;color: #000000;">' + value + '</span>';
    element.richText[0].insert = value;
  }

  public isGlobalEditable(col: any, element: any): boolean {
    return this.isEditableFirst(col, element) || this.isEditableSecond(col, element) || this.isEditableThird(col, element);
  }

  public isEditableFirst(col: any, element: any): boolean {
    return (
      col !== 'actions'
      && col !== 'alerte'
      && !this.cellIsEmpty(element[col])
      && this.headersCorrespondances[col] !== 'adserving'
      && this.headersCorrespondances[col] !==  'border'
      && this.headersCorrespondances[col] !==  'creation_date'
      && (this.validationFields && !this.validationFields[this.headersCorrespondances[col]])
      && this.specialFields.indexOf(this.headersCorrespondances[col]) < 0
    );
  }

  public isEditableSecond(col: any, element: any): boolean {
    return (
      (
        this.headersCorrespondances[col] === 'file_type'
        || this.headersCorrespondances[col] === 'dimensions'
        || this.headersCorrespondances[col] === 'max_weight'
        || this.headersCorrespondances[col] === 'max_duration'
      )
      && !element[col]['editMode']
    );
  }

  public getValidationFieldText(col, element) {
    let field = this.headersCorrespondances[col]
    if(field === 'dimensions') {
      let a=0
    }
    if(this.validationFields && this.validationFields['element']) {
      let result = this.initFields(element[col].richText, field === 'dimensions', ['max_weight', 'max_duration'].indexOf(field)>=0, field)
       if(result.length>0) {
         element[col].text = XslxDataParser.deltaToHtml(result);
         element[col].richText = result;
       }
        else {
         element[col].text = XslxDataParser.deltaToHtml(element[col].richText);
       }

    }


    return element[col].text;
  }

  initFields(richText, isDimensionDialog, withNumberField, selectField) {
    if(selectField === 'dimensions') {
      let a=0;
    }
    let elements = []
    for(let v of this.validationFields['element']) {
      elements.push(v.text)
    }
    let fieldValues = []
    for(let v of this.validationFields[selectField]) {
      fieldValues.push(v.text)
    }
    let ratios = []
    for(let v of this.validationFields['ratio']) {
      ratios.push(v.text)
    }
    let out = []
    let lines = []
    for(let rt of richText) {
      let spt = rt.insert.split('\n')
      for (let row of spt) {
        if(row.trim() !=='') {
          lines.push({insert: row.trim()});
        }
      }
    }
    for(let rt of lines) {

      if(rt.insert.trim() !== '') {



        if(rt.insert.indexOf(':')>=0) {

          let spt = rt.insert.split(/:(.*)/)
          if(selectField == 'dimensions') {
            for (let val of spt[1].split(',')) {
              let value = {input1: {text: ''}, input2: '', select: {text: ''}, ratio: {text: ''}};
              value.input1 = {text: spt[0].trim()};
              if(elements.indexOf(value.input1.text)<0) {
                continue
              }
              if(spt.length > 1 && val)
                value.select = {text: val.trim()};
              if(withNumberField || (val && (val.trim().toLowerCase().indexOf('caractères max') > 0 ||
                  val.trim().toLowerCase().indexOf('mots') > 0) &&
                isDimensionDialog && val.trim() !== '-')) {

                let regexExp = /([0-9-]+)\s*([a-zA-Z è]+)/
                let result = regexExp.exec(val.trim());
                let spt2 = [];
                if(result)
                  spt2 = [result[1], result[2]]

                if(spt2.length > 1 &&  !isNaN(parseInt(spt2[0].trim()))) {
                  value.input2 = spt2[0].trim();
                  value.select = {text: spt2[1].trim()};
                }
              }
              if( isDimensionDialog && (spt[0].trim().toLowerCase() === 'video' || spt[0].trim().toLowerCase().indexOf('image') >= 0 || spt[0].trim().toLowerCase().indexOf('bannière') >= 0)) {
                let spt2 = val.split('|')
                value.select = {text: spt2[0].trim()};
                if( spt2.length > 1) {
                  value.ratio = {text: spt2[1].trim()};
                  if(ratios.indexOf(value.ratio.text)<0) {
                    continue
                  }
                }
              }
            }
          }
        else {
            let value = {input1: {text: ''}, input2: '', select: {text: ''}, ratio: {text: ''}};
            value.input1 = {text: spt[0].trim()};
            value.select = {text: spt[1].trim()};
            if(withNumberField || (spt[1].trim().toLowerCase().indexOf('caractères max') > 0 && isDimensionDialog)) {

              let regexExp = /([0-9]+)\s*([a-zA-Z è]+)/
              let result = regexExp.exec(spt[1].trim());
              let spt2 = []
              if(result)
                spt2 = [result[1], result[2]]

              if(spt2.length > 1 &&  !isNaN(parseInt(spt2[0].trim()))) {
                value.input2 = spt2[0].trim();
                value.select = {text: spt2[1].trim()};
              }
            }
            if( isDimensionDialog && (spt[0].trim().toLowerCase() === 'video' || spt[0].trim().toLowerCase().indexOf('image') >= 0 || spt[0].trim().toLowerCase().indexOf('bannière') >= 0)) {
              let spt2 = spt[1].split('|')
              value.select = {text: spt2[0].trim()};
              if( spt2.length > 1) {
                value.ratio = {text: spt2[1].trim()};
                if(ratios.indexOf(value.ratio.text)<0) {
                  continue
                }
              }
            }
            if(elements.indexOf(value.input1.text)<0) {
              continue
            }
            if(selectField === 'file_type' || selectField === 'dimensions')
            {
              let split = value.select.text.split(',')
              let valid = true;
              for(let v of split) {
                if(fieldValues.indexOf(v.trim())<0) {
                  valid = false;
                  continue;
                }
              }

              if(!valid) {
                continue;
              }
            }
            else {
              if(fieldValues.indexOf(value.select.text)<0) {
                continue
              }
            }
            if(!rt.insert.endsWith('\n'))
              rt.insert+='\n'
            out.push(rt)
          }
        }
          }
        }


    return out
  }

  public isEditableThird(col: any, element: any): boolean {
    return (
      (
        this.headersCorrespondances[col] === 'file_type'
        || this.headersCorrespondances[col] === 'dimensions'
      )
      && element[col]['editMode']
    );
  }

  public isEditableFourth(col: any, element: any): boolean {
    return (
      (
        this.headersCorrespondances[col] === 'max_weight'
        || this.headersCorrespondances[col] === 'max_duration'
      )
      && element[col]['editMode']
    );
  }

  public redirectClick(col: any, element: any, rowIndex: any): any {
    if (this.isEditableFirst(col, element)) {
      this.openEditDialog(element[col], rowIndex, element[col]?.header);
    }

    if (this.isEditableSecond(col, element)) {
      this.switchEditModeSpecialFields(element[col], this.headersCorrespondances[col], this.headersCorrespondances[col] === 'max_weight' || this.headersCorrespondances[col] === 'max_duration' );
    }

    if (this.isEditableThird(col, element)) {
      this.switchEditModeSpecialFields(element[col], this.headersCorrespondances[col], false);
    }

    // @TODO Attention : les paramètres 2 et 3 ont des valeurs au pif car la signature ne prends pas de valeurs par défauts
    if (this.isEditableFourth(col, element)) {
      this.switchEditModeSpecialFields(element[col], this.headersCorrespondances[col], false);
    }
  }

}
