import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export interface User {
  id: string;
  fullname: string;
  firstname: string;
  lastname: string;
  email: string;
  creationDate: string;
  lastLogin: string;
}


@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {
  }

  public addUser(email: string): Observable<any> {
    return this.http.post('/api/users/add', {email: email}, httpOptions);
  }

  public deleteUser(user: User): Observable<any> {
    return this.http.post('/api/users/delete', {id: user.id}, httpOptions);
  }

  public list(page: number, count: number, filter: string, sort_column: string, sort_direction: string): Observable<any> {
    let params = new HttpParams()
      .set('page', String(page))
      .set('count', String(count));

    if (sort_column) {
      params = params.set('sort_column', sort_column);

      if (sort_direction) {
        params = params.set('sort_direction', sort_direction);
      }
    }

    if (filter) {
      params = params.set('filter', filter);
    }

    return this.http.get('/api/users/list', {params: params});
  }
}
