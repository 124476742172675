<ng-template #uploadTemplate let-reference="reference">
  <div class="flex layout-column">
    <div class="flex layout-row layout-align-start-center">
      <label>
        Choose your file :
      </label>
      <button type="button" mat-button (click)="reference.openInputFileUpload()" color="warn">
        <mat-icon class="mdi mdi-upload"></mat-icon>
        <span>CHOOSE YOUR FILE</span>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #itemTemplate let-reference="reference" let-files="files">
  <mat-card class="layout-column layout-margin" *ngFor="let _file of files; let i = index">
    <div class="layout-row layout-align-space-between-center">
      <div class="flex-25" *ngIf="reference._preview">
        <div
          style="width: 64px; height: 64px; border-radius: 50%; background-size: cover; background-position: center center; background-repeat: no-repeat"
          [style.background-image]="_file.content"></div>
      </div>
      <div class="flex layout-column">
        <p><b>{{ _file.name }}</b></p>
        <p>
          <small>{{ _file.size / 1024 / 1024 | number:'1.2-2' }} Mo</small> &nbsp;
        </p>
      </div>

      <button type="button" mat-icon-button *ngIf="_file.error || !(_file.progress < 100)" color="warn"
        (click)="reference.onDelete(i)">
        <mat-icon class="mdi mdi-24 mdi-delete"></mat-icon>
      </button>
    </div>
    <mat-progress-bar mode="determinate" [value]="_file.progress" *ngIf="!(_file.error) && _file.progress < 100">
    </mat-progress-bar>
  </mat-card>
</ng-template>

<div class="layout-column layout-align-center-stretch">

  <div mat-dialog-title class="layout-column layout-align-space-between-stretch block-title">
      <div class="layout-row layout-align-space-between-center">
          <h5>Upload Source</h5>
          <button mat-icon-button [mat-dialog-close]="false">
              <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
          </button>
      </div>

      <div class="line"></div>
  </div>

  <div mat-dialog-content>

    <mat-accordion>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
              Upload a new source
          </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="formData" class="layout-column flex">
          <app-mat-file-upload
            [uploadTemplate]="uploadTemplate"
            [itemTemplate]="itemTemplate"
            [preview]="false"
            (ngModelChange)="onFileChange($event)"
            [multiple]="false"
            [maxSize]="128*(1024*1024)"
            [maxLength]="256"
            accept="*"
            [formControl]="formData.get('file')">
          </app-mat-file-upload>
          <mat-form-field>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
        </form>

        <button mat-raised-button color="primary" (click)="upload()" [disabled]="!formData.valid">Upload</button>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
              Use an existing source
          </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="existingSourceForm" class="layout-column flex">
          <mat-form-field>
            <mat-select formControlName="sourceSelect" placeholder="Source">
              <mat-option>
                <ngx-mat-select-search formControlName="sourceSearch" placeholderLabel="Search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let source of filteredSources | async" [value]="source">
                {{source.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <button mat-raised-button color="primary" (click)="useExistingSource()" [disabled]="!existingSourceForm.valid">Use this</button>
      </mat-expansion-panel>

      <mat-expansion-panel (click)="sourceOption === 'new_source'">
        <mat-expansion-panel-header>
          <mat-panel-title>
              Replace existing source
          </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="replaceSourceForm" class="layout-column flex">
          <mat-form-field>
            <mat-select formControlName="sourceSelect" (ngModelChange)="replaceSelectChange($event)"
              placeholder="Source">
              <mat-option>
                <ngx-mat-select-search formControlName="sourceSearch" placeholderLabel="Search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let source of filteredSourcesReplace | async" [value]="source">
                {{source.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
          <app-mat-file-upload
            [uploadTemplate]="uploadTemplate"
            [itemTemplate]="itemTemplate"
            [preview]="false"
            [multiple]="false"
            (ngModelChange)="onReplaceFileChange($event)"
            [maxSize]="128*(1024*1024)"
            [maxLength]="256"
            accept="*"
            [formControl]="replaceSourceForm.get('file')">
          </app-mat-file-upload>
        </form>

        <button mat-raised-button color="primary" (click)="replaceSource()" [disabled]="!replaceSourceForm.valid">Replace Existing Source</button>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
