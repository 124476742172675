<div class="layout-column layout-align-center-stretch">
  <div mat-dialog-title class="layout-column layout-align-space-between-stretch block-title">
    <div class="layout-row layout-align-space-between-center">
      <h5>Rename Source</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="renameSourceForm" class="layout-column flex">
      <mat-form-field>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
    </form>

    <button mat-raised-button color="primary" (click)="rename()" [disabled]="!renameSourceForm.valid">Rename Source</button>
  </div>
</div>
