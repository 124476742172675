import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, FormGroup} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import { merge } from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import {ValidationFieldsService} from "../../services/validation_fields.service";
import {FieldImportComponent} from "./field-import/field-import.component";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit {
  public fieldsData: MatTableDataSource<any> = new MatTableDataSource<any>();
  public tableColumns: string[] = ['field', 'validate', 'unvalidate', 'action'];

  public filterForm: FormGroup;

  public tableIsLoading = false;
  public hasData = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(private validationFieldsService: ValidationFieldsService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngAfterViewInit(): void {
    this.filterForm.get('filter').valueChanges.subscribe(() => this.paginator.pageIndex = 0)
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0)
    merge(this.sort.sortChange, this.paginator.page, this.filterForm.get('filter').valueChanges).pipe(
      startWith(''),
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {

        this.loadData();
      }
    );
  }

  private loadData() {
    this.tableIsLoading = true;

    const filter = this.filterForm.get('filter').value;

    let skip = 0;
    let limit = 10;
    if (this.paginator)
    {
      limit = this.paginator.pageSize;
      skip = this.paginator.pageIndex * this.paginator.pageSize;
    }
    let sort_column = null;
    let sort_direction = null;

    if (this.sort && this.sort.active && this.sort.direction) {
      sort_column = this.sort.active;
      sort_direction = this.sort.direction === 'asc' ? '1' : '-1';
    }
    this.validationFieldsService.list_fields(skip, limit, filter, sort_column, sort_direction).subscribe(response => {
      if (response.elements) {

        this.fieldsData.data = response.elements
        this.tableIsLoading = false;
        this.hasData = this.fieldsData.data.length > 0;
      }
    });
  }

  public import() {
    this.dialog.open(FieldImportComponent).afterClosed().subscribe(result => {
        if(result) {
          this.loadData();
        }
    });
  }

  public export() {
    const token = localStorage.getItem('access_token');

    if (token) {

      window.location.assign('/api/validation_fields/export?token=' + encodeURI(token));
    }

  }

}
