<mat-sidenav-container>
  <mat-sidenav-content>
    <section>
      <div id="header">
        <div>
          <h1>{{selectField}}</h1>
          <span class="sub-title">List of fields to validate</span>
        </div>
        <div class="actions">
          <button mat-raised-button (click)="addFieldValue()">
            <mat-icon class="mdi mdi-24px mdi-import"></mat-icon>
            <div class="btn-text">Add Value</div>
          </button>
        </div>
        <div id="breadcrumb">
          <ul>
            <li><a [routerLink]="['/admin']">Admin</a></li>
            <li>/</li>
            <li><span>{{selectField}}</span></li>
          </ul>
        </div>
      </div>

      <div id="block-table-specs">
        <form [formGroup]="filterForm">
          <mat-form-field>
            <input matInput formControlName="filter" placeholder="FILTER">
            <button type="button" *ngIf="filterForm.get('filter').value" mat-button matSuffix mat-icon-button
                    aria-label="Clear" (click)="filterForm.get('filter').setValue('')">
              <mat-icon class="mdi mdi-close"></mat-icon>
            </button>
          </mat-form-field>
        </form>
        <span class="no-data" *ngIf="!hasData">
          There is no data to display<br />
        </span>

        <div [ngStyle]="{'display': hasData ? 'block' : 'none'}">


          <div class="div-table">
            <table mat-table matSort [dataSource]="fieldsData" matSortActive="_id" matSortDirection="desc"
                   [ngClass]='{ "loading": tableIsLoading }'>

              <!-- Loading -->
              <ng-container matColumnDef="loading">
                <th mat-header-cell class="table-loading" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </th>
              </ng-container>

              <!-- No Result -->
              <ng-container matColumnDef="noresult">
                <th mat-header-cell class="table-noresult" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  No result
                </th>
              </ng-container>

              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
                <td mat-cell *matCellDef="let element">
                  <div>{{createdAt(element._id)}}</div></td>
              </ng-container>

              <ng-container matColumnDef="update_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Validation date</th>
                <td mat-cell *matCellDef="let element">
                  <div>{{element.updatedAt? formatDate(element.updatedAt):''}}</div></td>
              </ng-container>

              <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell *matCellDef="let element">
                  <div>{{element.user?element.user.fullname:''}}</div></td>
              </ng-container>

              <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Text</th>
                <td mat-cell *matCellDef="let element">
                  <div class="editable-text"
                       (click)="editText(element)">
                    <div  [innerHTML]="QuillToHtml(element.richText)| safeHtml"></div>
                  </div></td>
              </ng-container>

              <ng-container matColumnDef="validate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Validate</th>
                <td mat-cell *matCellDef="let element">
                  <div>{{element.validate?'YES':'NON'}}</div></td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <div>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions" >
                    <mat-icon class="mdi mdi-18px mdi-dots-vertical"></mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button  *ngIf="!element.validate" mat-menu-item (click)="changeValidation({value:true}, element)">
                      <mat-icon class="mdi mdi-18px mdi-check"></mat-icon>
                      <div class="btn-text">Validate</div>
                    </button>
                    <button  *ngIf="!element.validate" mat-menu-item (click)="rejectField(element)">
                      <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
                      <div class="btn-text">Reject</div>
                    </button>
                    <button  *ngIf="element.validate" mat-menu-item (click)="rejectField(element, true)">
                      <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
                      <div class="btn-text">Delete</div>
                    </button>
                  </mat-menu>
                  </div>
                </td>

              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-header-row class="table-loading" *matHeaderRowDef="['loading']"></tr>
              <tr mat-header-row
                  [ngClass]="{'table-noresult' : true, 'display' : fieldsData.data.length == 0 && !tableIsLoading}"
                  *matHeaderRowDef="['noresult']"></tr>
              <tr mat-row   [ngClass]="{'invalidate': !row.validate }" *matRowDef="let row; columns: tableColumns;"></tr>
            </table>
          </div>

          <mat-paginator showFirstLastButtons="showFirstLastButtons" pageSize="10" [pageSizeOptions]="[10, 25, 50]">
          </mat-paginator>
        </div>

      </div>

    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
