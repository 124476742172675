
import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SpecificationService} from '../../../services/specification.service';
import {merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import {XslxDataParser} from '../../../utils/xslx-data-parser';
import {SpecEditComponent} from '../../specs/spec-edit/spec-edit.component';
import {SpecDeleteComponent} from '../../specs/spec-delete/spec-delete.component';
import {ConfirmSaveComponent} from '../confirm-save/confirm-save.component';
import {Router} from '@angular/router';
import {SpecSaveComponent} from '../../specs/spec-save/spec-save.component';
import {LibraryImportComponent} from '../library-import/library-import.component';
import {SourceUploadComponent} from '../../specs/source-upload/source-upload.component';
import * as moment from 'moment';
import {LibraryMergeComponent} from '../library-merge/library-merge.component';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import {ValidationFieldsService} from "../../../services/validation_fields.service";
import {ValidationFieldEditComponent} from "../../../utils/validation-field-edit/validation-field-edit.component";
import {LoginService} from "../../../services/login.service";

@Component({
  selector: 'app-library-list',
  templateUrl: './library-list.component.html',
  styleUrls: ['./library-list.component.scss']
})
export class LibraryListComponent implements OnInit, AfterViewInit {

  public loading = false;

  public isTab = false;

  public showOnlySelected = false;

  public newSpecs = []

  public data: MatTableDataSource<any> = new MatTableDataSource<any>();
  public columns: Array<string> = [ 'actions', 'alerte', 'updatedAt',  'adserving', 'publisher', 'site', 'format', 'user'];
  public columnsDisplay: Array<string> = [ 'actions', 'alerte', 'updatedAt', 'adserving', 'publisher', 'site', 'format', 'user'];

  public changedSpecs: any = {};

  requiredColumns = ['adserving', 'publisher', 'site', 'format'];
  adservingOptions = [
    'Hébergement CM',
    'Hébergement Sizmek',
    'Hébergement WCM',
    'Hébergement Adform',
    'Hébergement partenaire',
    'Hébergement Innovid',
    'Hébergement Flashtalking'
  ];
  public headersCorrespondances: any;
  public headersByKey = {'actions': 'actions', 'alerte': 'alerte', 'user': 'user', 'updatedAt': 'updatedAt'};
  public hasChanges = false;
  public filter: FormGroup;
  public hasErrors = false;

  public duplicateRows = [];

  public specialFields = ['file_type', 'dimensions', 'max_weight', 'max_duration']
  public validationFields = {};

  dataChecked = [];
  hasData = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tablespecs') tablespecs: MatTable<any>;

  @Output('manageSpecs') public manageSpecsOutput = new EventEmitter();

  constructor(private specificationService: SpecificationService,
              private validationFieldsService: ValidationFieldsService,
              private loginService: LoginService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private router: Router,
              private sanitized: DomSanitizer
  ) {
    this.loadValidationFields();
    this.getDuplicateElements();
    if (this.router.url !== '/spec-library') {
      this.isTab = true;
    }

    this.specificationService.getHeadersByLabel().subscribe((response: any) => {
      this.headersCorrespondances = response;

      let headers = [];


      for (let h in this.headersCorrespondances) {
        headers.push(this.headersCorrespondances[h]);
        this.headersByKey[this.headersCorrespondances[h]] = h;
      }
      headers = ['actions', 'alerte', 'user', 'updatedAt'].concat(headers);
      this.columns = headers;
      this.columnsDisplay = headers;
    });

    this.data.data = [];

    this.filter = this.fb.group({
      filter: ['']
    });
  }

  created_at(objid) {
    return moment(new Date(parseInt(objid.substring(0,8), 16)*1000)).format('YYYY-MM-DD HH:mm:ss');
  }

  ngAfterViewInit () {


    this.sort.sortChange.subscribe(
      () => this.paginator.pageIndex = 0
    );
    this.filter.get('filter').valueChanges.subscribe(() => this.paginator.pageIndex = 0)
    merge(this.filter.get('filter').valueChanges, this.sort.sortChange, this.paginator.page).pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(e => {


      this.specificationService.listChangedValues().subscribe(result => {
        let changed = result.data.map(el => {
          if(!el.history)
            return
          let history = el.history[el.history.length - 1].values;

          for(let header of this.columns) {
            if (header !== 'actions' && header !== 'user' && header !== 'alerte' && header !== 'updatedAt') {
              el[header] = {
                text: XslxDataParser.deltaToHtml(history[header].richText, this.sanitized),
                richText: history[header].richText,
                plainText: el[header]
              };
            }
          }
          if (this.changedSpecs[el._id]) {
            el.changes = this.changedSpecs[el._id].changes;
          }
          return el;
        });

        for (let data of changed) {
          if(!this.changedSpecs[data._id]) {
            this.changedSpecs[data._id] = data;
          }
        }

        this.getData();
      })
    });
  }

  public getDictValues(dict) {
    return Object.values(dict);
  }

  public loadValidationFields() {
    this.validationFieldsService.list_mine().subscribe(result => {
      this.validationFields = result.elements;
    })
  }

  public getDuplicateElements(){
    this.specificationService.getDuplicateRows().subscribe(res=> {
      if(res.data) {
        this.duplicateRows = res.data;
      }
    });
  }

  public switchEditModeSpecialFields(element, fieldName, withNumberField) {

    let elem =  element[fieldName];
    if(element.changes && element.changes[fieldName]) {
      elem = element.changes[fieldName];
    }
    let _dialog = this.dialog.open(ValidationFieldEditComponent, {
      data: {
        richText: elem.richText,
        fieldName: fieldName,
        withNumberField: withNumberField,
        fieldList: this.validationFields[fieldName],
        elements: this.validationFields['element'],
        ratioList: this.validationFields['ratio'],
        isDimensionDialog: fieldName === 'dimensions',
        isMultiselect: fieldName === 'dimensions' || fieldName === 'file_type'
      },
      autoFocus: false
    })

    _dialog.afterClosed().subscribe(result => {
      if(result) {
        this.loadValidationFields();
        this.change({
          text: '<span style="font-size: 11px;color: #000000;">' + result.text + '</span>',
          richText: result.richText,
          plainText: result.text,
          values: result.values
        },  element, fieldName, false);
      }
    })

  }

  public validationFieldChange(event, element, header) {

    this.loadValidationFields();
    this.change({
      text: '<span style="font-size: 11px;color: #000000;">' + event.text + '</span>',
      richText: event.richText,
      plainText: event.text
    },  element, header, false);
  }

  public addValueForValidation(fieldName, element) {
    let dialog = this.dialog.open(SpecEditComponent, { data: {}, autoFocus: false });

    dialog.afterClosed().subscribe(result => {
      if(result) {
        let field = {
          text: this.getPlainText(result).trim(),
          richText: result.richText,
          field: fieldName,
          validate: false
        };

        this.validationFieldsService.addField(field).subscribe(response => {
          if(response.status && response.status === 'success') {
            let value = result;
            if (value.richText.ops) {
              value.richText = value.richText.ops;
              delete value.richText.ops;

              this.change(value, element, fieldName, false);
              this.loadValidationFields();
              this.snackBar.open('Field value sent for validation !',null, {duration:3000})
            }
          }
        })
      }
    });
  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1.text.toLowerCase() === o2.text.toLowerCase();
  }

  public manageSpecs() {
    this.manageSpecsOutput.emit();
  }

  public change(value, element, key, refresh = true) {
    this.hasErrors = false;
    if (!element.changes) {
      element.changes = {};
    }
    if (!value.text) {
      value.text = "";
    }
    value.plainText = XslxDataParser.revertescapeHtml(value.text).replace(/<[^>]*>/g, '').trim();
    let htmlTxt = XslxDataParser.deltaToHtml(element[key].richText).trim()
    if ( (value.text).trim() !==  htmlTxt) {
      this.hasChanges = true;
      element.changes[key] = JSON.parse(JSON.stringify(value));
      if (!this.changedSpecs[element._id]) {
        this.changedSpecs[element._id] = JSON.parse(JSON.stringify(element));
      }

      // for(let k in element.changes) {
      //   this.changedSpecs[element._id][k] = JSON.parse(JSON.stringify(element.changes[k]));
      // }
      if ( this.requiredColumns.indexOf(key) >=0) {
        let adserving = this.getPlainText(element, 'adserving').trim();
        let publisher = this.getPlainText(element, 'publisher').trim();
        let site = this.getPlainText(element, 'site').trim();
        let format = this.getPlainText(element, 'format').trim();
        this.specificationService.isSpecExists(adserving, publisher, site, format).subscribe(res => {
          if(res.isExists === true && res.spec && res.spec['_id'] !== element['_id']) {
            this.duplicateRows.push({adserving: adserving, publisher: publisher, site: site, format: format, _id: element['_id']});
          }
          else {
            this.duplicateRows = this.duplicateRows.filter((obj) => {
              if(!(obj.adserving === adserving && obj.publisher === publisher && obj.site === site && obj.format === format)) {
                return obj;
              }
              return false;
            });
          }
        });
      }

      if(this.paginator)
        this.paginator.pageIndex = 0;
    }
    else {
      delete element.changes[key];
      if(!this.changedSpecs[element._id]) {
        this.changedSpecs[element._id] = JSON.parse(JSON.stringify(element));
      }

      if (!element.changes || Object.keys(element.changes).length === 0) {
        this.hasChanges = false;
        delete this.changedSpecs[element._id];
      }
    }
    let offset = this.paginator.pageIndex * this.paginator.pageSize;
    let limit = this.paginator.pageSize;

    let cleanedData = [];
    for(let d of this.data.data) {
      if(!this.changedSpecs[d._id]) {
        cleanedData.push(d);
      }
    }
    let newSpecs = []
    //let newSpecs = Object.values(this.changedSpecs).filter((value) => { return value['_id'].indexOf('new-item')>=0 })
    let changed = Object.values(this.changedSpecs)

    if(offset < changed.length) {
      changed = changed.slice(offset, offset + limit)
    }
    else {
      changed = [];
    }

    changed = changed.sort((a,b) => {

      if ( a['_id'] < b['_id'] ){
        return 1;
      }
      if ( a['_id'] > ['_id'] ){
        return -1;
      }
      return 0;
    });

    let values = changed.concat(cleanedData)
    this.data.data = values.slice(0, limit);

    if(refresh) {
      this.tablespecs.renderRows();
      this.getData();
    }

  }

  public getInnerHtml(element, header) {
    return (element.changes && element.changes[header]) ? element.changes[header].text : element[header].text;
  }

  public getElement(element, header) {
    return (element.changes && element.changes[header]) ? element.changes[header] : element[header];
  }

  public formatDate(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  public save() {
    this.dialog.open(ConfirmSaveComponent, {autoFocus: false}).afterClosed().subscribe(result => {
      if (result === 'save') {
        const changes = this.data.data.filter(element => {
          return (element.changes && Object.keys(element.changes).length > 0);
        });
        this.specificationService.updateSpecLibrary(changes).subscribe(result => {
          if (result.status && result.status === 'success') {
            this.getData();
            this.hasChanges = false;

            this.changedSpecs = {};
            this.newSpecs = []


            this.snackBar.open('Changes saved', null, {
              duration: 2000
            });

          }
        });
      }
      else {
        if (result === 'discard') {
          this.changedSpecs = {};
          this.getData();
          this.hasChanges = false;

          this.snackBar.open('Changes discarded', null, {
            duration: 2000
          });
        }
      }
    });

  }

  public rowsHasChanges() {
    return Object.keys(this.changedSpecs).length > 0;
  }

  public rowHasChanges(row) {
    // if(row._id.indexOf('new-item')>=0)
    //   return false;
    // if(row.changes && Object.keys(row.changes).length>0) {
    //   for(let key in row.changes) {
    //     if(this.requiredColumns.indexOf(key)>=0) {
    //       return false;
    //     }
    //   }
    // }

    return (row && row.changes && Object.keys(row.changes).length > 0) || (row && row.isNewRow);
  }

  public rowsHasError() {
    for(let spec of this.data.data) {
      if(this.getAlertMessage(spec).length > 0) {
        return true;
      }
    }
    return false;
  }

  public saveOne(element, merge=false) {
    this.loading = true;
    this.dialog.open(SpecSaveComponent, {data:merge, autoFocus: false}).afterClosed().subscribe((result) => {
      if (result) {

        const changes = [element];
        this.specificationService.updateSpecLibrary(changes).subscribe(response => {
          if (response.status && response.status === 'success') {


            if(element._id.indexOf('new-item')>=0) {
              for(let i=0; i< this.newSpecs.length; i++) {
                if(this.newSpecs[i]._id == element._id) {
                  this.newSpecs.splice(i,1);
                }
              }


            }
            delete element['changes'];
            delete this.changedSpecs[element._id];

            this.getData();
            this.tablespecs.renderRows();

            this.snackBar.open(merge ? 'Merge success !' : 'Save success !' , null, {
              duration:2000
            });
          }
          this.loading = false;
        });
      }

    });
  }

  public merge(element) {
    let _dialog = this.dialog.open(LibraryMergeComponent, {
      data: element,
      autoFocus: false
    });

    _dialog.afterClosed().subscribe(result => {
      if(result.merge) {
        this.saveOne(result.data, true);
      }
      else {
        element = result.data
      }
    })

  }

  public duplicate(element, index) {
    let spec = JSON.parse(JSON.stringify(element));
    let i = 0;
    while (this.changedSpecs['new-item-' + i]) {
      i++;
    }
    spec['user'] = null;
    spec['_id'] = 'new-item-' + i;

    spec['user'] = null;
    spec['updatedAt'] = null;
    spec['isNewRow'] = true;

    let adserving = this.getPlainText(spec,'adserving').trim();
    let site = this.getPlainText(spec,'site').trim();
    let publisher = this.getPlainText(spec,'publisher').trim();
    let format = this.getPlainText(spec,'format').trim();

    this.duplicateRows.push({adserving: adserving, site: site, publisher: publisher, format: format, _id: spec['_id']})
    this.changedSpecs['new-item-' + i] = spec;

    this.fillHeadersIfEmpty();

    this.newSpecs.unshift(spec);
    if(this.paginator)
      this.paginator.pageIndex = 0
    this.getData()
  }

  public discardOne(element) {
    delete element['changes'];
    delete this.changedSpecs[element._id];

    this.getData()
  }

  public openSourceUploadDialog(element, header) {
    let dialog = this.dialog.open(SourceUploadComponent, {
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(response => {
      if (response.urlText && response.urlDelta) {
        element.richText = response.urlDelta;
        element.text = response.urlText;
        const value = {
          text: response.urlText,
          richText: response.urlDelta,
          plainText: response.urlText
        };
        this.change(value, element, header, false);
      }
    });
  }

  public getAlertMessage(spec) {
    const messages = [];

    if (this.isValidationRequire(spec)) {
      messages.push('Waiting for field validation');
    }

    if (this.getRequiedColumnsEmptyCells(spec).length > 0) {
      messages.push('The first 4 cells can`\'t be empty');

    }
    let changes = spec;
    if ( spec && spec.changes && spec.changes['format']) {
      changes = spec.changes;
    }
    if (changes && changes['format'] && this.getPlainText(changes['format']).split('|').length !==  3) {
      messages.push('Format field must have 3 parts separated by |(Pipe)');
    }

    if (spec && this.hasDuplicates(spec)) {
      messages.push('Existing rows');
    }

    if(messages.length > 0) {
      this.hasErrors = true;
    }
    return messages;
  }

  isValidationRequire(spec) {
    for (let field in this.validationFields) {

      if(this.specialFields.indexOf(field)>=0) {
        if(!spec)
          return false;
        let elmt = spec[field]
        if(spec.changes && spec.changes[field])
          elmt = spec.changes[field]
        if(elmt && elmt.values) {
          for(let el of elmt.values) {
            for(let v of el.values) {
              let f = this.getValidationFieldByText(field, v.select);
              if(f && !f.validate)
                return true;
            }

            let f = this.getValidationFieldByText('element', el.element.text);
            if(f && !f.validate)
              return true;
          }
        }
      }
      else {

        let text =  this.getPlainText(spec[field]);
        if(text.toLowerCase().indexOf('campaign')>=0 && field === 'site')
          console.log()
        let f = this.getValidationFieldByText(field, text);
        if (f)
          spec[field]['id_field'] = f._id;
        if(f && !f.validate)
          return true;
      }

    }
    return false;
  }

  getValidationFieldByText(field, text) {
    if(!this.validationFields[field]) {
      return null;
    }

    if(text.text) {
      text = text.text;
    }

    for(let f of this.validationFields[field]) {
      if(f.text.trim().toLowerCase() === text.trim().toLowerCase()) {
        return f;
      }
    }
    return null;
  }

  private getHeadersByField() {
    return Object.entries(this.headersCorrespondances)
      .reduce((obj, [key, value]) => (obj[this.headersCorrespondances[key]] = key, obj), {});
  }

  public isSpecInDupicateList(spec) {
    let removeId = null;
    for (let i in this.duplicateRows) {
      let dup = this.duplicateRows[i];
      let isDifferent = false;
      for (let field of this.requiredColumns) {
        try {
          if (dup[field].trim() !== this.getPlainText(spec, field).trim()){
            isDifferent = true;
            if(spec['_id'] === dup['_id']) {
              removeId = i;
            }
            break;
          }
        }
        catch(e) {
          isDifferent = true;
        }

      }
      if(!isDifferent) {
        return true;
      }
    }
    if(removeId) {
      this.duplicateRows.splice(removeId,1);
    }
    return false;
  }

  public hasDuplicates(spec) {
    let count = 0;

    if(this.isSpecInDupicateList(spec)) {
      return true;
    }

    const specs = Object.values(this.changedSpecs);

    specs.forEach((element) => {
      let sameFields = true;
      if(element['_id'] === spec._id)
        return;

      for (let field of this.requiredColumns) {
        let headerLabel = field;
        let changes = spec;
        if( spec.changes && spec.changes[field]) {
          changes = spec.changes;
        }
        let changes2 = element;
        if ( element['changes'] && element['changes'][field]) {
          changes2 = element['changes']
        }

        if (XslxDataParser.revertescapeHtml(changes[headerLabel].text).replace(/<[^>]*>/g, '').trim() !== XslxDataParser.revertescapeHtml(changes2[headerLabel].text).replace(/<[^>]*>/g, '').trim()) {
          sameFields = false;
          break;
        }
      }

      if (sameFields) {
        count++;
      }
    });

    this.data.data.forEach((element) => {
      let sameFields = true;

      for (let field of this.requiredColumns) {
        let headerLabel = field;
        let changes = spec;
        if( spec.changes && spec.changes[field]) {
          changes = spec.changes;
        }
        let changes2 = element;

        if ( element && element.changes && element.changes[field]) {
          changes2 = element.changes;
        }

        let textChange1 = ''
        if(changes && changes[headerLabel] && changes[headerLabel].text && XslxDataParser.revertescapeHtml(changes[headerLabel].text)) {
          textChange1 = XslxDataParser.revertescapeHtml(changes[headerLabel].text).replace(/<[^>]*>/g, '').trim();
        }

        let textChange2 = ''
        if(changes2 && changes2[headerLabel] && changes2[headerLabel].text && XslxDataParser.revertescapeHtml(changes2[headerLabel].text)) {
          textChange2 = XslxDataParser.revertescapeHtml(changes2[headerLabel].text).replace(/<[^>]*>/g, '').trim();
        }


        if (textChange1 !== textChange2) {
          sameFields = false;
          break;
        }
      }

      if (sameFields) {
        count++;
      }
    });

    return count > 1;
  }

  public getRequiedColumnsEmptyCells(element) {

    let emptyCells = [];
    let headers = this.getHeadersByField();
    for (let field of this.requiredColumns) {
      let headerLabel = field;

      let spec = element;
      if (element && element.changes && element.changes[headerLabel])
        spec = element.changes;
      if(!spec) {
        return emptyCells;
      }

      if(spec && !spec[headerLabel]) {
        spec[headerLabel] = {}
      }

      if (!spec[headerLabel].text) {
        if(typeof spec[headerLabel] === 'string' || spec[headerLabel] instanceof String) {
          spec[headerLabel] = { text: spec[headerLabel], richText: [{insert: spec[headerLabel] }]};
        }
        else {
          spec[headerLabel].text = '';
        }
      }

      if (!spec[headerLabel].text || XslxDataParser.revertescapeHtml(spec[headerLabel].text).replace(/<[^>]*>/g, '').trim() === '' || (field === 'adserving' )) {

        if(field !== 'adserving'){
          emptyCells.push(headerLabel);
        }
        else {
          if (!spec[headerLabel].richText[0].insert.trim().toLowerCase().startsWith('hébergement partenaire') && this.adservingOptions.indexOf(XslxDataParser.revertescapeHtml(spec[headerLabel].text).replace(/<[^>]*>/g, '').trim()) < 0){
            emptyCells.push(headerLabel);
          }
          else {

            if(spec[headerLabel].richText[0].insert.trim().toLowerCase().startsWith('hébergement partenaire') && (spec[headerLabel].richText[0].insert.trim().toLowerCase().split('-').length <= 1 ||(spec[headerLabel].richText[0].insert.trim().toLowerCase().split('-').length > 1 &&spec[headerLabel].richText[0].insert.trim().toLowerCase().split('-')[1].trim() === '')) ) {
              emptyCells.push(headerLabel);

            }
          }
        }
      }
    }
    return emptyCells;
  }

  delete(element) {
    this.dialog.open(SpecDeleteComponent, {
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      if (result) {
        if(element['_id'].indexOf('new-item-')>=0)
        {
          this.data.data = this.data.data.filter(value => value['_id']!=element['_id'])
          this.newSpecs = this.newSpecs.filter(value => value['_id']!=element['_id'])
          let adserving = this.getPlainText(element, 'adserving').trim();
          let publisher = this.getPlainText(element, 'publisher').trim();
          let site = this.getPlainText(element, 'site').trim();
          let format = this.getPlainText(element, 'format').trim();

          this.duplicateRows = this.duplicateRows.filter((obj) => {
             if(!(obj.adserving === adserving && obj.publisher === publisher && obj.site === site && obj.format === format)) {
               return obj;
             }
             return false;
          });

        }
        if(this.changedSpecs[element['_id']]) {
          delete this.changedSpecs[element['_id']]
        }
        this.specificationService.deleteFromSpecLibrary(element._id).subscribe( result => {
          if (result.status && result.status == 'success') {
            this.getData();
          }
        });
      }
    });
  }

  public cellIsEmpty(cell) {

    if (!cell || !cell.text || XslxDataParser.revertescapeHtml(cell.text).replace(/<[^>]*>/g, '').trim() === '') {

      return true;
    }
    return false;
  }

  public openEditDialog(element, key) {
    let sp = JSON.parse(JSON.stringify(element[key]));
    if (element.changes && element.changes[key]) {
      sp = JSON.parse(JSON.stringify(element.changes[key]));
    }
    let dialog = this.dialog.open(SpecEditComponent, { data: sp, autoFocus: false });

    dialog.afterClosed().subscribe(result => {

      if (result) {
        let value = {
          text: result.text,
          richText: result.richText,
          plainText: XslxDataParser.revertescapeHtml(result.text).replace(/<[^>]*>/g, '').trim()
        };

        if (value.richText.ops) {
          value.richText = value.richText.ops;
          delete value.richText.ops;
        }

        this.change(value, element, key, false);
      }
    });
  }

  public setShowOnlySelected() {
    this.showOnlySelected = !this.showOnlySelected;
    this.getData();
  }

  private fillHeadersIfEmpty() {
  }

  public createRow() {
    let spec = {};
    for (var k in this.headersCorrespondances) {
      let element = this.headersCorrespondances[k]
      spec[element] = {};

      spec[element]['richText'] = [{
        insert: '',
        attributes: {
          size: '11px',
          color: '#000000',
          bold: false,
          italic: false,
          underline: false
        }
      }];

      spec[element]['text'] = '';
    }
    let i = 0;

    while (this.changedSpecs['new-item-' + i]) {
      i++;
    }
    spec['_id'] = 'new-item-' + i;

    spec['changes'] = {};

    for(let k in spec) {
      if (k != 'changes') {
        spec['changes'][k] = spec[k];
      }
    }
    spec['isNewRow'] = true;
    this.changedSpecs['new-item-' + i] = JSON.parse(JSON.stringify(spec));

    this.fillHeadersIfEmpty();

    this.newSpecs.unshift(JSON.parse(JSON.stringify(spec)));
    // this.data.data.unshift(JSON.parse(JSON.stringify(spec)))
    // this.tablespecs.renderRows();
    // this.hasData = true;
    if(this.paginator)
      this.paginator.pageIndex = 0;
    this.getData();
  }

  public import() {
    let dialog = this.dialog.open(LibraryImportComponent, {data: this.headersCorrespondances, autoFocus: false});

    dialog.afterClosed().subscribe(result=>{
      this.paginator.pageIndex = 0;

      let specs = result.specs;

      let i = 0;
      while (this.changedSpecs['new-item-' + i]) {
        i++;
      }

      for(let j in specs) {
        specs[j]['_id'] = 'new-item-' + i;

        specs[j]['changes'] = {};

        for(let k in specs[j]) {
          if (k != 'changes') {
            specs[j]['changes'][k] = specs[j][k];
          }
        }
        this.changedSpecs['new-item-' + i] = specs[j]
        this.newSpecs.unshift(specs[j]);
        i++;
      }

      this.fillHeadersIfEmpty();

      // this.tablespecs.renderRows();
      // this.hasData = true;
      this.getData();
    });
  }

  public deltaToHtml(delta) {
    return XslxDataParser.deltaToHtml(delta, this.sanitized);
  }

  public getValidationFieldText(field, element) {
    if(this.validationFields && this.validationFields['element'] ) {
      let result = this.initFields(element[field].richText, field === 'dimensions', ['max_weight', 'max_duration'].indexOf(field)>=0, field)
      element[field].text = XslxDataParser.deltaToHtml(result);
      element[field].richText = result;

    }


    return element[field].text;
  }

  initFields(richText, isDimensionDialog, withNumberField, selectField) {
    let elements = []
    for(let v of this.validationFields['element']) {
      elements.push(v.text)
    }
    let fieldValues = []
    for(let v of this.validationFields[selectField]) {
      fieldValues.push(v.text)
    }
    let ratios = []
    for(let v of this.validationFields['ratio']) {
      ratios.push(v.text)
    }
    let out = []
    let lines = []
    for(let rt of richText) {
      let spt = rt.insert.split('\n')
      for (let row of spt) {
        if(row.trim() !=='') {
          lines.push({insert: row.trim()});
        }
      }
    }
    for(let rt of lines) {
      if(rt.insert.trim() !== '') {
        let value = {input1: {text: ''}, input2: '', select: {text: ''}, ratio: {text: ''}};

        if(rt.insert.indexOf(':')>=0) {
          let spt = rt.insert.split(/:(.+)/)
          value.input1 = {text: spt[0].trim()};
          if(spt.length > 1)
            value.select = {text: spt[1].trim()};
          if(withNumberField || (spt.length > 1 && spt[1].trim().toLowerCase().indexOf('caractères max') > 0 && isDimensionDialog)) {

            let regexExp = /([0-9]+)\s*([a-zA-Z è]+)/
            let result = regexExp.exec(spt[1].trim());
            let spt2 = []
            if(result)
              spt2 = [result[1], result[2]]

            if(spt2.length > 1 &&  !isNaN(parseInt(spt2[0].trim()))) {
              value.input2 = spt2[0].trim();
              value.select = {text: spt2[1].trim()};
            }
          }
          if( isDimensionDialog && (spt[0].trim().toLowerCase() === 'video' || spt[0].trim().toLowerCase().indexOf('image') >= 0 || spt[0].trim().toLowerCase().indexOf('bannière') >= 0)) {
            let spt2 = spt[1].split('|')
            value.select = {text: spt2[0].trim()};
            if( spt2.length > 1) {
              value.ratio = {text: spt2[1].trim()};
              if(ratios.indexOf(value.ratio.text)<0) {
                continue
              }
            }
          }
        }

        if(elements.indexOf(value.input1.text)<0) {
          continue
        }
        if(selectField === 'file_type')
        {
          let split = value.select.text.split(',')
          let valid = true;
          for(let v of split) {
            if(fieldValues.indexOf(v.trim())<0) {
              valid = false;
              continue;
            }
          }

          if(!valid) {
            continue;
          }
        }
        else {
          if(fieldValues.indexOf(value.select.text)<0) {
            continue
          }
        }
        if(!rt.insert.endsWith('\n'))
          rt.insert+='\n'
        out.push(rt)
      }
    }
    return out
  }

  public getExistingSpec(spec) {
    for(let index in this.data.data) {
      let existingSpec = this.data.data[index];
      let isExists = true;
      for(let reqColumn of this.requiredColumns) {
        if(this.getPlainText(existingSpec[reqColumn]).trim() !== this.getPlainText(spec[reqColumn]).trim()) {
          isExists = false;
        }
      }

      if(isExists) {
        return index;
      }
    }
    return -1;
  }

  public getData() {
    this.loading = true;


    let offset = this.paginator.pageIndex * this.paginator.pageSize;
    let limit = this.paginator.pageSize;
    let sort = undefined;
    let filter = this.filter.get('filter').value;

    if (this.sort.active && this.sort.direction) {
      sort = {prop: this.sort.active, dir: this.sort.direction};
    }
    else {
      sort = { prop: '_id', dir: -1};
    }

    let changedSpecs = null;
    let newSpecLimit = limit;
    if(offset < Object.values(this.changedSpecs).length) {
      limit = (limit + offset) - Object.values(this.changedSpecs).slice(offset, limit).length;


    }
    let requestOffset = offset - Object.values(this.changedSpecs).length;
    if(requestOffset < 0) {
      requestOffset = 0;
    }



    if (this.showOnlySelected && Object.keys(this.changedSpecs).length > 0) {
      changedSpecs = [].concat(Object.keys(this.changedSpecs));
      offset = 0;
      this.paginator.pageIndex = 0;
    }
    if(limit < 0) {
      limit = 0;
    }
    this.specificationService.specLibraryList(requestOffset, limit, filter, sort, changedSpecs).subscribe(e => {
      this.data.filter = undefined;
      this.data.sort = undefined;
      this.data.paginator = undefined;
      this.columnsDisplay = this.columns;
      let newData = e.data.map(el => {
        if(!el.history)
          return
        let history = el.history[el.history.length - 1].values;

        for(let header of this.columns) {
          if (header !== 'actions' && header !== 'user' && header !== 'alerte' && header !== 'updatedAt') {
            el[header] = {
              text: XslxDataParser.deltaToHtml(history[header].richText, this.sanitized),
              richText: history[header].richText,
              plainText: el[header]
            };
          }
        }
        if (this.changedSpecs[el._id]) {
          el.changes = this.changedSpecs[el._id].changes;
        }
        return el;
      });
      let cleanedData = [];
      for(let d of newData) {
        if(!this.changedSpecs[d._id]) {
          cleanedData.push(d);
        }
      }
      let newSpecs = []
      //let newSpecs = Object.values(this.changedSpecs).filter((value) => { return value['_id'].indexOf('new-item')>=0 })
      let changed = Object.values(this.changedSpecs)
      let changedLength = Object.values(this.changedSpecs).length
      if(offset < changed.length) {
        changed = changed.slice(offset, offset + newSpecLimit)
      }
      else {
        changed = [];
      }
      changed = changed.sort((a,b) => {

        if ( a['_id'] < b['_id'] ){
          return 1;
        }
        if ( a['_id'] > ['_id'] ){
          return -1;
        }
        return 0;
      });

      let values = changed.concat(cleanedData).sort()

      this.data.data = values.slice(0, newSpecLimit);
      this.hasData = (this.data.data.length > 0) ?  true : false;
      this.paginator.length = e.count + changedLength;



      // for(var s of this.newSpecs) {
      //   this.data.data.unshift(s);
      // }

      if (this.showOnlySelected) {
        this.data.data = this.data.data.filter((value) => {
          return value.changes && Object.keys(value.changes).length > 0;
        });
      }
      this.tablespecs.renderRows();

      this.loading = false;
    });
  }

  public getPlainText(element, key=null) {
    let obj = element;

    if (key) {
      if ( obj.changes && obj.changes[key]) {
        obj = obj.changes[key];
      }
      else {
        obj = element[key];
      }
    }
    if(obj && obj.text) {

      let plainText = XslxDataParser.revertescapeHtml(obj.text).replace(/<[^>]*>/g, '');

      return plainText;
    }
    return '';
  }

  public getHtmlContent(element, key=null) {
    let obj = element;

    if (key) {
      if ( obj.changes && obj.changes[key]) {
        obj = obj.changes[key];
      }
      else {
        obj = element[key];
      }
    }
    if(obj && obj.text) {


      return obj.text;
    }
    return '';
  }

  public onBorderCheck(event, element, index) {

    this.dataChecked[index] = event.checked;

    let value = 'non';
    if (event.checked) {
      value = 'oui';
    }
    // element.text = '<span style="font-size: 11px;color: #000000;">' + value + '</span>';
    // element.richText[0].insert = value


    this.change({
      text: '<span style="font-size: 11px;color: #000000;">' + value + '</span>',
      richText: [{insert : value}],
      plainText: value
    },  element, 'border', false);
  }

  public isChecked(index) {
    return (index in this.dataChecked) ? this.dataChecked[index] : false;
  }

  public adservingChange(event, element) {

    // element.text = '<span style="font-size: 11px;color: #000000;">' + event.value + '</span>';
    // element.richText[0].insert = event.value;

    this.change({
      text: '<span style="font-size: 11px;color: #000000;">' + event.value + '</span>',
      richText: [{insert : event.value}],
      plainText: event.value
    },  element, 'adserving', false);
  }

  public partnerChange(event, element, header) {
    this.change({
      text: '<span style="font-size: 11px;color: #000000;">' + 'Hébergement partenaire - ' + event.text + '</span>',
      richText: [{insert : 'Hébergement partenaire - ' + event.text}],
      plainText: 'Hébergement partenaire - ' + event.text
    },  element, 'adserving', false);
    this.loadValidationFields();
  }

  public creationDateChange(event, element) {
    let value = '';
    if (event.option) {
      value = 'MEL J-' + event.option.value;
    }
    if (event.target) {
      value = 'MEL J-' + event.target.value;
    }

    if ( this.getPlainText(element['creation_date']).toUpperCase().indexOf('MEL J-')<0) {
      element['creation_date'] = {
        text: '<span style="font-size: 11px;color: #000000;">MEL J-5</span>',
        richText: [{insert : 'MEL J-5'}],
        plainText: 'MEL J-5'
      };
    }

    this.change({
      text: '<span style="font-size: 11px;color: #000000;">' + value + '</span>',
      richText: [{insert : value}],
      plainText: value
    },  element, 'creation_date', false);
  }

  getValidationFieldById(id, fieldname) {
    for(let field of this.validationFields[fieldname]) {
      if(field._id === id)
        return field;
    }
    return null;
  }

  initValidationField(element, fieldname) {
    if(element[fieldname].id_field) {
      return this.getValidationFieldById(element[fieldname].id_field, fieldname);
    }
    let self = this;


    return {text: this.getPlainText(element[fieldname]).trim()};
  }

  ngOnInit() {

  }

  public isGlobalEditable(col: any, element: any): boolean {
    return (this.isEditableFirst(col, element) || !this.isEditableFirst(col, element)) || this.isEditableSecond(col, element);
  }

  public isEditableFirst(col: any, element: any): boolean {
    if(element['changes'] && element['changes'][col] && element['changes'][col].text != '') {
      return true;
    }
    return !this.cellIsEmpty(element[col]);
  }

  public isEditableSecond(col: any, element: any): boolean {
    if(element['changes'] && element['changes'][col] && element['changes'][col].text != '') {
      return true;
    }
    return !element[col]['editMode'];
  }
}
