<div class="layout-column layout-align-center-stretch">

    <div mat-dialog-title class="layout-column layout-align-space-between-stretch block-title">
        <div class="layout-row layout-align-space-between-center">
            <h5>Confirm delete</h5>
            <button mat-icon-button [mat-dialog-close]="false">
                <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
            </button>
        </div>

        <div class="line"></div>
    </div>

    <div mat-dialog-content>
        <p>Are you sure to delete this ?</p>
    </div>

    <div mat-dialog-actions class="layout-row layout-align-space-between-stretch">
        <button mat-stroked-button color="warn" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true">Delete</button>
    </div>
</div>