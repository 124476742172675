import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SpecificationService, SpecPlan} from '../../services/specification.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {SpecPlanDeleteComponent} from '../spec-plan-list/spec-plan-delete/spec-plan-delete.component';
import {merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: './draft-list.component.html',
  styleUrls: ['./draft-list.component.scss']
})
export class DraftListComponent implements OnInit, AfterViewInit {
  public filterForm: FormGroup;
  public hasData = false;
  public tableIsLoading = false;

  public tableColumns: string[] = ['name', 'user.fullname', 'creation.date', 'actions'];
  public drafts: MatTableDataSource<SpecPlan> = new MatTableDataSource<SpecPlan>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  constructor(private specificationService: SpecificationService, private router: Router, private dialog: MatDialog) {
    this.filterForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngOnInit() {
    //this.getData();
  }

  ngAfterViewInit () {

    this.sort.sortChange.subscribe(
      () => this.paginator.pageIndex = 0
    );

    merge(this.filterForm.get('filter').valueChanges, this.sort.sortChange, this.paginator.page).pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(e => {
      this.getData();
    });
  }

  getData() {
    let offset = this.paginator.pageIndex * this.paginator.pageSize;
    let limit = this.paginator.pageSize;
    let sort = undefined;
    let filter = this.filterForm.get('filter').value;



    if (this.sort.active && this.sort.direction) {
      if(this.sort.active !='creation.date')
        sort = {sort_column: this.sort.active, sort_direction: this.sort.direction};
      else
        sort = {sort_column: '_id', sort_direction: this.sort.direction};
    }

    this.specificationService.draftList(offset, limit, filter, sort).subscribe(response => {

      if (response.drafts) {
        this.drafts.data = response.drafts;
        this.hasData = (this.drafts.data.length > 0) ?  true : false;
        this.paginator.length = response.count;
        this.tableIsLoading = false;
      }
    });
  }

  monngoIdToDate(id) {
    let timestamp = id.substring(0, 8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return date.toLocaleString();
  }

  createSpecPlan() {
    this.router.navigate(['/specs']);
  }

  duplicateDraft(element) {

    this.specificationService.draftDuplicate(element.id).subscribe(response =>{
      if (response.status && response.status === 'success') {
        this.getData();
      }
    });
  }

  loadDraft(id) {
    this.router.navigate(['/specs'], {queryParams: {draft: id}});
  }

  deleteDraft(id) {
    this.dialog.open(SpecPlanDeleteComponent).afterClosed().subscribe(result => {
      if (result) {
        this.specificationService.draftDelete(id).subscribe(response => {
          if (response.status && response.status === 'success') {
            this.getData();
          }
        });
      }
    });
  }
}
