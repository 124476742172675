<div [ngStyle]="{width:'200px'}" *ngIf="!addMode">
  <div  [ngStyle]="{width:'100px', display:'inline-block'}">
    <mat-form-field  *ngIf="!isMultiselect">
      <mat-label>Choose {{fieldName}}</mat-label>
      <input type="text"
             matInput
             [formControl]="fieldControl"
             (change)="onChange($event)"
             (blur)="onFocusOut($event)"
             [matAutocomplete]="auto" [readonly]="readonly">
      <mat-autocomplete #auto [displayWith]="displayFn">
        <mat-option *ngFor="let field of filteredOptions | async"
                    [ngStyle]="{backgroundColor: field.validate === true?'#CBFBC4':'#EDAEAE'}"
                    (onSelectionChange)="onChange($event)"

                    [value]="field">{{field.text}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <ng-container *ngIf="isMultiselect">
      <div style="display: flex; flex-direction: row">
        <mat-form-field  appearance="fill" *ngIf="isNumericField()" style="width: 40px">
          <input matInput [formControl]="numericField"  [min]="0"
                 onkeydown="return event.key !== 'e' && event.key !== 'E'"
                 pattern="[0-9-]*" />
        </mat-form-field>

        <app-select-with-search style="width: 120px" [formControl]="chipValue" [values]="fieldList" ></app-select-with-search>
        <!--      <mat-autocomplete #auto="matAutocomplete" [formControl]="chipValue" (optionSelected)="selected($event)" [displayWith]="displayFn">-->
        <!--        <mat-option *ngFor="let field of filteredOptions | async"-->
        <!--                    [ngStyle]="{backgroundColor: field.validate === true?'#CBFBC4':'#EDAEAE'}"-->
        <!--                    [value]="field">{{field.text}}</mat-option>-->
        <!--      </mat-autocomplete>-->
        <button type="button"  style="width: 20px;height: 20px" color="accent" (click)="addChip()"
                mat-icon-button matTooltip="Validate" [disabled]="(chipValue.value == '' || !chipValue.value.text)|| (numericField.value == '' && isNumericField())">
          <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-check-circle"></mat-icon>
        </button>
      </div>
    <mat-form-field appearance="fill">

      <mat-chip-list #chipList aria-label="Value selection">
        <mat-chip
          *ngFor="let value of multiselectValues; let i = index"
          [selectable]="!readonly"
          [removable]="!readonly"
          (removed)="remove(i)">
          {{value}}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>

    </ng-container>

  </div>
  <div>
    <button type="button" color="accent" (click)="switchAddMode()" mat-icon-button matTooltip="New Item" [disabled]="readonly">
      <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-plus-circle"></mat-icon>
    </button>
  </div>
</div>



<div *ngIf="addMode">
  <div>
  <mat-form-field>
    <mat-label>New field value</mat-label>
    <input matInput type="text" [(ngModel)]="newValue"/>
  </mat-form-field>
</div>
  <div>
    <button type="button" color="accent" (click)="addElement()" mat-icon-button matTooltip="Validate">
      <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-check-circle"></mat-icon>
    </button>
    <button type="button" color="accent" (click)="fieldControl.setValue('');addMode = false" mat-icon-button matTooltip="Cancel">
      <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-undo"></mat-icon>
    </button>
  </div>

</div>


