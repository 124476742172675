import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {SpecificationService} from '../../../services/specification.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-source-upload',
  templateUrl: './source-upload.component.html',
  styleUrls: ['./source-upload.component.scss']
})
export class SourceUploadComponent implements OnInit{
  public formData: FormGroup;
  public existingSourceForm: FormGroup;
  public replaceSourceForm: FormGroup;
  public sourceOption = 'new_source';
  public sourceList = [];

  public filteredSources: Observable<any>;
  public filteredSourcesReplace: Observable<any>;

  public baseUri: string;
  constructor(public dialog: MatDialogRef<SourceUploadComponent>, private specificationService: SpecificationService) { }

  ngOnInit(): void {
    this.formData = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required, this.ValidatorFileNotExists.bind(this)])
    });

    this.replaceSourceForm = new FormGroup({
      sourceSelect: new FormControl(null, [Validators.required]),
      sourceSearch: new FormControl(null, []),
      name: new FormControl(null, [Validators.required, this.ValidatorFileToReplaceNotExists.bind(this)]),
      file: new FormControl(null, [Validators.required])
    });

    this.existingSourceForm = new FormGroup({
      sourceSelect: new FormControl(null, [Validators.required]),
      sourceSearch: new FormControl(null, [])
    });

    this.specificationService.listSources().subscribe(response => {
      if(response.sources) {
        let existingNames = [];

        this.sourceList = [];
        for ( let source of response.sources ) {
          if ( existingNames.indexOf(source.name) < 0) {
            this.sourceList.push(source);
            existingNames.push(source.name);
          }
        }

        this.baseUri = response.base_uri;

        this.filteredSources = this.existingSourceForm.get('sourceSearch').valueChanges.pipe(
          startWith(''), map(name => typeof name === 'string' ? this._filterSources(name) : this.sourceList.slice())
        );

        this.filteredSourcesReplace = this.replaceSourceForm.get('sourceSearch').valueChanges.pipe(
          startWith(''), map(name => typeof name === 'string' ? this._filterSources(name) : this.sourceList.slice())
        );
      }
    });
  }

  public replaceSelectChange(event) {
    this.replaceSourceForm.get('name').setValue(event.name);
  }

  public ValidatorFileNotExists(control: AbstractControl) {
    let exists = false;
    this.sourceList.forEach(source => {
      if (control.value === source.name) {
        exists = true;
        return;
      }
    });
    if (exists) {
      return {exists: true};
    }
    else {
      return null;
    }
  }

  public ValidatorFileToReplaceNotExists(control: AbstractControl) {
    if (!this.replaceSourceForm)
      return {exists: true};
    let exists = false;
    this.sourceList.forEach(source => {
      if (control.value === source.name) {
        exists = true;
        return;
      }
    });
    if (exists && control.value !== this.replaceSourceForm.get("sourceSelect").value.name) {
      return {exists: true};
    }
    else {
      return null;
    }
  }

  private _filterSources(name): any[] {
    const filterValue = name.toLowerCase();
    return this.sourceList.filter(source => source.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public replaceSource() {
    if (this.replaceSourceForm.valid) {

      this.specificationService.replaceSource(
        this.replaceSourceForm.get('sourceSelect').value.uuid,
        this.replaceSourceForm.get('file').value['content'].split(',')[1],
        this.replaceSourceForm.get('file').value['name'],
        this.replaceSourceForm.get('name').value
      ).subscribe((response) => {
        if (response.status && response.status === 'success') {
          let delta = {
            attributes: {
              underline: true,
              size: '11px',
              color: 'black',
              italic: true,
              bold: false
            },
            insert: response.url
          }
          let urlText = `<span style="font-size: 11px;color: black;font-style: italic;text-decoration: underline;">${response.url}</span>`
          this.dialog.close({urlText: urlText, urlDelta: [delta]});
        }
      });
    }
  }


  public upload(): void {
    this.specificationService.uploadSource(
      this.formData.get('file').value['content'].split(',')[1],
      this.formData.get('file').value['name'],
      this.formData.get('name').value
    ).subscribe((response) => {
      if (response.status && response.status === 'success') {
        let delta = {
          attributes: {
            underline: true,
            size: '11px',
            color: 'black',
            italic: true,
            bold: false
          },
          insert: response.url
        }
        let urlText = `<span style="font-size: 11px;color: black;font-style: italic;text-decoration: underline;">${response.url}</span>`
        this.dialog.close({urlText: urlText, urlDelta: [delta]});
      }
    });
  }

  public onFileChange(event) {
    this.formData.get('name').setValue(this.formData.get('file').value['name']);
  }

  public onReplaceFileChange(event) {
    this.replaceSourceForm.get('name').setValue(this.replaceSourceForm.get('file').value['name']);
  }

  public useExistingSource() {
    if (this.existingSourceForm.valid) {
      let source = this.existingSourceForm.get('sourceSelect').value;
      let delta = {
        attributes: {
          underline: true,
          size: '11px',
          color: 'black',
          italic: true,
          bold: false
        },
        insert: `${this.baseUri}?uuid=${source.uuid}`
      }
      let urlText = `<span style="font-size: 11px;color: black;font-style: italic;text-decoration: underline;">${this.baseUri}?uuid=${source.uuid}</span>`
      this.dialog.close({urlText: urlText, urlDelta: [delta]});
    }
  }
  public close(): void {
    this.dialog.close();
  }

}
