import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public authenticated = false;
  public errors: string;
  public loading = false;

  constructor(public loginService: LoginService, private router: Router, ) { }

  ngOnInit() {

  }

  async signIn() {
    const token: Observable<any> = await this.loginService.signIn();
    this.loading = true;
    token.subscribe(tok => {

      setTimeout(() => {
        this.loading = false;
      }, 500);

      if (tok.token) {
        this.router.navigate(['/spec-plans']);
      } else {
        this.errors = tok.errors.replace(new RegExp(/"/, 'g'), '').split('|')[1];
      }
    });
  }

}
