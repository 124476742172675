<mat-sidenav-container>
  <mat-sidenav-content>
    <app-loader *ngIf="loading"></app-loader>
    <mat-tab-group #tab class="invisible-tabs" dynamicHeight>
      <mat-tab>
        <section>

          <div id="header" style="margin-bottom: 20px;">
            <div>
              <h1>Specifications</h1>
              <span *ngIf="filename" class="sub-title">You are actually looking : {{filename}}</span>

              <div id="breadcrumb">
                <ul>
                  <li><a [routerLink]="['/spec-plans']">Specification Plans</a></li>
                  <li>/</li>
                  <li><span>Create Specification Plan</span></li>
                </ul>
              </div>
            </div>

            <div class="pre-actions">
<!--              <button mat-raised-button (click)="toggle('import')">-->
<!--                <mat-icon class="mdi mdi-24px mdi-progress-upload"></mat-icon>-->
<!--                <div class="btn-text">Import File</div>-->
<!--              </button>-->
              <button mat-raised-button (click)="toggle('export')" [style.display]="!rowsHasError() && specsList.data.length > 0? 'block' : 'none'">
                <mat-icon class="mdi mdi-24px mdi-progress-download"></mat-icon>
                <div class="btn-text">Export</div>
              </button>
<!--              <button mat-raised-button (click)="saveInLibrary()" [style.display]="!rowsHasError() && specsList.data.length > 0 ? 'block' : 'none'">-->
<!--                <mat-icon class="mdi mdi-24px mdi-content-save"></mat-icon>-->
<!--                <div class="btn-text">Save in library</div>-->
<!--              </button>-->
              <button mat-raised-button (click)="saveForLater()" [style.display]="specsList.data.length === 0 ? 'none' : 'block'">
                <mat-icon class="mdi mdi-24px mdi-content-save"></mat-icon>
                <div class="btn-text">Save for later</div>
              </button>

              <div class="post-actions">
<!--                <button mat-raised-button color="primary" (click)="createRow()">-->
<!--                  <mat-icon class="mdi mdi-24px mdi-table-row-plus-after"></mat-icon>-->
<!--                  <div class="btn-text">Create row</div>-->
<!--                </button>-->
                <button mat-raised-button color="primary" (click)="openLibrarySpecDialog()">
                  <mat-icon class="mdi mdi-24px mdi-table-row-height"></mat-icon>
                  <div class="btn-text">Add row(s) from library</div>
                </button>

                <button *ngIf="selection.selected.length > 0" mat-raised-button color="warn" (click)="delete()">
                  <mat-icon matBadge="{{selection.selected.length}}" matBadgePosition="before" matBadgeColor="warn" class="mdi mdi-24px mdi-delete"></mat-icon>
                  <div class="btn-text">Delete</div>
                </button>
              </div>
            </div>
          </div>

          <mat-accordion class="app-nav-accordion" *ngIf="infos">
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>Spec Plan Informations</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-card style ="margin-bottom: 10px">
                <mat-card-content>
                  <mat-list>
                    <mat-list-item><span style="width:200px">Advertiser :</span><span style="width:200px">{{infos.advertiser}}</span></mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item><span style="width:200px">Campaign :</span><span style="width:200px">{{infos.campaign}}</span></mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item><span style="width:200px">Start date :</span><span style="width:200px">{{infos.startDate}}</span></mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item><span style="width:200px">Delivery date :</span><span style="width:200px">{{infos.deliveryDate}}</span></mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item><span style="width:200px">Contacts :</span><span style="width:200px">{{infos.contacts}}</span></mat-list-item>
                  </mat-list>
                </mat-card-content>
              </mat-card>
            </mat-expansion-panel>
          </mat-accordion>

          <div id="block-table-specs" class="block-table-specs-class">

        <span class="no-data" *ngIf="!hasData">
          There is no data to display
          <br />
          You can <a (click)="openLibrarySpecDialog()">import data from the library</a><br />
        </span>

            <div class="div-table" id="block-table-specification" [ngStyle]="{'display': hasData ? 'block' : 'none'}">
              <table mat-table #tablespecs [dataSource]="specsList">

                <ng-container [matColumnDef]="col" *ngFor="let col of headers; let i=index;">

                  <ng-container *ngIf="col === 'checking'">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>

                    <td mat-cell *matCellDef="let row;let rowIndex=index;">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(rowIndex) : null"
                                    [checked]="selection.isSelected(rowIndex)">
                      </mat-checkbox>
                    </td>
                  </ng-container>

                  <ng-container *ngIf="col !== 'checking'">
                    <th mat-header-cell *matHeaderCellDef>
                      <div *ngIf="headersCorrespondances[col] === 'border'">Bordure 1x1 noire</div>
                      <div *ngIf="headersCorrespondances[col] === 'format'">{{col}}</div>
                      <div *ngIf="headersCorrespondances[col] !== 'format' && headersCorrespondances[col] !== 'border'">{{col}}</div>
                    </th>

                    <td mat-cell
                        *matCellDef="let element; let rowIndex=index"
                        [ngClass]="{'editable-cell': ['Commentaires', 'Durée max', 'Date de réception des créas'].indexOf(col)>=0 && isGlobalEditable(col, element),'unvalidate': (element[col] && element[col].validate!==null && element[col].validate === false)}"
                        (click)="['Commentaires', 'Durée max', 'Date de réception des créas'].indexOf(col)>=0 ?redirectClick(col, element, rowIndex): null"
                    >
                      <mat-icon *ngIf="['Commentaires', 'Durée max', 'Date de réception des créas'].indexOf(col)>=0 && isGlobalEditable(col, element)" class="mdi mdi-18px mdi-pencil"></mat-icon>
                      <ng-container *ngIf="col === 'alerte'">
                        <!-- <span *ngIf="!validateSpec(element) || specHasChanges(rowIndex)"
                          matTooltip="{{getAlertMessage(element, rowIndex)}}" [matTooltipPosition]="'after'"
                          matTooltipClass="alert-tooltip">
                          <mat-icon color="warn" class="mdi mdi-18px mdi-alert-circle"></mat-icon>
                        </span> -->
                        <div class="block-message-alert">
                          <span class="message-alert" *ngFor="let messageAlert of getAlertMessage(element, rowIndex)">{{messageAlert}}</span>
                        </div>
                      </ng-container>

                      <div [ngClass]="{'editable-text': ['Commentaires', 'Durée max', 'Date de réception des créas'].indexOf(col)>=0}" *ngIf="isEditableFirst(col, element)">
                        <div [innerHTML]="element[col].text| safeHtml"></div>
                      </div>

                      <div [ngClass]="{'editable-text': ['Commentaires', 'Durée max', 'Date de réception des créas'].indexOf(col)>=0}" *ngIf="isEditableSecond(col, element)">
                        <div [innerHTML]="getValidationFieldText(col, element)| safeHtml"></div>
                      </div>

                      <div *ngIf="isEditableThird(col, element)" [ngStyle]="{width:'335px'}">
                        <div [ngStyle]="{width:'315px'}" *ngFor="let row of element[col].values;let indexOfelement=index;">
                          <div  [ngStyle]="{width:'100px', display:'inline-block'}">
                            <mat-form-field>
                              <input matInput [value]="initSpecialFieldInput(element[col], indexOfelement)" (change)="changeSpecialFieldInput($event, element[col], indexOfelement)" type="text">
                            </mat-form-field>
                          </div>
                          <div [ngStyle]="{width:'10px', display:'inline-block'}">:</div>
                          <div  [ngStyle]="{width:'100px', display:'inline-block'}">
                            <mat-form-field>
                              <mat-label>Choose an option</mat-label>
                              <mat-select [compareWith]="compareObjects" [value]=" initSpecialFieldSelect(element[col], indexOfelement)" (selectionChange)="changeSpecialFieldSelect($event, element[col], indexOfelement)">
                                <mat-option *ngFor="let field of validationFields[headersCorrespondances[col]]"  [ngStyle]="{backgroundColor: field.validate === true?'#CBFBC4':'#EDAEAE'}" [value]="field">{{field.text}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div  [ngStyle]="{width:'80px', display:'inline-block'}">
                            <button type="button" color="accent" (click)="addValueForValidation(headersCorrespondances[col], element[col])" mat-icon-button matTooltip="New Item">
                              <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-plus-circle"></mat-icon>
                            </button>
                            <button type="button" color="accent" (click)="removeLine(element[col], indexOfelement)" mat-icon-button matTooltip="New Item">
                              <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-minus-circle"></mat-icon>
                            </button>
                          </div>
                        </div>

                        <button type="button" color="accent" (click)="addLine(element[col])" mat-icon-button matTooltip="New Item">
                          <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-plus-circle"></mat-icon><span>Add Line</span>
                        </button>
                      </div>


                      <div *ngIf="isEditableFourth(col, element)" [ngStyle]="{width:'335px'}">
                        <div [ngStyle]="{width:'315px'}" *ngFor="let row of element[col].values;let indexOfelement=index;">
                          <div  [ngStyle]="{width:'100px', display:'inline-block'}">
                            <mat-form-field>
                              <input matInput [value]="initSpecialFieldInputBis(element[col], indexOfelement)" (change)="changeSpecialFieldInputBis($event, element[col], indexOfelement)" type="text">
                            </mat-form-field>
                          </div>
                          <div [ngStyle]="{width:'10px', display:'inline-block'}">:</div>
                          <div  [ngStyle]="{width:'50px', display:'inline-block'}">
                            <mat-form-field>
                              <input matInput [value]="initSpecialFieldInputBis(element[col], indexOfelement, 2)" (change)="changeSpecialFieldInputBis($event, element[col], indexOfelement, 2)" type="number">

                            </mat-form-field>
                          </div>
                          <div  [ngStyle]="{width:'50px', display:'inline-block'}">
                            <mat-form-field>
                              <mat-label>Choose an option</mat-label>
                              <mat-select [compareWith]="compareObjects" [value]=" initSpecialFieldSelectBis(element[col], indexOfelement)" (selectionChange)="changeSpecialFieldSelectBis($event, element[col], indexOfelement)">
                                <mat-option *ngFor="let field of validationFields[headersCorrespondances[col]]"  [ngStyle]="{backgroundColor: field.validate === true?'#CBFBC4':'#EDAEAE'}" [value]="field">{{field.text}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div  [ngStyle]="{width:'80px', display:'inline-block'}">
                            <button type="button" color="accent" (click)="addValueForValidation(headersCorrespondances[col], element[col])" mat-icon-button matTooltip="New Item">
                              <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-plus-circle"></mat-icon>
                            </button>
                            <button type="button" color="accent" (click)="removeLine(element[col], indexOfelement)" mat-icon-button matTooltip="New Item">
                              <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-minus-circle"></mat-icon>
                            </button>
                          </div>
                        </div>

                        <button type="button" color="accent" (click)="addLineBis(element[col])" mat-icon-button matTooltip="New Item">
                          <mat-icon aria-label="newItem" class="mdi mdi-24px mdi-plus-circle"></mat-icon><span>Add Line</span>
                        </button>
                      </div>

<!--                      <div [ngClass]="{'editable-icon': ['Commentaires', 'Durée max', 'Date de réception des créas'].indexOf(col)>=0}" *ngIf="col !== 'actions' && col !='alerte' && cellIsEmpty(element[col]) && headersCorrespondances[col] != 'adserving' && headersCorrespondances[col] != 'border' && headersCorrespondances[col] != 'creation_date'  && (!validationFields ||(validationFields && !validationFields[headersCorrespondances[col]])) && specialFields.indexOf(headersCorrespondances[col])<0"-->
<!--                           (click)="['Commentaires', 'Durée max', 'Date de réception des créas'].indexOf(col)>=0?openEditDialog(element[col], rowIndex, element[col]?.header):null">-->
<!--                        <mat-icon class="mdi mdi-24px mdi-playlist-edit"></mat-icon>-->
<!--                      </div>-->
                      <div *ngIf="headersCorrespondances[col] == 'border'">
                        <mat-slide-toggle
                          checked="{{ (getPlainText(element[col]).toLowerCase() === 'non' || getPlainText(element[col]).toLowerCase() === '-') ? false : true }}"
                          (change)="onBorderCheck($event, element[col], rowIndex)" [disabled]="true">
                          {{ getPlainText(element[col]).toLowerCase() === 'non' || getPlainText(element[col]).toLowerCase() === '-'? 'OFF' : 'ON' }}
                        </mat-slide-toggle>
                      </div>

                      <div *ngIf="validationFields && validationFields[headersCorrespondances[col]]&& specialFields.indexOf(headersCorrespondances[col])<0" layout="row" layout-align="center center">
                        <validation-field-select
                          [value]="{text: getPlainText(element[col]).trim()}"
                          [fieldList]="validationFields[headersCorrespondances[col]]"
                          [fieldName]="headersCorrespondances[col]"
                          (fieldSelect)="validationFieldChange($event, element[col])" [readOnly]="true"></validation-field-select>
                      </div>

                      <div *ngIf="headersCorrespondances[col] == 'adserving'"  [ngStyle]="{width:'250px'}">
                        <mat-form-field>
                          <mat-label>Choose an advertiser</mat-label>
                          <mat-select value="{{getPlainText(element[col]).toLowerCase().startsWith('hébergement partenaire -')?'Hébergement partenaire':getPlainText(element[col])}}" (selectionChange)="adservingChange($event, element[col])" [disabled]="true">
                            <mat-option *ngFor="let adserving of adservingOptions" value="{{adserving}}">{{adserving}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div *ngIf="getPlainText(element[col]).startsWith('Hébergement partenaire') && validationFields && validationFields['publisher']">
                          <validation-field-select [value]="{text: getPlainText(element[col]).replace('Hébergement partenaire -','').replace('Hébergement partenaire','').trim()}"
                          [fieldList]="validationFields['publisher']"
                          [fieldName]="'publisher'"
                          (fieldSelect)="partnerChange($event, element[col])" [readOnly]="true"></validation-field-select>
                          </div>
                      </div>

                      <div *ngIf="headersCorrespondances[col] == 'creation_date'">
                        <mat-form-field>
                          <span matPrefix>MEL J-</span>
                          <input matInput type="number" min="0"  (change)="creationDateChange($event, element[col])" [matAutocomplete]="auto" value="{{(getPlainText(element[col]).toUpperCase().indexOf('MEL J-') >= 0)? getPlainText(element[col]).toUpperCase().replace('MEL J-', ''): '5'}}">
                        </mat-form-field>
                        <mat-autocomplete #auto (optionSelected)="creationDateChange($event, element[col])">
                          <mat-option *ngFor="let item of [5,7]; let i = index" value="{{item}}">MEL J-{{item}}</mat-option>
                        </mat-autocomplete>
                      </div>

                      <ng-container *ngIf="col === 'actions'">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
                          <mat-icon class="mdi mdi-18px mdi-dots-vertical"></mat-icon>
                        </button>

                        <mat-menu #menu="matMenu">
<!--                          <button mat-menu-item (click)="openSourceUploadDialog(element['Sources'])">-->
<!--                            <mat-icon class="mdi mdi-18px mdi-cloud-upload"></mat-icon>-->
<!--                            <div class="btn-text">Upload source</div>-->
<!--                          </button>-->
<!--                          <button mat-menu-item (click)="openSourceUploadDialog(element['Templates / Démos'])">-->
<!--                            <mat-icon class="mdi mdi-18px mdi-cloud-upload-outline"></mat-icon>-->
<!--                            <div class="btn-text">Upload template/démos</div>-->
<!--                          </button>-->
<!--                          <button mat-menu-item [disabled]="!specHasChanges(rowIndex)" (click)="merge(rowIndex)">-->
<!--                            <mat-icon class="mdi mdi-18px mdi-call-merge"></mat-icon>-->
<!--                            <div class="btn-text">Merge</div>-->
<!--                          </button>-->
<!--                          <button mat-menu-item (click)="duplicate(element, rowIndex)">-->
<!--                            <mat-icon class="mdi mdi-18px mdi-content-duplicate"></mat-icon>-->
<!--                            <div class="btn-text">Duplicate</div>-->
<!--                          </button>-->
<!--                          <button mat-menu-item (click)="saveOneInlibrary(element, rowIndex)">-->
<!--                            <mat-icon class="mdi mdi-18px mdi-content-save"></mat-icon>-->
<!--                            <div class="btn-text">Save in library</div>-->
<!--                          </button>-->
                          <button mat-menu-item (click)="deleteOne(rowIndex)">
                            <mat-icon class="mdi mdi-18px mdi-delete"></mat-icon>
                            <div class="btn-text">Delete</div>
                          </button>
                        </mat-menu>
                      </ng-container>
                    </td>
                  </ng-container>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="headers;sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: headers;"></tr>
              </table>
            </div>
          </div>

        </section>
      </mat-tab>
      <mat-tab>
        <app-library-list #libManager (manageSpecs)="closeLibrary()"></app-library-list>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav-content>

  <mat-sidenav #sidenav mode="over" position="end" opened="{{sidenavOpened}}"
               [style.overflow]="loading ? 'hidden' : 'auto'">

    <app-spec-export *ngIf="sidenavselected==='export'" [infos]="infos" [_id]="_id" [draftId]="draftId" [specsList]="specsList"
                     [headersCorrespondances]="headersCorrespondances" [sidenav]="sidenav" (spec-export)="setSpecChange($event)">
    </app-spec-export>
    <app-spec-import *ngIf="sidenavselected==='import'" [sidenav]="sidenav"
                     [headersCorrespondances]="headersCorrespondances" (spec-import)="setSpecList($event)">
    </app-spec-import>
  </mat-sidenav>
</mat-sidenav-container>
