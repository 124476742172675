import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SpecificationService} from '../../../services/specification.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XslxDataParser} from '../../../utils/xslx-data-parser';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-library-import',
  templateUrl: './library-import.component.html',
  styleUrls: ['./library-import.component.scss']
})
export class LibraryImportComponent implements OnInit {

  public form: FormGroup;
  public datas: any;
  public dataParser: XslxDataParser;
  public specPlanIsValid = true;
  public maxFileSize = 4;
  public headersCorrespondances: any;

  constructor(public dialog: MatDialogRef<LibraryImportComponent>, @Inject(MAT_DIALOG_DATA) public  data: any, private specificationService: SpecificationService,
  private sanitized: DomSanitizer
  
  ) {
    this.headersCorrespondances = data;
  }

  ngOnInit() {
    this.form = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      });
  }

  public import() {
    this.specificationService.import({
      filename: this.form.get('file').value['name'],
      content: this.form.get('file').value['content'].split(',')[1]

    }).subscribe(result => {
      if (result.status && result.status === 'success') {
        this.datas = result.data;
        this.dataParser = new XslxDataParser(this.datas, this.headersCorrespondances, this.sanitized);
        this.dataParser.parse();

        const line = [];
        for (let i = 0; i < this.dataParser.specs.length; i++) {
          const row = this.dataParser.specs[i];
          line[i] = {};

          for (let j = 0; j < row.length; j++) {
            if (this.headersCorrespondances[row[j].header] === 'creation_date' && row[j].richText[0].insert.toUpperCase().indexOf('MEL J-') < 0) {
              line[i][this.headersCorrespondances[row[j].header]] = { text: '<span style="font-size: 11px;color: #000000;">MEL J-7</span>', richText: [{insert: 'MEL J-7'}], header: row[j].header };

            }
            else {
              if (this.headersCorrespondances[row[j].header] === 'border' && row[j].richText[0].insert.toUpperCase().trim() !== 'OUI') {
                line[i][this.headersCorrespondances[row[j].header]] = { text: '<span style="font-size: 11px;color: #000000;">Non</span>', richText: [{insert: 'Non'}], header: row[j].header };
              }
              else {
                line[i][this.headersCorrespondances[row[j].header]] = { text: row[j].text, richText: row[j].richText, header: row[j].header };
              }
            }

          }
        }

        this.dialog.close({specs:line})
      }
    });
  }

}
