import { SpecMergeComponent } from './components/specs/spec-merge/spec-merge.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { LoginComponent } from './components/login/login.component';
import { LoginService } from './services/login.service';
import { MsalModule } from '@azure/msal-angular';
import { OAuthSetting } from './utils/auth';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { MaterialModule } from './core/material.module'
import { AuthGuardService } from './services/auth-guard.service';
import { SpecPlanListComponent } from './components/spec-plan-list/spec-plan-list.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpecificationService } from './services/specification.service';
import { SpecsComponent } from './components/specs/specs.component';
import { SafeHtmlPipe } from './utils/safehtml';
import { SpecEditComponent } from './components/specs/spec-edit/spec-edit.component';
import { QuillModule } from 'ngx-quill';
import { SpecDeleteComponent } from './components/specs/spec-delete/spec-delete.component';
import { ThemeService } from './services/theme.service';
import { LibrarySpecComponent } from './components/library-spec/library-spec.component';
import { SourceUploadComponent } from './components/specs/source-upload/source-upload.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UsersComponent } from './components/users/users.component';
import { UsersService } from './services/users.service';
import { UserAddComponent } from './components/users/user-add/user-add.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SpecImportComponent } from './components/specs/spec-import/spec-import.component';
import { SpecExportComponent } from './components/specs/spec-export/spec-export.component';
import { UserDeleteComponent } from './components/users/user-delete/user-delete.component';
import { ConfirmSaveComponent } from './components/library-spec/confirm-save/confirm-save.component';
import { LibraryListComponent } from './components/library-spec/library-list/library-list.component';
import { SpecSaveComponent } from './components/specs/spec-save/spec-save.component';
import { SpecPlanDeleteComponent } from './components/spec-plan-list/spec-plan-delete/spec-plan-delete.component';
import {CanDeactivateGuard} from './services/can-deactivate.guard';
import { DraftSaveComponent } from './components/specs/draft-save/draft-save.component';
import { DraftListComponent } from './components/draft-list/draft-list.component';
import { TutoComponent } from './components/tuto/tuto.component';
import { TutoEditComponent } from './components/tuto/tuto-edit/tuto-edit.component';
import { LibraryImportComponent } from './components/library-spec/library-import/library-import.component';
import {HelpComponent} from './components/help/help.component';

import {PdfViewerModule} from "ng2-pdf-viewer";
import {LibraryMergeComponent} from './components/library-spec/library-merge/library-merge.component';
import { MatFileUploadModule } from './utils/mat-file-upload/mat-file-upload.component';
import { SourcesComponent } from './components/sources/sources.component';
import { ReplaceComponent } from './components/sources/replace/replace.component';
import { AddComponent } from './components/sources/add/add.component';
import { RenameComponent } from './components/sources/rename/rename.component';
import { AdminComponent } from './components/admin/admin.component';
import {ValidationFieldsService} from "./services/validation_fields.service";
import { FieldViewComponent } from './components/field-view/field-view.component';
import { ConfirmRejectComponent } from './components/field-view/confirm-reject/confirm-reject.component';
import {AdminGuardService} from "./services/admin-guard.service";
import {UnauthorizedComponent} from "./components/unauthorized/unauthorized.component";
import { ValidationFieldComponent } from './utils/validation-field/validation-field.component';
import { ValidationFieldEditComponent } from './utils/validation-field-edit/validation-field-edit.component';
import { FieldImportComponent } from './components/admin/field-import/field-import.component';
import {SelectWithSearchComponent} from "./utils/select-with-search/select-with-search.component";
import {MatOptionFilterComponent} from "./utils/select-with-search/mat-option-filter/mat-option-filter.component";
import {InputFocusDirective} from "./utils/autofocus.directive";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SpecPlanListComponent,
    HeaderComponent,
    SpecsComponent,
    SafeHtmlPipe,
    SpecEditComponent,
    SpecDeleteComponent,
    SpecMergeComponent,
    LibrarySpecComponent,
    SpecMergeComponent,
    SourceUploadComponent,
    UsersComponent,
    UserAddComponent,
    UserDeleteComponent,
    LoaderComponent,
    SpecImportComponent,
    SpecExportComponent,
    ConfirmSaveComponent,
    LibraryListComponent,
    SpecSaveComponent,
    SpecPlanDeleteComponent,
    DraftSaveComponent,
    DraftListComponent,
    TutoComponent,
    TutoEditComponent,
    HelpComponent,
    LibraryImportComponent,
    LibraryMergeComponent,
    SourcesComponent,
    ReplaceComponent,
    AddComponent,
    RenameComponent,
    AdminComponent,
    FieldViewComponent,
    ConfirmRejectComponent,
    UnauthorizedComponent,
    ValidationFieldComponent,
    ValidationFieldEditComponent,
    FieldImportComponent,
    SelectWithSearchComponent,
    MatOptionFilterComponent,
    InputFocusDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    QuillModule.forRoot(),
    MatFileUploadModule,
    FileUploadModule,
    PdfViewerModule,
    NgxMatSelectSearchModule,
    MsalModule.forRoot({auth:{clientId : OAuthSetting.appId, redirectUri : OAuthSetting.redirectUri}

    })
  ],
  providers: [
    ThemeService,
    LoginService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AuthGuardService,
    AdminGuardService,
    CanDeactivateGuard,
    SpecificationService,
    UsersService,
    ValidationFieldsService
  ]
  ,
  entryComponents: [
    SpecEditComponent,
    SpecDeleteComponent,
    SpecMergeComponent,
    SourceUploadComponent,
    LibrarySpecComponent,
    UserDeleteComponent,
    ConfirmSaveComponent,
    SpecSaveComponent,
    SpecPlanDeleteComponent,
    DraftSaveComponent,
    TutoEditComponent,
    LibraryImportComponent,
    LibraryMergeComponent,
    ReplaceComponent,
    AddComponent,
    RenameComponent,
    ConfirmRejectComponent,
    ValidationFieldEditComponent,
    FieldImportComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
