import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SpecificationService} from "../../../services/specification.service";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-replace',
  templateUrl: './replace.component.html',
  styleUrls: ['./replace.component.scss']
})
export class ReplaceComponent implements OnInit {
  public replaceSourceForm: FormGroup;
  public sourceList = [];

  public source: any;

  public filteredSourcesReplace: Observable<any>;

  constructor(public dialog: MatDialogRef<ReplaceComponent>,
              private specificationService: SpecificationService,
              @Inject(MAT_DIALOG_DATA) public  data: any) {
    this.source = data;
    console.log(this.source);
  }

  ngOnInit(): void {
    this.replaceSourceForm = new FormGroup({
     // filename: new FormControl(this.source.name, [Validators.required]),
      name: new FormControl(this.source.name, [Validators.required]),
      file: new FormControl(null, [Validators.required])
    });

    this.specificationService.listSources().subscribe(response => {
      if(response.sources) {
        let existingNames = [];

        this.sourceList = [];
        for ( let source of response.sources ) {
          if ( existingNames.indexOf(source.name) < 0) {
            this.sourceList.push(source);
            existingNames.push(source.name);
          }
        }

        // this.filteredSourcesReplace = this.replaceSourceForm.get('sourceSearch').valueChanges.pipe(
        //   startWith(''), map(name => typeof name === 'string' ? this._filterSources(name) : this.sourceList.slice())
        // );
      }
    });

  }

  public replaceSelectChange(event) {
    this.replaceSourceForm.get('name').setValue(event.name);
  }

  public onReplaceFileChange(event) {
    //this.replaceSourceForm.get('name').setValue(this.replaceSourceForm.get('file').value['name']);
  }

  public ValidatorFileToReplaceNotExists(control: AbstractControl) {
    if (!this.replaceSourceForm)
      return {exists: true};
    let exists = false;
    this.sourceList.forEach(source => {
      if (control.value === source.name) {
        exists = true;
        return;
      }
    });
    if (exists && control.value !== this.replaceSourceForm.get("name").value) {
      return {exists: true};
    }
    else {
      return null;
    }
  }

  private _filterSources(name): any[] {
    const filterValue = name.toLowerCase();
    return this.sourceList.filter(source => source.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public replaceSource() {
    if (this.replaceSourceForm.valid) {

      this.specificationService.replaceSource(
        this.source.uuid,
        this.replaceSourceForm.get('file').value['content'].split(',')[1],
        this.replaceSourceForm.get('file').value['name'],
        this.replaceSourceForm.get('name').value
      ).subscribe((response) => {
        if (response.status && response.status === 'success') {
          this.dialog.close({success: true});
        }
      });
    }
  }
}
