import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TutoEditComponent} from './tuto-edit/tuto-edit.component';
import {SpecificationService} from '../../services/specification.service';

@Component({
  templateUrl: './tuto.component.html',
  styleUrls: ['./tuto.component.scss']
})
export class TutoComponent implements OnInit {
  tuto = [
    ];

  constructor(private dialog: MatDialog, private specificationService: SpecificationService) { }

  editHelp() {
    this.dialog.open(TutoEditComponent).afterClosed().subscribe(result => {
      if(result) {
        this.specificationService.tutoInsert(result.tuto).subscribe(response => {
          if (response.status && response.status === 'success') {
            this.getTuto();
          }
        });
      }
    });
  }

  ngOnInit() {
    this.getTuto();
  }

  getTuto() {
    this.specificationService.tutoList().subscribe(response => {
      if (response.status && response.status === 'success') {
        if (response.tuto.length > 0) {
          this.tuto = response.tuto[response.tuto.length - 1].tuto;
        }
      }
    });
  }
}
