import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import {XslxDataParser} from '../../../utils/xslx-data-parser';

@Component({
  selector: 'app-spec-merge',
  templateUrl: './spec-merge.component.html',
  styleUrls: ['./spec-merge.component.scss']
})
export class SpecMergeComponent{
  allChecked = true;
  oldSpec: any;
  newSpec: any;
  changedHeaders: any;
  headerCorrespondances: any;
  results = {};
  quillConfig= {
    toolbar: []
  }

  selected = {}

  constructor(public dialog: MatDialogRef<SpecMergeComponent>, @Inject(MAT_DIALOG_DATA) public  data: any, private sanitized:DomSanitizer) {

    this.headerCorrespondances = data.headersCorrespondances;
    this.oldSpec = data.oldSpec;
    this.newSpec = Object.values(data.newSpec);
    this.changedHeaders = data.changedHeaders;
    for (var key in this.headerCorrespondances) {
      var headerField = this.headerCorrespondances[key];
      this.results[headerField] = {};
      if(this.changedHeaders.indexOf(headerField) >= 0) {
        this.results[headerField] = this.oldSpec[headerField].richText;
        this.selected[headerField] = true;
      }
    }
  }

  public deltaToHtml(delta) {
    return XslxDataParser.deltaToHtml(delta, this.sanitized);
  }

  public discardChanges() {
    let merged = [];
    for(var key in this.headerCorrespondances) {

      let headerField = this.headerCorrespondances[key];
      merged.push({
        header: key,
        richText: this.oldSpec[headerField].richText
      });
    }
    this.dialog.close({ merged });
  }

  public merge() {

    let specs = {}

    for(let s of this.newSpec) {
      let headerField = this.headerCorrespondances[s.header];
      specs[headerField] = s;
    }
    let merged = [];
    for(var key in this.headerCorrespondances) {

      let headerField = this.headerCorrespondances[key];
      if(!this.selected[headerField]) {
        merged.push({
          header: key,
          richText: this.oldSpec[headerField].richText
        });
      }
      else {
        merged.push({
          header: key,
          richText: specs[headerField].richText
        });
      }
    }
    this.dialog.close({ merged});
  }

  public selectAll(event) {

    for (let k in this.selected) {
      this.selected[k] = event.checked;
    }
  }

  public selectionChange(event) {
    let allselected = true;
    for(let k in this.selected) {
      if(this.selected[k] == false) {
        allselected = false;
        break;
      }
    }

    this.allChecked = allselected;
  }

  public hasSelected() {
    for(let k in this.selected) {
      if(this.selected[k] == true)
      {
        return true;
      }
    }
    return false;
  }

  public close() {
    this.dialog.close();
  }
}
