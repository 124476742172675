import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-spec-plan-delete',
  templateUrl: './spec-plan-delete.component.html',
  styleUrls: ['./spec-plan-delete.component.scss']
})
export class SpecPlanDeleteComponent  {

  constructor (public dialog: MatDialogRef<SpecPlanDeleteComponent>) {}

  public delete() {
    this.dialog.close("ok");
  }

  public close() {
    this.dialog.close(null);
  }
}
