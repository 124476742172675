<div class="layout-column layout-align-center-stretch">

  <div mat-dialog-title class="layout-column layout-align-space-between-stretch block-title">
    <div class="layout-row layout-align-space-between-center">
      <h5>Confirm save</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>

  <div mat-dialog-content>
    <p>Are you sure to save this ?</p>
  </div>
  <div  class="layout-column" [formGroup]="draftFormGroup">
    <mat-form-field>
      <input matInput placeholder="Draft name" name="name" type="text" formControlName="name">
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="layout-row layout-align-space-between-stretch">
    <button mat-stroked-button color="warn" [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{name: this.draftFormGroup.get('name').value, duplicate:true}" [disabled]="!draftFormGroup.valid" *ngIf="data.id">Duplicate</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{name: this.draftFormGroup.get('name').value}" [disabled]="!draftFormGroup.valid">{{(data.id)? 'Update':'Save'}}</button>
  </div>
</div>
