import { SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

export class XslxDataParser {

  data: any;
  headers = [];
  specs = [];
  infos = {};
  sanitized:DomSanitizer;

  constructor(data: any, requiredHeaders: any,   sanitized: DomSanitizer) {
    this.data = data;
    this.headers = Object.keys(requiredHeaders);
    this.sanitized= sanitized;
  }

  public static deltaToHtml(delta,   sanitized?: DomSanitizer) {
    let richText = '<p>';
    if(!delta) {
      return '<p></p>'
    }
    if (delta.ops) {
      delta = delta.ops;
    }
    for (let i in delta) {
      if (!delta[i].insert) {
        delta[i]['insert'] = '';
      }
      if (!delta[i].attributes) {
        delta[i]['attributes'] = {};
      }
      let open = `<span style="${XslxDataParser.attributesToStyle(delta[i].attributes)}">`;
      richText += `${open}${XslxDataParser.escapeHtml(delta[i].insert).replace(/\n/g, '</span></p><p>' + open)}</span>`;
    }
    richText += "</p>"

    return richText;
  }

  static escapeHtml(unsafe) {
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;")
         .replace(/ /g, '&nbsp;');
 }

 static revertescapeHtml(unsafe) {
  return unsafe
       .replace(/"&amp;/g, "&")
       .replace(/&lt;/g, "<")
       .replace(/&gt;/g, ">")
       .replace(/&quot;/g, '"')
       .replace(/&#039;/g, "'")
       .replace(/&nbsp;/g, ' ');
}


  public parse(infosRequired=false) {
    let msgIndex = -1;
    let row = 0;

    if(infosRequired) {
      while (this.data[row].length < 2 || this.data[row][1].text.indexOf('advertiser') < 0) {
        row ++;
        if (row === 20) {
          throw Error('Invalid template file');
        }
      }
      this.infos['advertiser'] = this.data[row][2].text;

      while (this.data[row].length < 2 || this.data[row][1].text.indexOf('campaign') < 0) {
        row ++;
        if (row === 20) {
          throw Error('Invalid template file');
        }
      }
      this.infos['campaign'] = this.data[row][2].text;

      while (this.data[row].length < 2 || this.data[row][1].text.indexOf('start date') < 0) {
        row ++;
        if (row === 20) {
          throw Error('Invalid template file');
        }
      }
      this.infos['startDate'] = this.data[row][2].text;

      while (this.data[row].length < 2 || this.data[row][1].text.indexOf('delivery date') < 0) {
        row ++;
        if (row === 20) {
          throw Error('Invalid template file');
        }
      }
      this.infos['deliveryDate'] = this.data[row][2].text;

      while (this.data[row].length < 2 || this.data[row][1].text.indexOf('contacts') < 0) {
        row ++;
        if (row === 20) {
          throw Error('Invalid template file');
        }
      }
      this.infos['contacts'] = this.data[row][2].text;
    }

    while (this.data[row].length < 2 || this.headers.indexOf(this.data[row][1].text) < 0) {
      row ++;
      if (row === 20) {
        throw Error('Invalid template file');
      }
    }

    let fileHeaders = [];
    for (let i = 0; i < this.data[row].length; i++) {
      fileHeaders.push(this.data[row][i].text.replace(/\*/g, '').trim());
    }

    row ++;

    for (let i = row; i < this.data.length; i++) {
      let spec = [];

      for (let j = 0; j < this.data[i].length; j++) {
          if (this.headers.indexOf(fileHeaders[j]) >= 0) {
            let richText = XslxDataParser.deltaToHtml(this.data[i][j].richText, this.sanitized);

            spec.push({richText: this.data[i][j].richText ,  text: richText, header: fileHeaders[j]});
          }
      }
      if (spec.length !== this.headers.length) {
        console.log(spec.length,this.headers.length)
        throw Error('Invalid template file');
      }
      this.specs.push(spec);
    }

  }

  public static attributesToStyle(attributes): string {
    let style = '';
    const sizes = {
      huge: '2.5em',
      large: '1.5em',
      normal: '1em',
      small: '0.75em'
    }
    for(var key in attributes) {
        if(key == "size") {
          if(sizes[attributes.size])
            style += `font-size: ${sizes[attributes.size]};`;
          else {
            style += `font-size: ${attributes.size};`;
          }
        }

        if(key == "color")
          style += `color: ${attributes.color};`;
        if(key == "italic" && attributes.italic)
          style += "font-style: italic;";
        if(key == "bold" && attributes.bold)
          style += "font-weight: bold;";
        if(key == "underline" && attributes.underline)
          style += "text-decoration: underline;";
    }
    return style;
  }
}
