<div class="edit-dialog">

  <div mat-dialog-title>
    <div class="layout-row layout-align-space-between-center">
      <h5>Edit Specification</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>

  <div mat-dialog-content>
    <!-- <ng-container class="layout-column"> -->
      <p>{{data.header}}</p>
      <quill-editor [modules]="quillConfig" placeholder="Enter Text" format="object" [formControl]="form">
      </quill-editor>
    <!-- </ng-container> -->
  </div>

  <div mat-dialog-actions class="layout-row layout-align-space-between-stretch">
    <button mat-stroked-button color="warn" [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
  </div>
</div>
