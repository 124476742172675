import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ValidationFieldsService} from "../../services/validation_fields.service";
import {XslxDataParser} from "../xslx-data-parser";
import {map} from "rxjs-compat/operator/map";

@Component({
  selector: 'app-validation-field-edit',
  templateUrl: './validation-field-edit.component.html',
  styleUrls: ['./validation-field-edit.component.scss']
})
export class ValidationFieldEditComponent implements OnInit {

  public elements = []


  public withNumberField = false;

  public fieldName: any;

  public fieldList = [];
  public elementList = [];
  public ratioList = [];
  public isDimensionDialog = false;
  public isMultiselect = false;

  constructor(public dialog: MatDialogRef<ValidationFieldEditComponent>, private validationFieldsService: ValidationFieldsService , @Inject(MAT_DIALOG_DATA) public  data: any) {

  }

  ngOnInit(): void {
    this.withNumberField = this.data.withNumberField;

    this.fieldList = this.data.fieldList;
    this.elementList = this.data.elements;
    this.ratioList = this.data.ratioList;
    this.fieldName = this.data.fieldName;
    if(this.data.isMultiselect)
      this.isMultiselect = true;
    if(this.data.isDimensionDialog)
      this.isDimensionDialog = true;
    this.initFields(this.data.richText);
    this.loadValidationFields();

  }


  changeField($event, row, inputName) {
    this.loadValidationFields();
    row[inputName] = $event.target.value;
  }

  changeSelect($event, row, inputName='select') {
    this.loadValidationFields();
    row[inputName] = $event;

  }

  addRow() {
    this.elements.push({element:{text: ''}, values: [{input2: '', select: {text: ''}, ratio:{text: ''}}]});
  }

  addValue(row) {
    row.push({input2: '', select: {text: ''}, ratio:{text: ''}})
  }

  removeValue(values, indexOfValue) {
    values.splice(indexOfValue, 1);
  }


  initFields(richText) {
    let lines = []
    for(let rt of richText) {
      let spt = rt.insert.split('\n')
      for (let row of spt) {
        if(row.trim() !=='') {
          lines.push({insert: row.trim()});
        }
      }
    }
    for(let rt of lines) {
      let ok = false;
      if(rt.insert.trim() !== '') {
        let values = [];
        let elementName = {};
        if(rt.insert.indexOf(':')>=0) {
          ok = true;
          let spt = rt.insert.split(/:(.+)/)
          if(spt.length < 2) {
            ok = false;
            continue
          }


          for (let val of spt[1].split(',')) {
            let value = { input2: '', select: {text: ''}, ratio: {text: ''}};
            elementName = {text: spt[0].trim()};
            if(spt.length > 1 && val)
              value.select = {text: val.trim()};
            if(this.withNumberField || (val && (val.trim().toLowerCase().indexOf('caractères max') > 0 ||
                val.trim().toLowerCase().indexOf('mots') > 0) &&
              this.isDimensionDialog && val.trim() !== '-')) {

              let regexExp = /([0-9-]+)\s*([a-zA-Z è]+)/
              let result = regexExp.exec(val.trim());
              let spt2 = [];
              if(result)
                spt2 = [result[1], result[2]]

              if(spt2.length > 1 &&  !isNaN(parseInt(spt2[0].trim()))) {
                value.input2 = spt2[0].trim();
                value.select = {text: spt2[1].trim()};
              }
            }
            if( this.isDimensionDialog && (spt[0].trim().toLowerCase() === 'video' || spt[0].trim().toLowerCase().indexOf('image') >= 0 || spt[0].trim().toLowerCase().indexOf('bannière') >= 0)) {
              let spt2 = val.split('|')
              value.select = {text: spt2[0].trim()};
              if( spt2.length > 1) {
                value.ratio = {text: spt2[1].trim()};
              }
            }
            values.push(value)
          }



        }
        if(ok)
          this.elements.push({element: elementName, values: values});
      }
    }
  }

  removeLine(indexOfelement) {
    this.elements.splice(indexOfelement, 1);
  }

  public loadValidationFields() {
    this.validationFieldsService.list_mine().subscribe(result => {
      this.fieldList = result.elements[this.fieldName];
      let validationFields = result.elements;

      this.elementList = validationFields['element'];
      this.ratioList = validationFields['ratio'];
    })
  }

  isValid() {
    for(let el of this.elements) {
      for(let v of el.values) {
        let value = (v.input2 + ' ' + v.select.text).trim();

        if(value === '' || !el.element  || !el.element.text || el.element.text.trim() === ''|| !v.select || !v.select.text || v.select.text.trim() === '') {
          return false;
        }

        if(this.isDimensionDialog && (el.element.text.toLowerCase() === 'video' || el.element.text.toLowerCase().indexOf('image') >= 0 || el.element.text.toLowerCase().indexOf('bannière') >= 0)) {
          let existing = this.ratioList.filter(element => element.text.toLowerCase().trim() === v.ratio.text.toLowerCase().trim())
          if(existing.length === 0) {
            return false
          }
        }
        if(this.withNumberField || ((v.select.text.trim().toLowerCase().indexOf('caractères max') >= 0 ||
          v.select.text.trim().toLowerCase().indexOf('mots') >= 0) && this.isDimensionDialog)) {
          const regex = new RegExp('^[0-9]+$');
          const regexRange = new RegExp('^[0-9]+-[0-9]+$');
          if(!regex.test(v.input2.trim()) && !regexRange.test(v.input2.trim())) {
            if(v.select.text.trim() !== '-') {
              return false;
            }
          }
          if(v.input2.trim() === '' && v.select.text.trim() !== '-') {
            return false;
          }
        }
      }
    }
    return true;
  }

  save() {
    let richText = []
    for(let el of this.elements) {
      let values = []
      for(let v of el.values) {
        let value = (v.input2 + ' ' + v.select.text).trim();
        if(v.select.text === '-') {
          value = '-'
        }
        if(this.isDimensionDialog && (el.element.text.toLowerCase() === 'video' || el.element.text.toLowerCase().indexOf('image') >= 0 || el.element.text.toLowerCase().indexOf('bannière') >= 0))
          value += ' | ' + v.ratio.text.toLowerCase();

        v.select = v.select.text;
        values.push(value)
      }
      richText.push({insert: el.element.text + ' : ' + values.join(',')});
      richText.push({insert: '\n'});
    }

    let html = XslxDataParser.deltaToHtml(richText);

    this.dialog.close({richText: richText, text: html, values: this.elements})
  }
}
