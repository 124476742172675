<ng-template #library>
  <section>
    <div id="header">
      <div>
        <h1>Specification Library</h1>
        <span class="sub-title">List of all your specifications available</span>

        <div id="breadcrumb">
          <ul>
            <li><a [routerLink]="['/spec-plans']">Specifications Plans</a></li>
            <li *ngIf="isTab">/</li>
            <li *ngIf="isTab"><a (click)="manageSpecs()">Create Specification Plan</a></li>
            <li>/</li>
            <li><span>List of specifications</span></li>
          </ul>
        </div>
      </div>

      <div class="actions">
        <button mat-raised-button color="primary" (click)="createRow()">
          <mat-icon class="mdi mdi-24px mdi-table-row-plus-after"></mat-icon>
          <div class="btn-text">Create row</div>
        </button>
        <button mat-raised-button color="primary" (click)="import()" >
          <mat-icon class="mdi mdi-24px mdi-progress-upload"></mat-icon>
          <div class="btn-text">Import</div>
        </button>
        <button mat-raised-button color="primary" (click)="save()" [style.display]="(rowsHasChanges() && !rowsHasError())?'block':'none'">
          <mat-icon class="mdi mdi-24px mdi-check"></mat-icon>
          <div class="btn-text">Save</div>
        </button>
      </div>
    </div>


    <div id="block-table-specs">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <form [formGroup]="filter">
        <mat-form-field>
          <input matInput placeholder="Filter" type="text" formControlName="filter" />
        </mat-form-field>
      </form>
      <span class="no-data" *ngIf="!hasData">
      There is no data to display<br />
      Let's <a (click)="manageSpecs()">create a specification plan</a>
    </span>


      <div [ngStyle]="{'display': hasData ? 'block' : 'none'}">
        <div class="toggle-action">
          <mat-slide-toggle (change)="setShowOnlySelected()">Show updated rows</mat-slide-toggle>
        </div>



        <div class="div-table" id="block-table-library">

          <table #tablespecs mat-table [dataSource]="data" matSort>
            <ng-container matColumnDef="noresult">
              <th mat-header-cell *matHeaderCellDef>No Result</th>
              <td mat-cell *matCellDef="let element"></td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell style="width: 80px !important" *matCellDef="let element; let rowIndex=index">
                <ng-container>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
                    <mat-icon class="mdi mdi-18px mdi-dots-vertical"></mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openSourceUploadDialog(element, 'sources')">
                      <mat-icon class="mdi mdi-18px mdi-cloud-upload"></mat-icon>
                      <div class="btn-text">Upload source</div>
                    </button>
                    <button mat-menu-item (click)="openSourceUploadDialog(element, 'templates')">
                      <mat-icon class="mdi mdi-18px mdi-cloud-upload-outline"></mat-icon>
                      <div class="btn-text">Upload template/démos</div>
                    </button>
                    <button mat-menu-item (click)="duplicate(element, rowIndex)">
                      <mat-icon class="mdi mdi-18px mdi-content-duplicate"></mat-icon>
                      <div class="btn-text">Duplicate</div>
                    </button>
<!--                    <button mat-menu-item (click)="merge(element)" [style.display]="(rowHasChanges(element)) && element['_id'].indexOf('new-item')<0?'':'none'">-->
<!--                      <mat-icon class="mdi mdi-18px mdi-content-save"></mat-icon>-->
<!--                      <div class="btn-text">Merge</div>-->
<!--                    </button>-->
                    <button mat-menu-item (click)="saveOne(element)" [style.display]="(element && this.getAlertMessage(element).length === 0)?'':'none'">
                      <mat-icon class="mdi mdi-18px mdi-content-save"></mat-icon>
                      <div class="btn-text">Save in library</div>
                    </button>
                    <button mat-menu-item (click)="delete(element)">
                      <mat-icon class="mdi mdi-18px mdi-delete"></mat-icon>
                      <div class="btn-text">Delete</div>
                    </button>
                  </mat-menu>
                </ng-container>
                <button mat-icon-button (click)="discardOne(element)" [style.display]="element && !element.isNewRow && rowHasChanges(element)?'block':'none'">
                  <mat-icon class="mdi mdi-18px mdi-undo"></mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="alerte">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" >
                <div class="block-message-alert">
                  <span class="message-alert" *ngFor="let messageAlert of getAlertMessage(element)">{{messageAlert}}</span>
                </div>
              </td>
            </ng-container>

            <ng-container *ngFor="let header of columns">
              <ng-container matColumnDef="{{header}}"  *ngIf="header != 'actions' && header != 'alerte' && header != 'updatedAt'&& header != 'user' && header != 'adserving'&& header != 'border'&& header != 'creation_date' && !validationFields[header]" >
                <th mat-header-cell *matHeaderCellDef  [ngClass]="{key: ['site', 'publisher', 'format'].includes(header)}" mat-sort-header>{{headersByKey[header]}}<div style="padding-left: 5px"  *ngIf="header === 'format' && (newSpecs.length >0 || getDictValues(changedSpecs).length > 0)"><span style="font-size:10px; font-style: italic">(Nom format | Dimensions | Device)</span></div></th>
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'editable-cell': isGlobalEditable(header, element)}"
                    (click)="openEditDialog(element, header)"
                >
                  <mat-icon *ngIf="isGlobalEditable(header, element)" class="mdi mdi-18px mdi-pencil"></mat-icon>
                  <div class="editable-text" *ngIf="isEditableFirst(header, element)">
                       <div [innerHTML]="((element.changes && element.changes[header])? element.changes[header].text : element[header].text) | safeHtml"></div>
                  </div>
                  <div class="editable-icon" *ngIf="!isEditableFirst(header, element)">
                    <mat-icon class="mdi mdi-18px mdi-playlist-edit"></mat-icon>
                  </div>
                </td>
              </ng-container>


              <ng-container matColumnDef="{{header}}"  *ngIf=" (validationFields && validationFields[header])&& specialFields.indexOf(header)>=0" >
                <th mat-header-cell  [ngClass]="{key: ['site', 'publisher', 'format'].includes(header)}" *matHeaderCellDef mat-sort-header>{{headersByKey[header]}}</th>
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'editable-cell': isGlobalEditable(header, element)}"
                    (click)="switchEditModeSpecialFields(element, header, header === 'max_weight' || header === 'max_duration' )"
                >
                  <mat-icon *ngIf="isGlobalEditable(header, element)" class="mdi mdi-18px mdi-pencil"></mat-icon>
                  <div class="editable-text" *ngIf="isEditableSecond(header, element)" [ngStyle]="{width:'285px'}">
                    <div [innerHTML]="((element.changes && element.changes[header])? element.changes[header].text : element[header].text) | safeHtml"></div>
                  </div>
                </td>
              </ng-container>



              <ng-container matColumnDef="{{header}}"  *ngIf="(validationFields && validationFields[header])&& specialFields.indexOf(header)<0" >
                <th mat-header-cell *matHeaderCellDef  [ngClass]="{key: ['site', 'publisher', 'format'].includes(header)}" mat-sort-header>{{headersByKey[header]}}</th>
                <td mat-cell *matCellDef="let element" >
                  <validation-field-select
                    [value]="{text: getPlainText(element, header).trim()}"
                    [fieldList]="validationFields[header]"
                    [fieldName]="header"
                    (fieldSelect)="validationFieldChange($event, element, header)"></validation-field-select>
                </td>
              </ng-container>

              <ng-container matColumnDef="{{header}}"  *ngIf=" header === 'adserving'" >
                <th mat-header-cell class="key" *matHeaderCellDef mat-sort-header>{{headersByKey[header]}}</th>
                <td mat-cell *matCellDef="let element">
                  <div style="width:200px">
                    <mat-form-field>
                      <mat-label>Choose an advertiser</mat-label>
                      <mat-select value="{{getPlainText(element, header).toLowerCase().startsWith('hébergement partenaire -')?'Hébergement partenaire':getPlainText(element, header)}}" (selectionChange)="adservingChange($event, element)">
                        <mat-option *ngFor="let adserving of adservingOptions" value="{{adserving}}">{{adserving}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div *ngIf="getPlainText(element, header).startsWith('Hébergement partenaire') && validationFields && validationFields['publisher']">
                      <validation-field-select [value]="{text: getPlainText(element, header).replace('Hébergement partenaire -','').replace('Hébergement partenaire','').trim()}"
                                               [fieldList]="validationFields['publisher']"
                                               [fieldName]="'publisher'"
                                               (fieldSelect)="partnerChange($event, element, header)"></validation-field-select>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="{{header}}"  *ngIf=" header === 'border'" >

                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{headersByKey[header]}}</th>
                <td mat-cell *matCellDef="let element; let rowIndex=index">
                  <div style="width:200px">
                    <mat-slide-toggle
                      checked="{{ (getPlainText(element,header).toLowerCase() == 'non') ? false : true }}"
                      (change)="onBorderCheck($event, element, rowIndex)">
                      {{ getPlainText(element[header]).toLowerCase() == 'non' || getPlainText(element[header]).toLowerCase() == '-'? 'OFF' : 'ON' }}
                    </mat-slide-toggle>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="{{header}}"  *ngIf=" header === 'creation_date'">

                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{headersByKey[header]}}</th>
                <td mat-cell *matCellDef="let element">
                  <div style="width:200px">
                    <mat-form-field>
                      <span matPrefix>MEL J-</span>
                      <input matInput  type="number" min="0" (change)="creationDateChange($event, element)" [matAutocomplete]="auto" value="{{(getPlainText(element, header).toUpperCase().indexOf('MEL J-') >= 0)? getPlainText(element, header).toUpperCase().replace('MEL J-', ''): '5'}}">
                    </mat-form-field>
                    <mat-autocomplete #auto (optionSelected)="creationDateChange($event, element)">
                      <mat-option *ngFor="let item of [5,7]; let i = index" value="{{item}}">MEL J-{{item}}</mat-option>
                    </mat-autocomplete>
                  </div>
                </td>
              </ng-container>
            </ng-container>
            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</th>
              <td mat-cell *matCellDef="let element">{{(element && element['user'])?element['user']:''}}</td>
            </ng-container>
            <ng-container matColumnDef="updatedAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated At</th>
              <td mat-cell *matCellDef="let element">{{(element && element['updatedAt'])?formatDate(element['updatedAt']):(element['_id'].indexOf('new-item')>=0)?'':created_at(element['_id'])}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="data.data.length == 0 ? ['noresult'] : columnsDisplay; sticky: true"></tr>
            <tr mat-row  [ngClass]="{'change-notify': rowHasChanges(row) }"  *matRowDef="let row; columns: (data.data.length == 0 ? ['noresult'] : columnsDisplay)"></tr>
          </table>
        </div>

        <mat-paginator [pageSizeOptions]="[5,10, 25]" pageSize="10"></mat-paginator>
      </div>
    </div>
  </section>
</ng-template>

<ng-container *ngIf="isTab" [ngTemplateOutlet]="library"></ng-container>
<mat-sidenav-container  *ngIf="!isTab">
  <mat-sidenav-content>

    <ng-container [ngTemplateOutlet]="library"></ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
