import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-spec-save',
  templateUrl: './spec-save.component.html',
  styleUrls: ['./spec-save.component.scss']
})

export class SpecSaveComponent {
  public merge = false;
  constructor (public dialog: MatDialogRef<SpecSaveComponent>, @Inject(MAT_DIALOG_DATA) public  data: any) {
    this.merge = data
  }

  public delete() {
    this.dialog.close('ok');
  }

  public close() {
    this.dialog.close(null);
  }
}
