import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog"
import {XslxDataParser} from "../../../utils/xslx-data-parser";
import {FormControl} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  templateUrl: './spec-edit.component.html',
  styleUrls: ['./spec-edit.component.scss']
})
export class SpecEditComponent implements OnInit {
  form: FormControl = new FormControl();
  quillConfig = {
    toolbar: [[{ 'size': [] }], ['bold', 'italic'], ['underline', { 'color': ["#000000","#e60000","#ff9900","#ffff00","#008a00","#0066cc","#9933ff","#ffffff","#facccc","#ffebcc","#ffffcc","#cce8cc","#cce0f5","#ebd6ff","#bbbbbb","#f06666","#ffc266","#ffff66","#66b966","#66a3e0","#c285ff","#888888","#a10000","#b26b00","#b2b200","#006100","#0047b2","#6b24b2","#444444","#5c0000","#663d00","#666600","#003700","#002966","#3d1466"] }, { align: [] }]],

  };

  constructor(public dialog: MatDialogRef<SpecEditComponent>, @Inject(MAT_DIALOG_DATA) public  data: any, private sanitized: DomSanitizer) {
  }

  ngOnInit(): void {
    this.form.setValue(JSON.parse(JSON.stringify(this.data.richText)));
  }

  save() {
    this.data.richText = this.form.value;
    if(this.data.richText.ops) {
      this.data.richText = this.data.richText.ops;
    }
    // this.data.richText.forEach(element => {
    //   element.insert =  element.insert;
    // });
    this.data.text = XslxDataParser.deltaToHtml(this.data.richText, this.sanitized);
    this.dialog.close(this.data);
  }

  close() {
    this.dialog.close();
  }
}
