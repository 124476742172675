import { Component } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import {SpecificationService} from '../../../services/specification.service';

@Component({
  selector: 'app-tuto-edit',
  templateUrl: './tuto-edit.component.html',
  styleUrls: ['./tuto-edit.component.scss']
})
export class TutoEditComponent {
  tuto: any;
  versions = [];
  constructor(public dialog: MatDialogRef<TutoEditComponent>, private specificationService: SpecificationService) {
    this.getTuto();
  }

  mongoIdToDate(id) {
    let timestamp = id.substring(0, 8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return date.toLocaleString();
  }

  getDefaultSelect() {
    if(this.versions.length === 0) {
      return null;
    }

    return this.mongoIdToDate(this.versions[this.versions.length - 1]._id);
  }

  selectionChange(event) {
    this.tuto = event.value.tuto;
  }

  getTuto() {
    this.specificationService.tutoList().subscribe(response => {
      if (response.status && response.status === 'success') {
        if (response.tuto.length > 0) {
          this.tuto = response.tuto[response.tuto.length - 1].tuto;
          this.versions = response.tuto;
        }
      }
    });
  }

  tutoHasChanges() {
    if ( this.versions.length === 0)
      return true;
    return JSON.stringify(this.tuto).trim() !== JSON.stringify(this.versions[this.versions.length - 1].tuto);
  }

  save() {
    this.dialog.close({tuto: this.tuto});
  }
}
