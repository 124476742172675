import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot } from '@angular/router';
import {SpecsComponent} from '../components/specs/specs.component';

@Injectable()

export class CanDeactivateGuard implements CanDeactivate<SpecsComponent> {
  canDeactivate(component: SpecsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot): Observable<boolean> | boolean {
    if (component.specsList.data.length === 0) {

      return true;
    }

    if (component.isExporting) {
      return true;
    }

    if(
      localStorage.getItem('saved-specs') &&
      JSON.stringify(JSON.parse(localStorage.getItem('saved-specs')).specsList) === JSON.stringify(component.specsList.data)
    ) {
      return true;
    }

    let confirmation = window.confirm("Do you want to leave without save changes?");

    return confirmation;
  }
}
