import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  pdfSrc = ''
  constructor() { }

  ngOnInit(): void {
    this.pdfSrc = window.location.protocol + '//' + window.location.host + "/assets/IPBridgeHelp.pdf"
  }
}
