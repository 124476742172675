import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {SpecificationService} from "../../services/specification.service";
import {MatDialog} from "@angular/material/dialog";
import {AddComponent} from "./add/add.component";
import {ReplaceComponent} from "./replace/replace.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FormControl, FormGroup} from "@angular/forms";
import { merge } from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import * as moment from "moment";
import {RenameComponent} from "./rename/rename.component";

@Component({
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit, AfterViewInit {

  public sourceData: MatTableDataSource<any> = new MatTableDataSource<any>();
  public tableColumns: string[] = ['creation_date', 'updatedAt', 'name', 'filename', 'associated_specs', 'download'];

  public filterForm: FormGroup;

  public tableIsLoading = false;
  public hasData = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(private specificationService: SpecificationService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngAfterViewInit(): void {
    this.filterForm.get('filter').valueChanges.subscribe(() => this.paginator.pageIndex = 0)
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0)
    merge(this.sort.sortChange, this.paginator.page, this.filterForm.get('filter').valueChanges).pipe(
      startWith(''),
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {
        this.loadData();
      }
    );
  }

  public download(source) {
    this.specificationService.downloadSource(source.uuid, source.filename);
  }

  public addSource() {
    this.dialog.open(AddComponent).afterClosed().subscribe(response => {
      if (response.success) {
        this.snackBar.open('Source add success !', null, { duration: 3000});
        this.loadData();
      }
    });
  }

  public replaceSource(element) {
    this.dialog.open(ReplaceComponent, {data: element}).afterClosed().subscribe(response => {
      if (response.success) {
        this.snackBar.open('Source replace success !', null, { duration: 3000});
        this.loadData();
      }
    });
  }

  public renameSource(element) {
    this.dialog.open(RenameComponent, {data: element}).afterClosed().subscribe(response => {
      if (response.success) {
        this.snackBar.open('Source rename success !', null, { duration: 3000});
        this.loadData();
      }
    });
  }

  createdAt(_id) {
    return moment(new Date(parseInt(_id.substring(0,8), 16)*1000)).format('YYYY-MM-DD');
  }

  public formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  private loadData() {
    this.tableIsLoading = true;

    const filter = this.filterForm.get('filter').value;

    let count = 10;
    let page = 0;
    if (this.paginator) {
      count = this.paginator.pageSize;
      page = this.paginator.pageIndex;
    }
    let sort_column = null;
    let sort_direction = null;

    if (this.sort && this.sort.active && this.sort.direction) {
      sort_column = this.sort.active;
      sort_direction = this.sort.direction;
    }
    this.specificationService.listSourcesPaginate(page + 1, count, filter, sort_column, sort_direction).subscribe(response => {
      if (response.sources) {

        this.sourceData.data = response.sources;

        if (this.sourceData.data.length > 0 ) {
          this.hasData = true;
        }
        else {
          this.hasData = false;
        }
        this.paginator.length = response.total;
        this.tableIsLoading = false;
      }
    });
  }



}
