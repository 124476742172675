<div class="layout-column layout-align-center-stretch">
  <div mat-dialog-title class="layout-column layout-align-space-between-stretch">
    <div class="layout-row layout-align-space-between-center">
      <h5>Library of specifications</h5>
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon class="mdi mdi-18px mdi-close"></mat-icon>
      </button>
    </div>

    <div class="line"></div>
  </div>

  <div mat-dialog-content>

    <div id="header">
      <div class="actions">
        <div class="toggle-action">
          <mat-slide-toggle (change)="showOnlySelected($event)">Show selected rows</mat-slide-toggle>
        </div>

        <button mat-raised-button color="primary" [style.display]="hasChanges?'block':'none'" (click)="save()">
          <mat-icon class="mdi mdi-18px mdi-save"></mat-icon>
          <div class="btn-text">Save changes</div>
        </button>
        <button mat-raised-button color="primary" (click)="close()" [disabled]="hasChanges">
          <mat-icon matBadge="{{selectedElement.length}}" matBadgePosition="before" matBadgeColor="primary" class="mdi mdi-18px mdi-import"></mat-icon>
          <div class="btn-text">Add selected rows to specification plan</div>
        </button>
      </div>
    </div>

    <div>
      <form [formGroup]="filter">
        <mat-form-field>
          <input matInput placeholder="Filter" type="text" formControlName="filter" />
        </mat-form-field>
      </form>
      <table mat-table [dataSource]="data" matSort>
        <ng-container matColumnDef="noresult">
          <th mat-header-cell *matHeaderCellDef>No Result</th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>
        <ng-container matColumnDef="selected">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.checked" (change)="selectItem($event, element)"></mat-checkbox>
          </td>
        </ng-container>
<!--        <ng-container matColumnDef="actions">-->
<!--          <th mat-header-cell *matHeaderCellDef></th>-->
<!--          <td mat-cell *matCellDef="let element">-->
<!--            <button mat-icon-button (click)="delete(element)">-->
<!--              <mat-icon class="mdi mdi-delete"></mat-icon>-->
<!--            </button>-->
<!--          </td>-->
<!--        </ng-container>-->
        <ng-container matColumnDef="adserving">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Adserving</th>
          <td mat-cell *matCellDef="let element">
            <div>{{(element.changes && element.changes['adserving'])?element.changes['adserving']:element['adserving']}}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="publisher">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Publisher</th>
          <td mat-cell *matCellDef="let element">
            <div>{{(element.changes && element.changes['publisher'])?element.changes['publisher']:element['publisher']}}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="site">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
          <td mat-cell *matCellDef="let element">
            <div>{{(element.changes && element.changes['site'])?element.changes['site']:element['site']}}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="format">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Format</th>
          <td mat-cell *matCellDef="let element">
            <div>{{(element.changes && element.changes['format'])?element.changes['format']:element['format']}}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</th>
          <td mat-cell *matCellDef="let element">{{element['user'].fullname}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="data.data.length == 0 ? ['noresult'] : columnsDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: (data.data.length == 0 ? ['noresult'] : columnsDisplay)"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5,10, 25, 50, 100]" pageSize="10"></mat-paginator>
    </div>

  </div>

</div>
