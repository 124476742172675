import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {User, UsersService} from '../../services/users.service';
import {Router} from '@angular/router';
import {merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import {UserDeleteComponent} from './user-delete/user-delete.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {

  public tableIsLoading = false;
  public filterForm: FormGroup;
  public tableColumns: string[] = ['fullname', 'email', 'creationDate', 'lastLogin', 'actions'];
  public users: MatTableDataSource<User> = new MatTableDataSource<User>();
  public hasData = false;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  @ViewChild('sidenavadduser')
  public sidenavAddUser: MatSidenav;

  constructor(private dialog: MatDialog, private usersService: UsersService, public snackBar: MatSnackBar, private router: Router) {
    this.filterForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initTableEvents();
  }

  private initTableEvents() {
    this.sort.sortChange.subscribe(
      () => this.paginator.pageIndex = 0
    );

    merge(this.sort.sortChange, this.paginator.page, this.filterForm.get('filter').valueChanges).pipe(
      startWith(''),
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {
        this.loadUsers();
      }
    );
  }

  public loadUsers() {
    this.tableIsLoading = true;

    const filter = this.filterForm.get('filter').value;
    const count = this.paginator.pageSize;
    const page = this.paginator.pageIndex;

    let sort_column = null;
    let sort_direction = null;

    if (this.sort.active && this.sort.direction) {
      sort_column = this.sort.active;
      sort_direction = this.sort.direction;
    }

    this.usersService.list(page + 1, count, filter, sort_column, sort_direction).subscribe(response => {
      if (response.users) {
        this.users.data = response.users;
        this.hasData = (this.users.data.length > 0) ?  true : false;
        this.paginator.length = response.total;
        this.tableIsLoading = false;
      }
    });
  }

  public addUser() {
    if (!this.sidenavAddUser.opened) {
      this.sidenavAddUser.open();
    } else {
      this.sidenavAddUser.close();
    }
  }

  public deleteUser(user) {
    this.dialog.open(UserDeleteComponent, { autoFocus: false }).afterClosed().subscribe(dialogResult => {
      //console.log(dialogResult)
      if (dialogResult) {
        this.usersService.deleteUser(user).subscribe(response => {
          if (response.status === 'ok') {
            this.snackBar.open('User deleted successfully', null, {
              duration: 2000
            });
            this.loadUsers();
          }
        });
      }
    });
  }
}
