<mat-sidenav-container>
  <mat-sidenav-content>
    <section>
      <div id="header">
        <div>
          <h1>Specification plans</h1>
          <span class="sub-title">List of all your specification plans exported</span>
        </div>

        <div class="actions">
         <!-- <button mat-raised-button (click)="resume()" [style.display]=" isSavedExists()?'block':'none'">
            <span>
              <mat-icon matPrefix class="mdi mdi-18px mdi-import"></mat-icon>
              Continue
            </span>
          </button>-->
          <button mat-raised-button (click)="createSpecPlan()">
            <mat-icon class="mdi mdi-24px mdi-import"></mat-icon>
            <div class="btn-text">Create Specification Plan</div>
          </button>

<!--          <button mat-raised-button (click)="extract()">-->
<!--            <mat-icon class="mdi mdi-24px mdi-import"></mat-icon>-->
<!--            <div class="btn-text">Extract</div>-->
<!--          </button>-->
        </div>
      </div>

      <div id="block-table-specs">
        <form [formGroup]="filterForm">
          <mat-form-field>
            <input matInput formControlName="filter" placeholder="FILTER">
            <button type="button" *ngIf="filterForm.get('filter').value" mat-button matSuffix mat-icon-button
                    aria-label="Clear" (click)="filterForm.get('filter').setValue('')">
              <mat-icon class="mdi mdi-close"></mat-icon>
            </button>
          </mat-form-field>
        </form>
        <span class="no-data" *ngIf="!hasData">
          There is no data to display<br />
          Let's <a (click)="createSpecPlan()">create a specification plan</a>
        </span>

        <div [ngStyle]="{'display': hasData ? 'block' : 'none'}">


          <div class="div-table">
            <table mat-table matSort [dataSource]="specPlans" matSortActive="date" matSortDirection="desc"
              [ngClass]='{ "loading": tableIsLoading }'>

              <!-- Loading -->
              <ng-container matColumnDef="loading">
                <th mat-header-cell class="table-loading" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </th>
              </ng-container>

              <!-- No Result -->
              <ng-container matColumnDef="noresult">
                <th mat-header-cell class="table-noresult" *matHeaderCellDef [attr.colspan]="tableColumns.length">
                  No result
                </th>
              </ng-container>

              <!-- Date -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                <td mat-cell *matCellDef="let element">{{ element.date }}</td>
              </ng-container>

              <!-- Filename -->
              <ng-container matColumnDef="filename">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
                <td mat-cell *matCellDef="let element">{{ element.filename }}</td>
              </ng-container>

              <!-- Advertiser -->
              <ng-container matColumnDef="advertiser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Advertiser</th>
                <td mat-cell *matCellDef="let element">{{ element.advertiser }}</td>
              </ng-container>

              <!-- Campaign -->
              <ng-container matColumnDef="campaign">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Campaign</th>
                <td mat-cell *matCellDef="let element">{{ element.campaign }}</td>
              </ng-container>

              <!-- Start Date -->
              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
                <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
              </ng-container>

              <!-- Delivery Date -->
              <ng-container matColumnDef="deliveryDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Date</th>
                <td mat-cell *matCellDef="let element">{{ element.deliveryDate }}</td>
              </ng-container>

              <!-- User -->
              <ng-container matColumnDef="user.fullname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
                <td mat-cell *matCellDef="let element">{{ element.user.fullname }}</td>
              </ng-container>

              <!-- Download -->
              <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button type="button" color="accent" mat-icon-button (click)="importSpecPlan(element)" matTooltip="Edit">
                    <mat-icon aria-label="import" class="mdi mdi-24px mdi-pencil"></mat-icon>
                  </button>
                  <button type="button" color="accent" mat-icon-button (click)="downloadSpecPlan(element)" matTooltip="Download">
                    <mat-icon aria-label="download" class="mdi mdi-24px mdi-file-download"></mat-icon>
                  </button>
                  <button type="button" color="accent" mat-icon-button (click)="deleteSpecPlan(element)" matTooltip="Delete">
                    <mat-icon aria-label="delete" class="mdi mdi-24px mdi-delete"></mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-header-row class="table-loading" *matHeaderRowDef="['loading']"></tr>
              <tr mat-header-row
                [ngClass]="{'table-noresult' : true, 'display' : specPlans.data.length == 0 && !tableIsLoading}"
                *matHeaderRowDef="['noresult']"></tr>
              <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
            </table>
          </div>

          <mat-paginator showFirstLastButtons="showFirstLastButtons" pageSize="10" [pageSizeOptions]="[10, 25, 50]">
          </mat-paginator>
        </div>

      </div>

    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
