import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SpecificationService, SpecPlan } from '../../services/specification.service';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith} from 'rxjs/operators';
import {SpecPlanDeleteComponent} from './spec-plan-delete/spec-plan-delete.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './spec-plan-list.component.html',
  styleUrls: ['./spec-plan-list.component.scss']
})
export class SpecPlanListComponent implements OnInit, AfterViewInit {

  public formData: FormGroup;
  public tableIsLoading = false;
  public filterForm: FormGroup;
  public tableColumns: string[] = ['date', 'filename', 'advertiser', 'campaign', 'startDate', 'deliveryDate', 'user.fullname', 'download'];
  public specPlans: MatTableDataSource<SpecPlan> = new MatTableDataSource<SpecPlan>();

  hasData = false;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(private specificationService: SpecificationService, public snackBar: MatSnackBar, private router: Router, private dialog: MatDialog) {
    this.formData = new FormGroup({
      file: new FormControl(null, [Validators.required]),
    });

    this.filterForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.initTableEvents();
  }

  private initTableEvents() {
    this.sort.sortChange.subscribe(
      () => this.paginator.pageIndex = 0
  );

    merge(this.sort.sortChange, this.paginator.page, this.filterForm.get('filter').valueChanges).pipe(
      startWith(''),
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {
        this.loadSpecPlans();
      }
    );
  }

  private loadSpecPlans() {
    this.tableIsLoading = true;

    const filter = this.filterForm.get('filter').value;
    const count = this.paginator.pageSize;
    const page = this.paginator.pageIndex;

    let sort_column = null;
    let sort_direction = null;

    if (this.sort.active && this.sort.direction) {
      sort_column = this.sort.active;
      sort_direction = this.sort.direction;
    }

    this.specificationService.specPlansList(page + 1, count, filter, sort_column, sort_direction).subscribe(response => {
      if (response.spec_plans) {
        this.specPlans.data = response.spec_plans;
        this.hasData = (this.specPlans.data.length > 0) ?  true : false;
        this.paginator.length = response.total;
        this.tableIsLoading = false;
      }
    });
  }

  public deleteSpecPlan(specPlan: SpecPlan) {
    let dialog = this.dialog.open(SpecPlanDeleteComponent);

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.specificationService.delete(specPlan.id, specPlan.filename).subscribe(response => {
          if (response.status && response.status === 'success') {
            this.loadSpecPlans();
            this.snackBar.open('Spec plan delete success !', null, {
                duration: 2000
              }
            );
          }
        });
      }
    });
  }

  public downloadSpecPlan(specPlan: SpecPlan) {
    this.specificationService.downloadSpecPlan(specPlan.filename);
  }

  public importSpecPlan(specPlan: SpecPlan) {
    this.router.navigate(['/specs'], {queryParams: {filename: specPlan.filename, _id: specPlan.id}});
  }

  public isSavedExists() {
    return localStorage.getItem('saved-specs');
  }

  public resume() {
    this.router.navigate(['/specs'], {queryParams: {resume: true}});
  }

  createSpecPlan() {
    this.router.navigate(['/specs']);
  }

  public extract() {
    this.specificationService.extract().subscribe(res => {

    })
  }
}
