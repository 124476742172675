import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpecificationService } from 'src/app/services/specification.service';
import { FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import * as moment from 'moment';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-spec-export',
  templateUrl: './spec-export.component.html',
  styleUrls: ['./spec-export.component.scss']
})
export class SpecExportComponent implements OnInit {

  public formSpecPlan: FormGroup;
  public loading = false;

  public deliveryDateFilter = (d: Date): boolean => {
    return d <= this.formSpecPlan.get('startDate').value;
  }

  // public startDateFilter = (d: Date): boolean => {
  //   return d >= this.formSpecPlan.get('deliveryDate').value;
  // }

  @Output('spec-export') public specExport = new EventEmitter();
  @Input('sidenav') public sidenav: MatSidenav;
  @Input('headersCorrespondances') public headersCorrespondances: any;
  @Input('specsList') public specsList: any;
  @Input('infos') public infos: any;
  @Input('draftId') public draftId:any;
  @Input('_id') public _id: any;


  constructor(private specificationService: SpecificationService, private snackBar: MatSnackBar, private router: Router, private loginService: LoginService) {
  }

  ngOnInit() {
    if (!this.infos) {
      this.formSpecPlan = new FormGroup({
        'advertiser': new FormControl('', Validators.required),
        'campaign': new FormControl('', Validators.required),
        'startDate': new FormControl('', Validators.required),
        //'deliveryDate': new FormControl('', [Validators.required, this.dateLessThan.bind(this)]),
        'contacts': new FormControl(this.loginService.user.email, [
          Validators.required, this.emailsValidator.bind(this)
        ])
      });
    }
    else {

      this.formSpecPlan = new FormGroup({
        'advertiser': new FormControl(this.infos.advertiser, Validators.required),
        'campaign': new FormControl(this.infos.campaign, Validators.required),
        'startDate': new FormControl(moment(this.infos.startDate, 'yyyy/MM/DD').toDate(), Validators.required),
        // 'deliveryDate': new FormControl(moment(this.infos.deliveryDate , 'yyyy/MM/DD').toDate(), [Validators.required, this.dateLessThan.bind(this)]),
        'contacts': new FormControl(this.infos.contacts, [
          Validators.required, this.emailsValidator.bind(this)
        ])
      });
    }
  }

  public export() {
    this.loading = true;

    const infos = {
      advertiser: this.formSpecPlan.get('advertiser').value,
      campaign: this.formSpecPlan.get('campaign').value,
      startDate: this.formSpecPlan.get('startDate').value,
      // deliveryDate: this.formSpecPlan.get('deliveryDate').value,
      contacts: this.formSpecPlan.get('contacts').value
    };

    const specs = this.specsList.data.map(element => {
      return Object.values(element);
    });

    console.log(specs);

    this.specificationService.export(specs, Object.keys(this.headersCorrespondances), infos, this._id).subscribe(response => {
      if (response.status === 'merge-required') {
        this.specExport.emit({ specs: response.specs });
        this.loading = false;
        this.sidenav.close();
        this.snackBar.open('Merge required !', null, { duration: 2000 });
      } else {
        if (response.status === 'success') {
          this.specExport.emit({ });
          this.snackBar.open('Export success !', null, { duration: 2000 });
          if(this.draftId) {
            this.specificationService.draftDelete(this.draftId).subscribe(res => {
              setTimeout(() => this.loading = false, 800);
              this.specificationService.downloadSpecPlan(response.filename);
              this.router.navigate(['/spec-plans']);
            });
          }
          else {
            setTimeout(() => this.loading = false, 800);
            this.specificationService.downloadSpecPlan(response.filename);
            this.router.navigate(['/spec-plans']);
          }

        }
      }
    });
  }

  dateLessThan(control: AbstractControl): ValidationErrors | null {

    if (!this.formSpecPlan) {
      return { required: true};
    }
    if (control.value > this.formSpecPlan.get('startDate').value) {
      return { dates: true};
    }

    return null;
  }

  public validateEmails(emails: string) {
    return (emails.split(/[,;]/)
      .map(email => Validators.email(<AbstractControl>{ value: email }))
      .find(_ => _ !== null) === undefined);
  }

  public emailsValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value === '' || !control.value || this.validateEmails(control.value)) {
      return null;
    }

    return { emails: true };
  }

}
