<section>
  <app-loader *ngIf="loading"></app-loader>
  <div class="section-header">
    <p class="section-title">Add user</p>
  </div>
  <div class="section-content">
    <div class="layout-column" [formGroup]="formUser">
      <mat-form-field>
        <input matInput placeholder="Email" name="email" type="email" formControlName="email">
        <mat-error *ngIf="formUser.get('email').hasError('required')">Email is required</mat-error>
        <mat-error *ngIf="formUser.get('email').hasError('email')">Email is not valid</mat-error>
        <mat-error *ngIf="formUser.get('email').hasError('duplicate')">User already exists</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="section-footer">
    <div class="layout-row layout-align-space-between-center">
      <button mat-stroked-button color="warn" (click)="sidenav.close()">
        Cancel
      </button>
      <button mat-raised-button color="primary" [disabled]="!formUser.valid" (click)="addUser()">
        Add
      </button>
    </div>
  </div>
</section>
