import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  DefaultValueAccessor,
  FormControl,
  FormGroupDirective, NG_VALUE_ACCESSOR, NgControl
} from "@angular/forms";
import {ReplaySubject} from "rxjs";

@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
   providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectWithSearchComponent),
    multi: true
  }],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})

export class SelectWithSearchComponent implements ControlValueAccessor, OnInit, AfterViewInit{

  @Input() set formControlName(name: string) {
    if(name) {
      this._formControlName = name;
    }

  }

  public _formControlName: string;
  @Input() formControl: FormControl;
  @Input() appearance = '';
  @Input() id = '';
  @Input() label = '';
  @Input() set values(val: any) {
    this._values = val;
    this.filtered = this._values;
  }
  public _values: any;
  @Input() grouped = false;
  @Input() keySelector = (x)=>{return x.text};
  @Input() valueSelector = (x)=>{return x};

  @ViewChild(DefaultValueAccessor) valueAccessor: DefaultValueAccessor;
  public searchValue = {value: '', timastamp: new Date().getTime()};
  public filtered:any;
  public keys = [];

  ngOnInit(): void {
    this.filtered = this._values;
    if(this.grouped) {
      this.keys = Object.keys(this.filtered);
    }
  }

  public onOptionsChange($event) {
    this.filtered = $event.values

  }

  public clearFilters() {
    this.searchValue = {value: '', timastamp: new Date().getTime()};

    this.filtered = this._values;
  }

  public onChange() {
    //this.clearFilters()
  }

  delegatedMethodCalls = new ReplaySubject<(_: ControlValueAccessor) => void>();

  ngAfterViewInit(): void {
    this.delegatedMethodCalls.subscribe(fn => fn(this.valueAccessor));
  }

  registerOnChange(fn: (_: any) => void): void {
    this.delegatedMethodCalls.next(valueAccessor => valueAccessor.registerOnChange(fn));
    this.clearFilters();
  }
  registerOnTouched(fn: () => void): void {
    this.delegatedMethodCalls.next(valueAccessor => valueAccessor.registerOnTouched(fn));
  }

  setDisabledState(isDisabled: boolean): void {
    this.delegatedMethodCalls.next(valueAccessor => valueAccessor.setDisabledState(isDisabled));
  }

  writeValue(obj: any): void {
    this.delegatedMethodCalls.next(valueAccessor => valueAccessor.writeValue(obj));
  }
}
