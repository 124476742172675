<ng-template #uploadTemplate let-reference="reference">
  <div class="flex layout-column">
    <div class="flex layout-row layout-align-start-center">
      <label>
        Choose your file :
      </label>
      <button type="button" mat-button (click)="reference.openInputFileUpload()" color="warn">
        <mat-icon class="mdi mdi-upload"></mat-icon>
        <span>Choose your file</span>
      </button>
    </div>
    <div>
      <a href="sources/download/template">Download Template</a>
    </div>
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #itemTemplate let-reference="reference" let-files="files">
  <mat-card class="layout-column layout-margin" *ngFor="let _file of files; let i = index">
    <div class="layout-row layout-align-space-between-center">
      <div class="flex-25" *ngIf="reference._preview">
        <div
          style="width: 64px; height: 64px; border-radius: 50%; background-size: cover; background-position: center center; background-repeat: no-repeat"
          [style.background-image]="_file.content"></div>
      </div>
      <div class="flex layout-column">
        <p><b>{{ _file.name }}</b></p>
        <p>
          <small>{{ _file.size / 1024 / 1024  }} Mo</small> &nbsp;
        </p>
      </div>

      <button type="button" mat-icon-button *ngIf="_file.error || !(_file.progress < 100)" color="warn" (click)="reference.onDelete(i)">
        <mat-icon class="mdi mdi-24 mdi-delete"></mat-icon>
      </button>
    </div>
    <mat-progress-bar mode="determinate" [value]="_file.progress" *ngIf="!(_file.error) && _file.progress < 100">
    </mat-progress-bar>
  </mat-card>
</ng-template>

<section>
  <app-loader *ngIf="loading"></app-loader>

  <div>
    <h3>Import File</h3>
  </div>
  <div>
    <div class="layout-column">
      <form [formGroup]="formData" class="layout-column flex">
        <app-mat-file-upload
          [uploadTemplate]="uploadTemplate"
          [itemTemplate]="itemTemplate"
          [preview]="false"
          [multiple]="false"
          [maxSize]="maxFileSize * 1024 * 1024"
          [maxLength]="128" accept=".xlsx"
          [formControl]="formData.get('file')">
        </app-mat-file-upload>
      </form>
    </div>
  </div>

  <div class="actions">
    <button mat-stroked-button color="warn" (click)="sidenav.close()">
      Cancel
    </button>
    <button mat-raised-button color="primary" [disabled]="!formData.valid" (click)="import()">
      Import
    </button>
  </div>
</section>
