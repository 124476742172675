import {Component} from "@angular/core";
import { MatDialogRef} from "@angular/material/dialog";

@Component({
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss']
})

export class UserDeleteComponent {
  constructor (public dialog: MatDialogRef<UserDeleteComponent>) {}

  public delete() {
    this.dialog.close("ok");
  }

  public close() {
    this.dialog.close(null);
  }
}
