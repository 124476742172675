import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SpecificationService } from 'src/app/services/specification.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { XslxDataParser } from 'src/app/utils/xslx-data-parser';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-spec-import',
  templateUrl: './spec-import.component.html',
  styleUrls: ['./spec-import.component.scss']
})
export class SpecImportComponent implements OnInit {

  public formData: FormGroup;
  public loading = false;
  public data: any;
  public dataParser: XslxDataParser;
  public specPlanIsValid = true;
  public maxFileSize = 4;

  @Output('spec-import') public specImport = new EventEmitter();
  @Input('sidenav') public sidenav: MatSidenav;
  @Input('headersCorrespondances') public headersCorrespondances: any;

  constructor(private specificationService: SpecificationService, private snackBar: MatSnackBar,     private sanitized: DomSanitizer    ) {
    this.formData = new FormGroup({
      file: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
  }

  public import() {
    this.loading = true;

    this.specificationService.import({
      filename: this.formData.get('file').value['name'],
      content: this.formData.get('file').value['content'].split(',')[1]

    }).subscribe(result => {

      if (result.status && result.status === 'success') {
        this.data = result.data;
        this.dataParser = new XslxDataParser(this.data, this.headersCorrespondances, this.sanitized);
        this.dataParser.parse();
        const headers = ['checking', 'alerte', 'actions'].concat(this.dataParser.headers);

        const line = [];
        for (let i = 0; i < this.dataParser.specs.length; i++) {
          const row = this.dataParser.specs[i];
          line[i] = {};

          for (let j = 0; j < row.length; j++) {
            if (this.headersCorrespondances[row[j].header] === 'creation_date' && row[j].richText[0].insert.toUpperCase().indexOf('MEL J-') < 0) {
              line[i][row[j].header] = { text: '<span style="font-size: 11px;color: #000000;">MEL J-7</span>', richText: [{insert: 'MEL J-7'}], header: row[j].header };

            }
            else {
              if (this.headersCorrespondances[row[j].header] === 'border' && row[j].richText[0].insert.toUpperCase().trim() !== 'OUI') {
                line[i][row[j].header] = { text: '<span style="font-size: 11px;color: #000000;">Non</span>', richText: [{insert: 'Non'}], header: row[j].header };
              }
              else {
                line[i][row[j].header] = { text: row[j].text, richText: row[j].richText, header: row[j].header };
              }
            }

          }
        }
        this.specImport.emit({ specs: line, headers: headers });
        this.specPlanIsValid = true;

        this.snackBar.open('File import success!', null, {
          duration: 2000
        });

        setTimeout(() => {
          this.loading = false;
          this.sidenav.close();
        }, 800);

      } else if (result.status && result.status === 'invalid') {
        this.snackBar.open('Imported file is invalid', null, {
          duration: 2000
        });
      } else {
        this.snackBar.open('An error occurred while importing file', null, {
          duration: 2000
        });
      }

    });
  }
}
