<mat-sidenav-container>
  <mat-sidenav-content>
    <section style="height: 85%">
      <div id="header">
        <div>
          <h1>Help</h1>
        </div>
      </div>

      <div style="height: 100%">
        <pdf-viewer
          [src]="pdfSrc"
          [render-text]="true"
          style="display: block;"
        ></pdf-viewer>
      </div>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>

