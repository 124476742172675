import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../services/login.service';
import {Router} from '@angular/router';
import {ValidationFieldsService} from "../../services/validation_fields.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: any;
  unvalidate = 0;

  constructor(public loginService: LoginService,
              public validationFieldsService: ValidationFieldsService,
              private router: Router) { }

  ngOnInit() {
    this.getUnvalidateCount();
    this.loginService.getUser().subscribe(val => {
      this.user = val.user;
    })
  }

  public getUnvalidateCount() {
    this.validationFieldsService.unvalidate_count().subscribe(response=>{
      this.unvalidate = response.count;
      this.validationFieldsService.unvalidate = response.count;
    })
  }

  public goToHelp() {
    window.open('/help', '_blank');
  }

  logout() {
    this.loginService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

}
