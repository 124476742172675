import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-save',
  templateUrl: './confirm-save.component.html',
  styleUrls: ['./confirm-save.component.scss']
})
export class ConfirmSaveComponent implements OnInit {

  constructor (public dialog: MatDialogRef<ConfirmSaveComponent>) {}

  ngOnInit() {
  }

}
