import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SpecificationService} from "../../../services/specification.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.scss']
})
export class RenameComponent implements OnInit {
  public renameSourceForm: FormGroup;
  public sourceList = []
  source: any;

  constructor(public dialog: MatDialogRef<RenameComponent>,
              private specificationService: SpecificationService,
              @Inject(MAT_DIALOG_DATA) public  data: any) {
    this.source = data;
    console.log(data)
  }
  ngOnInit(): void {
    this.renameSourceForm = new FormGroup({
      // filename: new FormControl(this.source.name, [Validators.required]),
      name: new FormControl(this.source.name, [Validators.required, this.ValidatorFileToReplaceNotExists.bind(this)])
    });

    this.specificationService.listSources().subscribe(response => {
      if(response.sources) {
        let existingNames = [];

        this.sourceList = [];
        for ( let source of response.sources ) {
          if ( existingNames.indexOf(source.name) < 0) {
            this.sourceList.push(source);
            existingNames.push(source.name);
          }
        }
      }
    });
  }

  public ValidatorFileToReplaceNotExists(control: AbstractControl) {

    this.sourceList.forEach(source => {
      if (control.value === source.name) {
        return {exists: true};
      }
    });

    return null;
  }

  rename() {
    if (this.renameSourceForm.valid) {

      this.specificationService.renameSource(
        this.source.uuid,
        this.source.filename,
        this.renameSourceForm.get('name').value
      ).subscribe((response) => {
        if (response.status && response.status === 'success') {
          this.dialog.close({success: true});
        }
      });
    }
  }

}
