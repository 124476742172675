import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-reject',
  templateUrl: './confirm-reject.component.html',
  styleUrls: ['./confirm-reject.component.scss']
})
export class ConfirmRejectComponent implements OnInit {

  constructor(public dialog: MatDialogRef<ConfirmRejectComponent>) { }

  ngOnInit(): void {
  }

}
