import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export interface SpecPlan {
  id: string;
  filename: string;
  date: string;
  advertiser: string;
  campaign: string;
  startDate: string;
  deliveryDate: string;
  user: any;
}

@Injectable()
export class SpecificationService {
  public data: any;

  constructor(private http: HttpClient) {
  }

  public import(params: any): Observable<any> {
    return this.http.post('/api/spec_plans/import', params, {headers: httpOptions.headers});
  }

  public import_existing(filename): Observable<any> {
    return this.http.get('/api/spec_plans/import_existing', {headers: httpOptions.headers, params: {filename: filename}});
  }

  public calculate_days(startDate, dates): Observable<any> {
    return this.http.post('/api/spec_plans/calculate_days', {startDate: startDate, dates: dates}, {headers: httpOptions.headers});
  }

  public export(specs: any, headers: any, infos: any, _id: any): Observable<any> {
    return this.http.post('/api/spec_plans/export', {specs: specs, headers: headers, infos: infos, _id: _id}, {headers: httpOptions.headers});
  }

  public save(specs: any, headers: any): Observable<any> {
    return this.http.post('/api/spec_plans/save', {specs: specs, headers: headers}, {headers: httpOptions.headers});
  }

  public extract(): Observable<any> {
    return this.http.get('/api/spec_plans/extract_all', { headers: httpOptions.headers});
  }

  public delete(id, filename): Observable<any> {
    return this.http.get('/api/spec_plans/delete', { params: {id: id, filename: filename}, headers: httpOptions.headers});
  }

  public uploadSource(data, filename, name): Observable<any> {
    return this.http.post('/api/sources/upload', {data: data, filename: filename, name: name}, httpOptions);
  }

  public replaceSource(uuid, data, filename, name): Observable<any> {
    return this.http.post('/api/sources/replace', { uuid: uuid, data: data, filename: filename, name: name}, httpOptions);
  }

  public renameSource(uuid, filename, name): Observable<any> {
    return this.http.post('/api/sources/rename', { uuid: uuid, filename: filename, name: name}, httpOptions);
  }

  public listSources(): Observable<any> {
    return this.http.get('/api/sources/list', httpOptions);
  }

  public listChangedValues(): Observable<any> {
    return this.http.get('/api/spec_library/list_changed_values', httpOptions);
  }

  public listSourcesPaginate(page: number, count: number, filter: string, sort_column: string, sort_direction: string): Observable<any> {
    let params = new HttpParams()
      .set('page', String(page))
      .set('count', String(count));

    if (sort_column) {
      params = params.set('sort_column', sort_column);

      if (sort_direction) {
        params = params.set('sort_direction', sort_direction);
      }
    }

    if (filter) {
      params = params.set('filter', filter);
    }
    console.log(params)
    return this.http.get('/api/sources/list_paginate', {... httpOptions, params: params});
  }

  public deleteFromSpecLibrary(_id): Observable<any> {
    return this.http.get('/api/spec_library/delete', { params: {_id: _id}, headers: httpOptions.headers});
  }

  public updateSpecLibrary(specs): Observable<any> {
    return this.http.post('/api/spec_library/update', {specs: specs}, httpOptions);
  }

  public getHeadersByLabel() {
    return this.http.get('/api/spec_plans/get_headers_by_label', httpOptions);
  }

  public getHeaders() {
    return this.http.get('/api/spec_plans/get_headers', httpOptions);
  }

  public merge(specs: any): Observable<any> {
    return this.http.post('/api/spec_plans/merge', {specs: specs}, httpOptions);
  }

  public downloadSpecPlan(filename: string) {
    const token = localStorage.getItem('access_token');

    if (token) {

      window.location.assign('/api/spec_plans/download' + '?filename=' + encodeURI(filename) + '&token=' + encodeURI(token));
    }
  }

  public downloadSource(uuid, filename) {
    const token = localStorage.getItem('access_token');

    if (token) {

      window.location.assign('/api/sources/download' + '?uuid=' + uuid + '&filename=' + encodeURI(filename) + '&token=' + encodeURI(token));
    }
  }

  public specPlansList(page: number, count: number, filter: string, sort_column: string, sort_direction: string): Observable<any> {
    let params = new HttpParams()
      .set('page', String(page))
      .set('count', String(count));

    if (sort_column) {
      params = params.set('sort_column', sort_column);

      if (sort_direction) {
        params = params.set('sort_direction', sort_direction);
      }
    }

    if (filter) {
      params = params.set('filter', filter);
    }


    return this.http.get('/api/spec_plans/list', {params: params});
  }

  public specLibraryList(offset, limit, filter, sort, changedSpecs: any = null): Observable<any> {
    let params = new HttpParams();
    params = params.set('offset', offset).set('limit', limit).set('filter', filter);
    if (sort)
      params = params.set('sprop', sort.prop).set('sdir', sort.dir);
    if (changedSpecs) {
      console.log(changedSpecs)
      params = params.set('changedSpecs', JSON.stringify(changedSpecs));
    }
    return this.http.get('/api/spec_library/list', {... httpOptions, params: params});
  }

  public getDuplicateRows(): Observable<any>{
    return this.http.get('/api/spec_library/list_duplicates', {... httpOptions});
  }

  public isSpecExists(adserving, publisher, site, format): Observable<any> {
    return this.http.post('/api/spec_library/is_exists',
      {adserving: adserving, publisher: publisher, site: site, format: format}, httpOptions);
  }

  public draftInsert(name, draft, user): Observable <any> {
    return this.http.post('/api/drafts/insert', { name: name, draft: draft, user: user}, httpOptions);
  }

  public draftUpdate(id, name, draft): Observable <any> {
    return this.http.post('/api/drafts/update', {id: id, name: name, draft: draft}, httpOptions);
  }

  public draftList(offset, limit, filter, sort): Observable <any> {
    let params = new HttpParams();
    params = params.set('offset', offset).set('limit', limit).set('filter', filter);
    if (sort)
      params = params.set('sort_column', sort.sort_column).set('sort_direction', sort.sort_direction);

    return this.http.get('/api/drafts/list', {params: params, headers: httpOptions.headers});
  }

  public draftGet(id): Observable <any> {
    return this.http.get('/api/drafts/get', { params: { id: id} , headers: httpOptions.headers});
  }

  public draftDuplicate(id): Observable <any> {
    return this.http.get('/api/drafts/duplicate', { params: { id: id} , headers: httpOptions.headers});
  }

  public draftDelete(id): Observable <any> {
    return this.http.get('/api/drafts/delete', { params: { id: id} , headers: httpOptions.headers});
  }

  public tutoInsert(tuto): Observable<any> {
    return this.http.post('/api/tuto/insert', { tuto: tuto}, httpOptions);
  }

  public tutoList(): Observable<any> {
    return this.http.get('/api/tuto/list', httpOptions);
  }

}
